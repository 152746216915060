<template>
  <div class="form-input read-only-input" :class="{ 'rounded bg-red-100 p-2.5': errors.length }">
    <div v-if="showLabel">
      <div class="mb-2">
        <span v-text="label" :class="{ 'text-required': errors.length }"></span>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <div :class="{ 'field-border': !errors.length }">
        <abn-lookup-details :item="value"></abn-lookup-details>
      </div>
      <field-error :errors="errors" :id="id"></field-error>
    </div>
  </div>
</template>
<script>
import inputProps from "../helpers/inputProps"
import FieldError from "@/components/form/FieldError"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import { isArray, isEmpty, isPlainObject } from "lodash"
import AbnLookupDetails from "@/components/form/AbnLookupDetails"

export default {
  components: { FieldError, AbnLookupDetails },
  props: {
    ...inputProps,
  },
  data() {
    return {
    }
  },
  computed: {
    processedValue() {
      const parsed = jsonParse(this.value, this.value)

      if (!isEmpty(parsed)) {
        return parsed?.value || ""
      }

      return ""
    },
    displayType() {
      if (isArray(this.processedValue) || isPlainObject(this.processedValue)) {
        return "loop"
      }

      return "string"
    },
  },
}
</script>
