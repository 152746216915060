import DOMPurify from "dompurify"

const safeIframes = ["https://player.vimeo.com/video", "https://www.youtube.com/embed"]

const editor = (text) => {
  let div = document.createElement("div")

  DOMPurify.addHook("uponSanitizeElement", (node, data) => {
    if (data.tagName === "iframe") {
      const src = node.getAttribute("src") || ""
      if (safeIframes.filter((safe) => src.includes(safe)) < 1) {
        return node.parentNode.removeChild(node)
      }
    }
  })

  div.innerHTML = DOMPurify.sanitize(text, {
    ADD_ATTR: ["target", "frameborder", "allowfullscreen", "src"],
    ADD_TAGS: ["iframe"],
  }).trim()

  Array.from(div.querySelectorAll("iframe"), (element, ind) => {
    let wrapper = document.createElement("div")
    let iframe = element.cloneNode()

    wrapper.className = "embed-container"
    wrapper.appendChild(iframe)

    element.parentNode.insertBefore(wrapper, element)
    element.parentNode.removeChild(element)
  })

  Array.from(div.querySelectorAll(".collapsible_panel"), (element, ind) => {
    let title = element.querySelector(".collapsible_panel_title").innerText
    title = title.trim()

    let label = document.createElement("label")
    label.htmlFor = `collapsible-trigger-${ind}`
    label.className = "dropdown"
    label.innerText = title

    element.querySelector(".collapsible_panel_title").innerHTML = ""
    element.querySelector(".collapsible_panel_title").appendChild(label)

    let checkbox = document.createElement("input")
    checkbox.type = "checkbox"
    checkbox.id = `collapsible-trigger-${ind}`
    checkbox.className = "collapsible-trigger"
    element.prepend(checkbox)

    return div
  })

  return div
}

export default {
  editor,
}
