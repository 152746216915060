import endpoint from "./endpoint"

const collection = (params = {}) => {
  //#todo this better
  params.per_page = 1000
  return endpoint.get("/knowledge", params)
}

const single = (id, params = {}) => {
  return endpoint.get(`/knowledge/${id}`, params)
}

const byMachineName = (machineName, params = {}) => {
  return endpoint.get(`/knowledge/machine-name/${machineName}`, params)
}

export default {
  collection,
  single,
  byMachineName,
}
