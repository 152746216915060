<template>
  <div>
    <h2>Uploads</h2>
    <attachment-input-file-list :files="files" :readOnly="true"></attachment-input-file-list>
    <router-link class="button button--filled" :to="{ name: 'section-review', params: { id: response.id } }">
      Return to review
    </router-link>
  </div>
</template>

<script>
import AttachmentInputFileList from "@/components/form/AttachmentInputFileList"
import { mapGetters } from "vuex"
import { flatten, flattenDepth, isObject } from "lodash"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import FormSections from "@/routes/response/mixins/form-sections"

export default {
  components: {
    AttachmentInputFileList,
  },
  mixins: [FormSections],
  props: {
    builtSections: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      response: "response/get",
    }),
    files() {
      let files = this.builtSections.map((section) => {
        let fields = section.fields
          .filter((field) => ["attachment", "table"].includes(field.type))
          .map((field) => {
            return field.response?.value
          })
          .filter((field) => !!field)
          .map((field) => {
            return jsonParse(field)
          })
          .map((field) => {
            if (field.type === "attachment") {
              return field.value
            } else if (field.type === "table") {
              if (field.value && field.value.length < 1) {
                return null
              }

              let allRows = flatten(field.value)
              if (allRows.length < 1) {
                return null
              }

              let attachmentRows = allRows.filter((cell) => isObject(cell)).filter((cell) => cell.type === "attachment")

              if (attachmentRows.length < 1) {
                return null
              }

              let filesRows = attachmentRows.map((cell) => {
                return cell?.value?.value
              })

              if (filesRows.length < 1) {
                return null
              }

              return flatten(filesRows)
            }
          })

        return fields
      })

      files = flattenDepth(files, 3)
      files = files.filter((file) => isObject(file))

      return files
    },
  },
}
</script>
