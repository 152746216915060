<template>
  <div class="relative">
    <div class="filter-wrapper">
      <div class="search-wrapper">
        <div class="text" v-if="content" v-dompurify-html="content.description"></div>
        <search-input v-model="query" class="mt-12 mb-4" placeholder="Search"></search-input>
      </div>
      <div class="content-wrapper">
        <div class="mb-4">
          <button class="button inline-flex button--outline mr-4" @click="toggle(true)">Expand all</button>
          <button class="button inline-flex button--outline" @click="toggle(false)">Collapse all</button>
        </div>
        <div class="accordion-wrapper">
          <transition-group name="list">
            <div :key="item.id" v-for="item of searchResult.data.items">
              <accordion :title="item.title" :content="item.description" :files="item.files" />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from "@/components/Accordion"
import SearchInput from "@/components/form/SearchInput"
import DynamicTitleMixin from "@/mixins/dynamic-title"

const configuration = {
  searchableFields: ["title", "description"],
  sortings: {
    name_asc: {
      field: "title",
      order: "asc",
    },
  },
  aggregations: {
    category: {
      title: "category",
      size: 10,
      conjunction: false,
      sort: "category",
    },
  },
}

export default {
  components: {
    Accordion,
    SearchInput,
  },
  mixins: [DynamicTitleMixin],
  computed: {
    knowledge() {
      return this.$store.getters["knowledge/faqKnowledgeItems"]
    },
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("faq_introduction") ?? false
    },
    searchResult() {
      return this.itemsjs.search({
        query: this.query,
      })
    },
    title() {
      return "Portal Frequently Asked Questions"
    },
  },
  data() {
    return {
      query: "",
      filters: {},
      itemsjs: {},
    }
  },
  methods: {
    reset() {
      this.query = ""
    },
    load() {
      this.$store.dispatch("knowledge/load", {
        type: "faq",
      })
    },
    toggle(state) {
      this.$events.$emit("accordion:toggle", state)
    },
  },
  watch: {
    knowledge() {
      this.itemsjs = this.$itemsjs(this.knowledge, configuration)
    },
  },
  created() {
    this.load()
    this.itemsjs = this.$itemsjs(this.knowledge, configuration)
  },
}
</script>

<style lang="scss">
.content-wrapper {
  @apply relative;
  @screen 2xl {
    &:after {
      @apply block bg-contain absolute bg-no-repeat;
      content: "";
      height: 370px;
      width: 300px;
      z-index: -1;
      bottom: -15rem;
      right: -15rem;
      background-image: url("~@/assets/images/welcome-person.svg");
    }
  }
}

.facet-wrapper {
  @apply max-w-full overflow-x-auto mb-4;
}

.facet-list {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.14);
}

input.radio-input + label {
  background: url("~@/assets/images/active-link-mobile.svg") no-repeat 0 70px;
  animation: activeLeaveMobile 0.2s 1;
  animation-timing-function: linear;
  @apply md:pr-0 whitespace-nowrap text-gray-700;
  @screen md {
    background: url("~@/assets/images/active-link.svg") no-repeat center left -4px;
    animation: activeLeaveDesktop 0.2s 1;
  }
  &:hover,
  &:focus {
    @apply text-primary-light;
  }
}

input.radio-input:checked + label {
  animation: activeEnterMobile 0.2s 1;
  animation-timing-function: linear;
  background-position: bottom;
  @apply text-primary-light font-bold;
  @screen md {
    animation: activeEnterDesktop 0.2s 1;
    background-position: center left 0;
  }
}
</style>
