import Introduction from "@/routes/tenders/children/Introduction"
import Faq from "@/routes/tenders/children/Faq"
import Addendum from "@/routes/tenders/children/Addendum"
import Addenda from "@/routes/tenders/children/Addenda"
import { auth } from "../../middleware"
import { guid } from "@/plugins/util"

export default [
  {
    path: "",
    name: "tender",
    component: Introduction,
    meta: { label: "Tender detail" },
  },
  {
    path: "faq",
    name: "tender-faq",
    component: Faq,
    meta: { label: "FAQ" },
  },
  {
    path: "addenda",
    name: "tender-addenda",
    component: Addenda,
    meta: { middleware: [auth], label: "Tender Addenda" },
  },
  {
    path: `addenda/:addendumId(${guid})?`,
    name: "tender-addendum",
    component: Addendum,
    meta: { middleware: [auth], label: "Tender Addendum" },
  },
]
