<template>
  <div>
    <ul class="pl-0 ml-0 list-none bg-white shadow-lg xl:shadow-none xl:bg-transparent" v-if="availableSections">
      <progress-section
        v-if="begin"
        :sectionInd="0"
        :isEnabled="false"
        :currentSection="{}"
        :section="overview"
        :current-section-index="currentSectionIndex"
        :section-selected="sectionsSelected"
        :complete="true"
        :read="false"
      >
      </progress-section>

      <progress-section
        v-for="(section, ind) in availableSections"
        :key="section.id"
        :sectionInd="ind"
        :isEnabled="!begin"
        :section="section"
        :menuLength="section.length"
        :currentSectionIndex="currentSectionIndex"
        :current-section="currentSection"
        :section-selected="sectionSelected"
        :complete="section.complete"
        :read="section.read"
        :readOnly="readOnly"
      >
      </progress-section>

      <li class="relative w-full" v-if="response && response.id && !readOnly">
        <router-link
          :to="{
            name: 'submit',
            params: { id: response.id },
          }"
          class="flex items-center pl-4 mb-10 no-underline"
        >
          <div class="relative z-10 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 52.828 27.779 27.876"
              class="absolute -top-1/3 -z-1 fill-current"
              :class="[sectionSelected ? 'text-primary-light' : 'text-gray-300']"
            >
              <path
                d="M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"
              />
            </svg>
            <div
              class="w-10 h-10 rounded-full"
              :class="{
                'bg-primary-light border-4 border-primary-light': $route.name === 'submit',
                'bg-white border-2 border-gray-500 ': $route.name !== 'submit',
              }"
            ></div>
          </div>
          <span
            class="relative block w-full px-2 py-5 ml-2 text-black no-underline rounded-r-md before:absolute before:w-14 before:h-full before:-left-14 before:top-0 before:rounded-l-md before:bg-opacity-20 bg-opacity-20"
            :class="$route.name === 'submit' ? 'bg-primary-highlight before:bg-primary-highlight' : ''"
            >Submit</span
          >
        </router-link>
      </li>
      <li class="relative w-full" v-else>
        <div class="flex items-center pl-4">
          <div class="relative z-10 flex items-center">
            <div
              class="relative flex items-center justify-center w-10 h-10 bg-white border-2 rounded-full"
              :class="{
                'border-gray-300': !submitted,
                'bg-[#003860] border-primary-light border-4': submitted,
              }"
            >
              <img v-if="submitted" src="@/assets/images/tick.svg" alt="Tick" class="w-1/2" />
            </div>
          </div>
          <div class="inline-block">
            <span class="block px-2 py-1 ml-2 text-black text-gray-400 no-underline rounded-md">Submit</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import ProgressSection from "./ProgressSection.vue"
import { isEmpty } from "lodash"

export default {
  components: {
    ProgressSection,
  },
  props: {
    response: {
      type: Object,
      default: () => {},
    },
    sections: {
      type: Array,
      default: () => [],
    },
    section: {
      type: Object,
      default: () => {},
    },
    overview: {
      type: Object,
      default: () => {},
    },
    isEnabled: {
      type: Boolean,
      default: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    availableSections: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    open: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      dirtySections: "response/sections",
      readSections: "response/readSections",
    }),
    submitted() {
      return this?.response?.status === "submitted"
    },
    currentSection() {
      return this.availableSections[this.currentSectionIndex]
    },
    currentSectionIndex() {
      if (this.$route.name === "submit") {
        return this.availableSections.length
      }

      return this.availableSections.findIndex((s) => s.id === this.section.id)
    },
    sectionSelected() {
      return this.$route.name === "submit"
    },
    begin() {
      return !isEmpty(this.overview) || !this.isEnabled
    },
  },
}
</script>
