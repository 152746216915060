<template>
  <span>
    <div class="form-input text-input" v-if="conditionsMet">
      <validation-provider
        mode="eager"
        :rules="validation.join('|')"
        v-slot="{ errors, changed }"
        :name="`${id}-${namePrefix}${label}`"
        slim
        :immediate="immediate"
      >
        <template v-if="!readOnly">
          <div class="flex items-end justify-between mb-2" v-if="showLabel">
            <div>
              <label :for="`input_${id}_${name}`" v-text="label"></label>
              <span class="text-required" v-if="validation.includes('required')"> *</span>
            </div>
          </div>
          <field-meta v-if="help && showLabel" :help="help"></field-meta>
          <div v-if="charactersLeft" class="text-xs text-right" :class="isOutOfRange ? '' : 'text-red-500'">{{ charactersLeft }} characters</div>
          <textarea
            :name="`input_${id}_${name}`"
            :id="`input_${id}_${name}`"
            v-model="current"
            :placeholder="placeholder"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          ></textarea>
          <reusable-modal v-if="reusable" :reusable-id="reusable" @reuse="reuse"></reusable-modal>
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          :label="label"
          :show-label="showLabel"
          :value="value"
          :type="type"
          :print="print"
          :id="id"
          :errors="errors"
        ></read-only-string>
      </validation-provider>
    </div>
  </span>
</template>
<script>
import ReusableModal from "@/components/form/ReusableModal"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"

export default {
  name: "TextareaInput",
  components: { ReusableModal, ReadOnlyString },
  mixins: [InputMixin, ConditionalMixin],
}
</script>
