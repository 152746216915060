<template>
  <div class="form-input attachment-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors }"
      ref="provider"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div
          v-show="acceptNew"
          class="relative w-full transition-all rounded-md cursor-pointer lg:w-2/3"
          :class="{
            'border-solid border-green-500 border-2': uploadSuccess,
            'border-dashed border-blue-300 border-2': !uploadSuccess && !invalidFile,
            'bg-primary-highlight': dropping,
            'border-solid border-red-600 border-4': invalidFile,
          }"
        >
          <dropzone
            class="px-12 py-6"
            ref="attachment"
            :id="`upload_${id}`"
            :options="dropzoneOptions"
            :include-styling="false"
            :useCustomSlot="true"
            @vdropzone-file-added="add"
            @vdropzone-file-added-manually="add"
            @vdropzone-success="success"
            @vdropzone-complete="complete"
            @vdropzone-sending="sending"
            @vdropzone-queue-complete="queueComplete"
            @vdropzone-processing-multiple="queueComplete"
            @vdropzone-processing="processing"
            @vdropzone-error="error"
            @vdropzone-drag-over="dropping = true"
            @vdropzone-drag-leave="dropping = false"
            @vdropzone-drag-drop="dropping = false"
          >
            <div v-if="!loading && !dropped" class="flex flex-col items-center justify-center text-center">
              <div class="mb-4 upload"></div>
              <label class="font-bold">Upload file</label>
            </div>
            <div v-if="loading && !uploadSuccess" class="flex flex-col items-center justify-center text-center">
              <div>
                <img src="~@/assets/images/loading.svg" alt="delete file icon" class="animate-spin-slow" />
              </div>
              <label class="font-bold">Uploading</label>
            </div>
            <div v-if="uploadSuccess" class="absolute flex flex-col items-center justify-center text-center bg-white inset-2 col">
              <div class="uploadSuccessIcon"></div>
              <label class="font-bold">Upload complete with no errors</label>
            </div>
          </dropzone>
        </div>

        <attachment-input-file-list
          v-model="current"
          :files="fileList"
          @remove="removeFile"
          :readOnly="readOnly"
          :type="type"
          :errors="errors"
          :label="label"
        ></attachment-input-file-list>
        <button
          type="button"
          class="flex items-center mt-2 rounded-md button--outline button--outline-normal"
          @click.prevent="addAnother"
          v-if="fileList.length >= 1 && acceptNew && !readOnly"
        >
          <img src="~@/assets/images/plus.svg" alt="delete file icon" class="inline p-2" />
          <span class="inline p-2">Add more</span>
        </button>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-attachment
        v-else
        :label="label"
        :show-label="showLabel"
        :value="current"
        :type="type"
        :print="print"
        :errors="errors"
        :id="id"
      ></read-only-attachment>
    </validation-provider>
  </div>
</template>
<script>
import { fileExt } from "@/plugins/util"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import AttachmentInputFileList from "./AttachmentInputFileList"
import moment from "moment"
import { default as Dropzone } from "vue2-dropzone"
import { findLastIndex } from "lodash"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import ReadOnlyAttachment from "@/components/form/read-only/ReadOnlyAttachment"
import rejectedFiles from "@/components/form/helpers/rejectedFiles.json"

export default {
  components: {
    ReadOnlyAttachment,
    Dropzone,
    AttachmentInputFileList,
  },
  name: "AttachmentInput",
  mixins: [InputMixin, ConditionalMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
  },

  //TODO: check dropzone maxfilesexceeded event. it could be triggering with the this.files array and not this.filelist array
  data() {
    let maxFiles = this.max > 0 ? this.max : 40
    this.max = this.max < 0 && this.$parent.$parent.type == "table" ? 1 : this.max;

    return {
      auto: false,
      fileList: [],
      loading: false,
      dropped: false,
      uploadSuccess: false,
      invalidFile: false,
      dropping: false,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_ENDPOINT}/uploads/${this.$route.params.id}`,
        maxFilesize: 20,
        createImageThumbnails: false,
        uploadMultiple: true,
        parallelUploads: 1,
        autoProcessQueue: false,
        //acceptedFiles: "image/*,application/pdf,.doc,.docx,.xls,.xlsx,.txt,.ppt,.pptx,.pot,.pdf,.zip",
        //maxFiles,
        //acceptedFiles: "*/*",
        headers: {
          Authorization: `Bearer ${this.$store.getters["auth/token"].accessToken}`,
        },
        paramName: () => {
          return "file"
        },
        previewTemplate: `<div class="data-dz-preview data-dz-file-preview"></div>`,
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
      },
      changed: false,
    }
  },
  computed: {
    acceptNew() {
      return !(this.max > 0 && this.fileList.filter((file) => file.status !== "error").length >= this.max)
    },
    validFilesCount() {
      return this.fileList.filter((file) => file.status !== "error").length
    },
  },
  methods: {
    load() {
      let field = jsonParse(this.value, {
        value: [],
      })

      this.current = field?.value ?? []
      this.fileList = this.current
    },
    update() {
      let value = ""

      if (this.current.length > 0) {
        value = {
          type: "attachment",
          value: this.current,
        }
      }

      if (this.updatesForm) {
        this.$emit("updated", value, {
          id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.name,
          files: this.files,
        })
      } else {
        this.$emit("changed", {
          value: value,
          question_id: this.id,
          type: this.type,
          name: this.label,
          files: this.files,
        })
      }
    },
    updateList() {
      if (this.fileList.length >= 1) {
        this.current = this.fileList
          .filter((f) => f.status === "success")
          .map((file) => ({
            filename: file.filename,
            name: file.name,
            status: file.status,
            id: file.id,
            size: file.size,
            uploaded_at: file.uploaded_at,
          }))

        this.files = this.current.map((c) => ({ id: c.id }))
      } else {
        this.files = []
        this.current = []
      }

      this.update()
      this.$refs.provider.syncValue(this.files)
    },
    addAnother() {
      this.$refs.attachment.dropzone.hiddenFileInput.click()
    },
    removeFile(file) {
      let storedFile = this.$refs.attachment.dropzone.files.find((f) => {
        if (f?.name === file.name) {
          return true
        }

        if (f?.upload?.name === file.filename) {
          return true
        }

        return f.name === file.filename
      })

      if (storedFile) {
        this.$refs.attachment.removeFile(storedFile)
      }

      let found = null
      found = findLastIndex(this.fileList, (f) => {
        if (file.id) {
          return f.id === file.id
        }

        return f?.name === file.name
      })

      if (found === -1) {
        found = findLastIndex(this.fileList, (f) => {
          return f.upload.uuid === file.upload.uuid
        })
      }

      this.fileList.splice(found, 1)
      this.changed = true
      this.updateList()
    },
    async success(file, response) {
      await this.upsert(file, response)
    },
    complete() {
      this.changed = true
    },
    add(file) {
      console.log("file", file)

      this.$store.dispatch("auth/poll").then(() => {})

      if (this.validFilesCount > this.max) {          
        this.$refs.attachment.removeFile(file)
        return
      }

      let status = file.status
      let response = {}
      let ext = fileExt(file.name)

      if (this.validFilesCount > this.max) {
        file.status = "error"
        this.removeFile(file)
        this.$refs.attachment.removeFile(file)
        this.error(file, "You can not upload any more files")
        return
      }

      if (rejectedFiles.includes(ext)) {
        file.status = "error"
        this.error(file, "File type not accepted")
        return
      }

      this.fileList.push({
        filename: file.name,
        name: file.name,
        status,
        id: "",
        url: "",
        upload: file.upload,
        response: status === "error" ? "error" : response,
      })

      this.dropping = false
      this.updateList()
      this.$nextTick(() => {
        this.$refs.attachment.processQueue()
      })
    },
    error(file, response) {
      this.upsert(file, response)
    },
    upsert(file, response = {}) {
      let found = findLastIndex(this.fileList, (f) => f.name === file.name)

      let result = {
        name: file.name,
        filename: response.filename,
        status: file.status,
        size: response.size,
        id: response.id,
        uploaded_at: moment().format("DD/MM/YYYY"),
        upload: file.upload,
        response,
      }

      if (found > -1) {
        this.$set(this.fileList, found, result)
      } else {
        this.fileList.push(result)
      }
      this.updateList()
    },
    queueComplete() {
      this.queueCompleted = true
      this.loading = false
    },
    processing() {
      this.loading = true
    },
    sending(file, xhr, formData) {
      formData.append("questionId", this.id)
      formData.append("responseId", this.$route.params.id)
      formData.append("sectionId", this.sectionId)
      formData.append("type", this.type)
      formData.append("name", this.name)
    },
  },
}
</script>
<style lang="scss" scoped>
.uploadSuccessIcon {
  background-image: url("~@/assets/images/success.svg");
  @apply p-8 pb-10 bg-center bg-no-repeat;
}
.upload {
  width: 60px;
  background-image: url("~@/assets/images/upload-icon.svg");
  @apply p-6 bg-center bg-no-repeat;
}
[v-cloak] {
  display: none;
}

.invalidFileIcon {
  background-image: url("~@/assets/images/invalid-2.svg");
  @apply p-8 bg-center bg-no-repeat;
}
</style>
