import axios from "axios"
import qs from "qs"
import { useDummy } from "@/plugins/util"
import store from "@/store"

const instance = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" })
  },
})

instance.defaults.headers.common["X-Api-Key"] = process.env.VUE_APP_API_TOKEN
instance.interceptors.request.use((config) => {
  // Do something before request is sent

  let token = store.getters["auth/token"]
  if (token?.accessToken) {
    config.headers.common["Authorization"] = `Bearer ${token.accessToken}`
  }

  return config
})

const endpointSilent = {
  get: (url, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.get(url, config)
  },
  post: (url, data = {}, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.post(url, data, config)
  },
  patch: (url, data = {}, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.patch(url, data, config)
  },
}

export default endpointSilent
