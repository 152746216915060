import endpointSilent from "./endpoint-silent"

/**
 *
 * @param params Object {names:['name1, name2']}
 * @return {Promise<AxiosResponse<any>>}
 */
const collection = (params = {}) => {
  return endpointSilent.get("/block-content", { params })
}

/**
 * @param machine_name
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
const single = (machine_name, params = {}) => {
  return endpointSilent.get(`/block-content/${machine_name}`, { params })
}

export default {
  collection,
  single,
}
