import inputProps from "../helpers/inputProps"
export default {
  props: {
    ...inputProps,
    id: {
      type: String,
      default: "",
    },
    row_label: {
      type: [String, Boolean],
      default: "",
    },
    format: {
      type: String,
      default: "vertical",
    },
    prefix: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Object, Array],
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    rowIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      current: [],
    }
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.load()
      },
    },
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.current = !this.value ? [] : this.value
    },
    input() {
      this.$emit("input", this.current)
    },
    removeRow() {
      this.$emit("removeRow")
    },
    changed(cellId, content) {
      this.current[cellId] = content
      this.$emit("input", this.current)
      this.$emit("updated")
    },
  },
}
