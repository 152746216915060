import { useDummy } from "@/plugins/util"
import api from "@/store/api"

let items = []

if (useDummy()) {
  items = require("../../mocks/data/responses").default
}

// state
const state = () => ({
  items,
})

// getters
const getters = {
  all: (state) => {
    //return Array(55).fill(state.items[0])
    return state.items
  },
  find: (state, getters) => (id) => {
    return getters.all.find((response) => response.id === id)
  },
  findByTender: (state, getters) => (tenderId) => {
    return getters.all.find((response) => response?.tender?.id === tenderId)
  },
}

// actions
const actions = {
  load({ commit }) {
    return api.responses.collection().then((response) => {
      commit("setResponses", {
        data: response.data,
      })
    })
  },
  async create({ commit }, { tender_id }) {
    let response = await api.responses.create({
      tender_id,
    })
    commit("updateResponses", {
      data: response.data,
    })
    return response.data
  },
  withdraw({ commit }, id) {
    return api.responses.withdraw(id).then((response) => {
      commit("setStatus", { id, data: response.data })
    })
  },
  remove({ commit }, id) {
    return api.responses.remove(id).then((response) => {
      commit("setStatus", { id, data: response.data })
    })
  },
  download({ commit }, params) {
    return api.responses.download(params.responseId, params.fileId, params.filename)
  },
}

// mutations
const mutations = {
  updateResponses(state, { data }) {
    if (!data?.id) {
      return
    }
    let index = state.items.findIndex((t) => t.id === data.id)
    if (index > -1) {
      state.items = [...state.items.slice(0, index), data, ...state.items.slice(index + 1)]
    } else {
      state.items.push(data)
    }
  },
  setResponses(state, { data }) {
    if (!data) {
      return
    }

    state.items = data
  },
  setStatus(state, { id, data }) {},

  setDownload(state, { responseId, fileId, filename, data }) {},
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
