import response from "./response"
import tenders from "./tenders"

export default [
  {
    id: "4ab43e84-639c-4488-abc9-f062d43f0f04",
    tender_id: "bee58702-bf7e-4ef5-914b-5fdacca77b18",
    ...response,
    tender: tenders.find((t) => t.id === "bee58702-bf7e-4ef5-914b-5fdacca77b18"),
  },
  {
    id: "b63d76ae-b1b9-4648-86a5-8750314e62a8",
    tender_id: "dfb816ad-a866-482b-b107-bd026578d7dd",
    ...response,
    status: "draft",
    tender: tenders.find((t) => t.id === "dfb816ad-a866-482b-b107-bd026578d7dd"),
  },
]
