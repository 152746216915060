<template>
  <div class="form-input select-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>

        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <multiselect
          :id="`input_${id}_${name}`"
          :name="`input_${id}_${name}`"
          v-model="current"
          @input="updated"
          :options="options"
          :placeholder="placeholder"
          :multiple="multiple"
          :close-on-select="!multiple"
          :clear-on-select="!multiple"
          :hide-selected="multiple"
          :show-labels="false"
          :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length && !changed }]"
          :value="value"
        >
        </multiselect>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-list
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="current"
        :type="type"
        :print="print"
        :id="id"
        :errors="errors"
      ></read-only-list>
    </validation-provider>
  </div>
</template>
<script>
import { isPlainObject, map } from "lodash"
import Multiselect from "vue-multiselect"
import InputMixin from "./mixins/input"
import MultiInputMixin from "./mixins/multiInput"
import ConditionalMixin from "./mixins/conditional"
import ReadOnlyList from "@/components/form/read-only/ReadOnlyList"
export default {
  components: {
    ReadOnlyList,
    Multiselect,
  },
  mixins: [InputMixin, MultiInputMixin, ConditionalMixin],
  props: {
    placeholder: {
      type: String,
      default: "Select",
    },
    required: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    errors: {
      type: [String, Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      current: [],
      multiple: true,
      auto: false,
    }
  },
  computed: {
    options() {
      if (isPlainObject(this.items)) {
        return map(this.items, (value, key) => ({
          key,
          value,
        }))
      }

      return this.items
    },
  },
  methods: {
    buildValue() {
      let value = this.current.sort((a, b) => {
        return this.items.indexOf(a) - this.items.indexOf(b)
      })

      if (value.length < 1) {
        return ""
      }

      return {
        type: "multi_linetext",
        value: value,
      }
    },
  },
}
</script>
