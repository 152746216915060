<template>
  <div class="relative">
    <card :title="title" class="flex flex-col" title-class="pl-4 pt-2" :loader="true" :loading="loading">
      <template v-slot:header>
        <h5 class="p-4 border-b border-gray-300" v-if="subTitle">{{ subTitle }}</h5>
      </template>
      <ul class="list-none m-0 p-0 mb-4" v-if="tenders && tenders.length > 0">
        <li v-for="(tender, ind) in tenders" :key="tender.id" class="px-2" :class="{ 'bg-gray-200': ind % 2 != 0 }">
          <tender-list-item :tender="tender"></tender-list-item>
        </li>
      </ul>
      <div v-else class="p-8 text-center">
        <div class="mb-4">
          <img src="@/assets/images/ipad.png" class="inline" alt="Ipad" />
        </div>
        <p>
          <span v-text="`No ${status} tenders.`"></span>
          <span v-if="$feature('login')"> Register or login to be notified when there are current tenders available for response.</span>
        </p>
        <button v-if="$feature('login') && !authenticated" @click="login()" :outline="false">
          Login / register
        </button>
      </div>
      <template v-slot:footer>
        <div class="flex justify-center">
          <slot name="footer"></slot>
        </div>
      </template>
    </card>
  </div>
</template>
<script>
import Card from "@/components/Card"
import TenderListItem from "@/components/tenders/TenderListItem"
import { mapActions } from "vuex"
export default {
  components: {
    Card,
    TenderListItem,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: [String, Boolean],
      default: false,
    },
    status: {
      type: String,
      default: "current",
    },
    limitList: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: "normal",
      validator: (val) => ["normal", "latest", "oldest"].includes(val),
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
  },
  computed: {
    tenders() {
      return this.reverse ? [...this.items].reverse() : this.items
    },
    items() {
      if (this.type === "latest") {
        return this.$store.getters["tenders/latest"](this.status, this.limitList)
      }

      if (this.type === "oldest") {
        return this.$store.getters["tenders/oldest"](this.status, this.limitList)
      }

      return this.$store.getters["tenders/whereStatus"](this.status, this.limitList)
    },
  },
}
</script>
