<template>
  <card>
    <div v-if="content" v-dompurify-html="content.description" class="text-content"></div>
  </card>
</template>

<script>
import Card from "@/components/Card"
import DynamicTitleMixin from "@/mixins/dynamic-title"
export default {
  components: {
    Card,
  },
  mixins: [DynamicTitleMixin],
  computed: {
    content() {
      return this.$store.getters["knowledge/findByMachineName"]("withdraw_tender") ?? false
    },
    title() {
      return this.content.title
    },
  },
  data() {
    return {}
  },
  methods: {
    load() {
      this.$store.dispatch("knowledge/byMachineName", "withdraw_tender")
    },
  },
  created() {
    if (!this.$feature("response")) {
      this.$router.push({ name: "home" })
    }

    this.load()
  },
}
</script>
