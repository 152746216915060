<template>
  <div>
    <card :title="title" title-class="pt-2 pl-4" :loading="loading">
      <template v-slot:header>
        <h5 class="p-4 border-b border-gray-300 md:hidden" v-if="subTitle">{{ subTitle }}</h5>
      </template>
      <div v-if="responses.length > 0">
        <table class="w-full md:table table-fixed sortable-table hidden" v-if="searchResult">
          <thead>
            <tr>
              <th @click="toggleSort('close_at')" class="default_sort" :class="{ asc: sort === 'close_at_asc', desc: sort === 'close_at_desc' }">
                Close date
              </th>
              <th @click="toggleSort('title')" class="default_sort px-8 w-1/3" :class="{ asc: sort === 'title_asc', desc: sort === 'title_desc' }">
                Title
              </th>
              <th class="text-center">Completion</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <response-list-item-row v-for="row in searchResult.data.items" :item="row" :key="row.id" />
          </tbody>
        </table>
        <div class="mobile-list md:hidden" v-if="searchResult">
          <ul class="p-0 m-0 mb-4 list-none">
            <response-list-item-list
              v-for="(row, ind) in searchResult.data.items"
              :key="row.id"
              :item="row"
              :class="{ 'bg-gray-200': ind % 2 !== 0 }"
            ></response-list-item-list>
          </ul>
        </div>
      </div>
      <div class="flex justify-center" v-if="responses.length > 0 && !showPagination">
        <button-router-link :to="{ name: 'responses' }"> See all </button-router-link>
      </div>
      <div class="p-4" v-if="responses.length < 1">
        <p>No current tenders</p>
      </div>
    </card>
    <table-pagination
      v-if="searchResult && showPagination"
      :search-result="searchResult"
      :per-page="per_page"
      @updatePageCount="updatePageCount"
      @goToPage="goToPage"
    ></table-pagination>
  </div>
</template>
<script>
import Card from "../Card"
import { mapActions } from "vuex"
import ButtonRouterLink from "@/components/ButtonRouterLink"
import ResponseListItemList from "@/components/responses/ResponseListItemList"
import ResponseListItemRow from "@/components/responses/ResponseListItemRow"
import TablePagination from "@/components/TablePagination"

const configuration = {
  searchableFields: ["title", "locations"],
  sortings: {
    title_asc: {
      field: "tender.title",
      order: "asc",
    },
    title_desc: {
      field: "tender.title",
      order: "desc",
    },
    close_at_asc: {
      field: "tender.close_at",
      order: "asc",
    },
    close_at_desc: {
      field: "tender.close_at",
      order: "desc",
    },
    close_at_status_desc: {
      field: ["tender.status", "tender.close_at"],
      order: ["desc", "asc"],
    },
  },
}

export default {
  components: {
    TablePagination,
    ResponseListItemRow,
    ResponseListItemList,
    Card,
    ButtonRouterLink,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: [String, Boolean],
      default: false,
    },
    status: {
      type: String,
      default: "current",
    },
    perPage: {
      type: Number,
      default: 5,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let page = this.$route?.query?.page || 1
    //let pagination = this.$store.getters["auth/pagination"]

    return {
      query: "",
      itemsjs: {},
      sort_keys: Object.keys(configuration.sortings),
      page: page,
      per_page: this.perPage,
      sort: "close_at_status_desc",
      loading: false,
      loaded: false,
    }
  },
  created() {
    this.loading = true
    this.fetchResponses().then(() => {
      this.build()
      this.loading = false
    })
  },
  computed: {
    responses() {
      return this.$store.getters["responses/all"]
    },
    searchResult() {
      if (!this.loaded) {
        return false
      }

      return this.itemsjs.search({
        query: this.query,
        per_page: this.per_page === "all" ? this.responses.length : this.per_page,
        page: this.page,
        sort: this.sort,
      })
    },
  },
  methods: {
    ...mapActions({
      fetchResponses: "responses/load",
    }),

    build() {
      this.itemsjs = this.$itemsjs(this.responses, configuration)
      this.loaded = true
    },
    toggleSort(column) {
      this.sort = this.sort === `${column}_asc` ? `${column}_desc` : `${column}_asc`
    },
    updatePageCount(perPage) {
      this.per_page = perPage
      this.$store.commit("auth/setPagination", { per_page: perPage })
    },
    goToPage(page) {
      this.setPage(page)
      this.page = page
    },
    setPage(page) {
      this.$router
        .push({
          name: this.$route.name,
          query: { page },
        })
        .catch(() => {})
    },
  },
  watch: {
    per_page() {
      this.page = 1
    },
    query() {
      this.page = 1
    },
  },
}
</script>
<style lang="scss" scoped>
.title {
  @apply no-underline;
  &:hover {
    @apply underline;
  }
}
</style>
