import Section from "./Section"
import Submit from "./Submit"
import UploadReview from "./UploadReview"
import BeginResponse from "./BeginResponse"
import Submitted from "./Submitted"
import { auth, response } from "@/routes/middleware"
import { guid } from "@/plugins/util"

export default [
  {
    path: "",
    name: "response",
    component: Section,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: "begin-response",
    name: "response-begin",
    component: BeginResponse,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: "submit",
    name: "submit",
    component: Submit,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: "submitted",
    name: "submitted",
    component: Submitted,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: `:section(${guid})?`,
    name: "response-section",
    component: Section,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: "review/uploads",
    name: "uploads-review",
    component: UploadReview,
    meta: { middleware: [auth, response], label: false },
  },
  {
    path: "review/:section?",
    name: "section-review",
    component: Section,
    meta: { middleware: [auth, response], label: false },
  },
]
