<template>
  <div>
    <card :loading="loading">
      <div v-if="!success && response" class="p-2 flex flex-col items-center">
        <div v-if="action === 'delete'">
          <p class="font-bold text-lg">
            You are about to {{ action }} your response to
            <router-link class="title" :to="{ name: 'response', params: { id: response.id } }">{{ response.tender.title }}</router-link>
          </p>
          <div class="list-disc">
            <ul>
              <li>All contents of your response will be deleted</li>
              <li>the contents will not be available for resuse in future tender responses</li>
              <li>once a response is withdrawn, you cannot retrieve the response</li>
            </ul>
          </div>
          <div>
            <p>Are you sure you want to continue?</p>
          </div>
        </div>
        <div v-if="action === 'unsubmit'">
          <p class="font-bold text-lg">
            You are about to unsubmit your response to
            <router-link class="title" :to="{ name: 'response', params: { id: response.id } }">{{ response.tender.title }}</router-link>
          </p>
          <div class="list-disc">
            <ul>
              <li>
                Once you unsubmit your response, the department will not receive your response <br />unless you submit it before the closing date and
                time.
              </li>
              <li>Please ensure you submit your response before the closing date and time.</li>
            </ul>
          </div>
          <div>
            <p>Are you sure you want to continue?</p>
          </div>
        </div>
        <div>
          <router-link class="button inline-flex button--outline m-4" :to="{ name: 'responses' }">Cancel</router-link>
          <button class="inline-flex button button--filled m-4" @click.prevent="actionResponse">Yes, {{ action }} my response</button>
        </div>
      </div>
      <div v-if="success" class="p-2 flex flex-col items-center">
        <div>
          <p class="font-bold text-lg">Your response has been {{ action === "unsubmit" ? "unsubmitted" : "deleted" }}</p>
        </div>
        <div>
          <div class="uploadSuccessIcon"></div>
        </div>
        <div>
          <router-link class="inline-flex button button--filled m-4" :to="{ name: 'responses' }">Return to My Tender Responses</router-link>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import Card from "@/components/Card"
import { mapActions } from "vuex"
import DynamicTitleMixin from "@/mixins/dynamic-title"
const titles = {
  unsubmit: "Unsubmit",
  delete: "Delete",
}

export default {
  components: {
    Card,
  },
  mixins: [DynamicTitleMixin],
  data() {
    return {
      success: false,
      loading: false,
    }
  },
  computed: {
    response() {
      return this.$store.getters["responses/find"](this.$route.params.id)
    },
    title() {
      if (titles.hasOwnProperty(this.$route.params.action)) {
        return `${titles[this.$route.params.action]} tender`
      }

      return `${this.$route.params.action} tender`
    },
    action() {
      return this.$route.params.action
    },
  },
  async created() {
    if (!this.response) {
      await this.load()
    }
  },
  methods: {
    ...mapActions({
      load: "responses/load",
      delete: "responses/remove",
      withdraw: "responses/withdraw",
    }),
    async actionResponse() {
      this.loading = true
      if (this.action === "delete") {
        await this.delete(this.response.id)
      } else if (this.action === "unsubmit") {
        await this.withdraw(this.response.id)
      }
      await this.load()
      this.success = true
      this.loading = false
    },
  },
}
</script>
