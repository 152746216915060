<template>
  <div class="w-full mb-2 bg-white rounded-lg accordion-item" :class="{ open: show, opened: opened, 'p-4': !readOnly }">
    <div @click="toggle" class="accordion" :class="{ 'cursor-pointer': !readOnly }">
      <div class="font-bold title" :class="{ dropdown: !readOnly }" v-text="title"></div>
      <span class="text-required" v-if="validation.includes('required')"> *</span>
    </div>
    <div :class="{ visible: show, opened: opened }" class="content">
      <component
        v-for="(field, cellId) in fields"
        :key="field.id"
        :is="field.component"
        v-bind="field"
        :updates-form="false"
        :id="`${field.id}[${rowIndex}]`"
        :value="current[cellId] ? current[cellId].value : ''"
        :name="`${field.name}[${rowIndex}][${cellId}]`"
        @changed="changed(cellId, $event)"
        :read-only="readOnly"
        :immediate="immediate"
        :name-prefix="`${title} - `"
        :print="print"
      >
      </component>
      <div v-if="!readOnly && rowIndex > 0" class="mt-3">
        <button @click.prevent="removeRow" class="text-red-600 underline">Delete</button>
      </div>
    </div>
  </div>
</template>
<script>
import { fieldComponentMixin } from "./helpers/fieldTypes"
import rowMixin from "./mixins/row"
export default {
  name: "VerticalTableRow",
  mixins: [fieldComponentMixin, rowMixin],
  data() {
    return {
      show: true,
      opened: true,
    }
  },
  computed: {
    title() {
      return `${this.prefix} ${this.rowIndex + 1}`
    },
  },
  methods: {
    toggle() {
      if (this.readOnly) {
        this.show = true
        this.opened = true
        return
      }

      if (this.show) {
        this.opened = false
        this.show = false
      } else {
        this.show = true
        setTimeout(() => {
          this.opened = true
        }, 300)
      }
    },
    setToggle(state) {
      this.show = state
      this.opened = state
    },
  },
  created() {
    this.$events.$on("rows:toggle", this.setToggle)
  },
  destroyed() {
    this.$events.$off("rows:toggle", this.setToggle)
  },
}
</script>
