<template>
  <div class="form-input time-range-input" v-if="conditionsMet">
    <validation-provider
      ref="provider"
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div
          class="flex items-stretch text-gray-500 bg-gray-100 border border-gray-500 rounded-md"
          :class="{ 'border-error border-2': errors.length > 0 && !changed }"
        >
          <div class="h-full p-5 border-r border-gray-500 clock-icon rounded-r-md" @click="$refs.input.$refs.input.focus()"></div>
          <date-picker
            prefix-class="xmx"
            @input="updated"
            v-model="timeRange"
            :append-to-body="appendBody"
            :popup-style="{ left: 0, marginTop: '10px' }"
            :input-attr="{ name: `input_${id}_${name}`, id: `input_${id}_${name}` }"
            type="time"
            range
            rangeSeparator=" - "
            format="hh:mm a"
            valueType="format"
            placeholder="hh:mm a - hh:mm a"
            ref="input"
          ></date-picker>
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :print="print"
        :type="type"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  components: { ReadOnlyString, FieldError, DatePicker },
  name: "TimePickerRangeInput",
  mixins: [InputMixin, ConditionalMixin],
  data() {
    return {
      auto: false,
      timeRange: {},
      appendBody: false,
    }
  },
  methods: {
    load() {
      this.current = this.value
      this.timeRange = this.value.split(" - ")
    },
    updated() {
      this.current = this.formatTime(this.timeRange)
      if (this.current === "") {
        this.timeRange = ""
      }

      this.input()
      this.update()
    },
    formatTime(timeArray) {
      if (timeArray[0] && timeArray[1]) {
        return timeArray.join(" - ")
      }

      return ""
    },
  },
}
</script>
