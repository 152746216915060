<template>
  <input type="text" v-model="current" id="search-input" spellcheck="false" aria-label="Search" class="search" :placeholder="placeholder" />
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Write your question here...",
    },
  },
  data: () => ({
    current: "",
  }),
  watch: {
    current() {
      this.$emit("input", this.current)
    },
  },
  created() {
    this.current = this.value
  },
}
</script>
