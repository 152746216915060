import Vue from "vue"
import Vuex from "vuex"
import VuexPersistence from "vuex-persist"

import auth from "./modules/auth"
import tenders from "./modules/tenders"
import form from "./modules/form"
import response from "./modules/response"
import responses from "./modules/responses"
import knowledge from "./modules/knowledge"
import blockContent from "./modules/block-content"
import createLogger from "../plugins/createLogger"

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== "production"

const vuexLocal = new VuexPersistence({
  key: "tenders",
  storage: window.localStorage,
  //storage: window.cookieStorage,
  //storage: window.sessionStorage,
  modules: ["auth"],
  // modules: [],
  // reducer: (state) => ({
  //   auth: {
  //     token: state.auth.token,
  //     account: state.auth.account,
  //     userLocation: state.auth.userLocation,
  //   },
  //   tenders: {
  //     pagination: state.tenders.pagination,
  //   },
  // }),
})

const store = new Vuex.Store({
  modules: {
    auth,
    tenders,
    form,
    response,
    responses,
    knowledge,
    blockContent,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin],
})

export default store
