<template>
  <div class="pdf print" v-if="printing">
    <section slot="pdf-content" class="p-10 bg-white text-sm">
      <h1 class="text-xl">Response to tender: {{ tenderTitle }}</h1>
      <div v-html="safeDescription(tenderSummary)" class="mb-8 text-content"></div>
      <div v-for="(section, ind) in builtComponentSections" :key="ind" class="pdf-item">
        <h2 class="text-base">{{ section.title }}</h2>
        <div v-html="safeDescription(section.description)" class="my-4 font-bold text-content"></div>
        <read-only-fields :fields="section.fields"></read-only-fields>
      </div>
    </section>
  </div>
</template>

<script>
import render from "@/plugins/render"
import ReadOnlyFields from "../form/read-only/ReadOnlyFields"

import { mapGetters } from "vuex"
import FormSections from "@/routes/response/mixins/form-sections.js"
import readOnly from "@/routes/response/mixins/readOnly"
import builtComponentSections from "@/routes/response/mixins/builtComponentSections"

export default {
  components: {
    ReadOnlyFields,
  },
  mixins: [FormSections, readOnly, builtComponentSections],
  data() {
    return {
      printing: false,
    }
  },
  computed: {
    ...mapGetters({
      response: "response/get",
    }),
    readOnly() {
      return this.$route.name === "section-review" || !this.editable
    },
    tenderTitle() {
      return this.response?.tender_title ?? ""
    },
    tenderSummary() {
      return this.response?.tender?.summary ?? false
    },
  },
  methods: {
    safeDescription(description) {
      if (!description) {
        return ""
      }

      // this render function uses dompurify so we don't need to run it through the v-dompurify-html attribute
      let text = render.editor(description)

      return text ? text.innerHTML : ""
    },
  },
  created() {
    window.onafterprint = () => {
      console.log("Printing completed...")
      this.printing = false
    }

    this.$events.$on("print:open", () => {
      this.printing = true
      setTimeout(() => {
        window.print()
      }, 2000)
    })
  },
  destroyed() {
    this.$events.$off("print:open")
  },
}
</script>
