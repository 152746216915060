<template>
  <div class="form-input read-only-input" :class="{ 'rounded bg-red-100 p-2.5': errors.length }">
    <div class="print-break">
      <div class="mb-2" v-if="showLabel">
        <span v-text="label" :class="{ 'text-required': errors.length }"></span>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <div class="whitespace-pre-line" :class="{ 'field-border': !errors.length, 'py-3': (value || !errors.length) && !print }" v-text="value"></div>
      <field-error :errors="errors" :id="id"></field-error>
    </div>
  </div>
</template>
<script>
import inputProps from "./../helpers/inputProps"
import FieldError from "@/components/form/FieldError"
export default {
  components: { FieldError },
  props: {
    ...inputProps,
  },
}
</script>
