<template>
  <div v-if="fields.length > 0">
    <div v-for="field in fields" :key="field.id">
      <component
        :is="field.component"
        v-bind="field"
        :value="field.response && field.response.value ? field.response.value : ''"
        :read-only="true"
        :immediate="true"
        :print="true"
      ></component>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
