<template>
  <div v-if="builtFields.length > 0">
    <div v-for="field in builtFields" :key="field.id">
      <component
        :is="field.component"
        v-bind="field"
        @input="input"
        :value="field.response.value"
        @updated="update"
        :immediate="readOnly || immediate"
        :read-only="readOnly"
        :key="`input_${field.id}`"
      ></component>
    </div>
  </div>
</template>
<script>
import { fieldComponents as baseFieldComponents } from "@/components/form/helpers/fieldTypes"
import VerticalTableInput from "./VerticalTableInput"
import HorizontalTableInput from "./HorizontalTableInput"
import { isEmpty } from "lodash"

export const fieldComponents = {
  ...baseFieldComponents,
  table_vertical: VerticalTableInput,
  table_horizontal: HorizontalTableInput,
}

export const getComponent = (field) => {
  let type = field.type
  if (type === "table") {
    type = `${type}_${field.format}`
  }

  return fieldComponents.hasOwnProperty(type) ? fieldComponents[type] : false
}

export default {
  props: {
    formSection: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    builtFields() {
      if (!this.formSection.fields) {
        return []
      }

      return this.formSection.fields.map((field) => {
        let response = false

        if (!isEmpty(this.section)) {
          response = this.section.fields.find((f) => f.question_id === field.id)
        }

        return {
          ...field,
          sectionId: this.formSection.id,
          component: getComponent(field),
          response: response ? response : { value: "" },
        }
      })
    },
  },
  data() {
    return {
      current: {},
      errors: [],
    }
  },
  created() {
    this.current = this.value
  },
  watch: {
    current() {
      this.input()
    },
  },
  methods: {
    update(value, field) {
      this.$store.dispatch("response/storeField", {
        section_id: this.formSection.id,
        field: field,
        value: value ? value : "",
      })
      this.$events.$emit("value:updated")
    },
    input() {
      this.$emit("input", this.current)
    },
  },
}
</script>
