import { render, staticRenderFns } from "./HelpHome.vue?vue&type=template&id=7c635ff8&scoped=true&"
import script from "./HelpHome.vue?vue&type=script&lang=js&"
export * from "./HelpHome.vue?vue&type=script&lang=js&"
import style0 from "./HelpHome.vue?vue&type=style&index=0&id=7c635ff8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c635ff8",
  null
  
)

export default component.exports