module.exports =
  "" +
  "<h3>Links to documents</h3>" +
  "<p>It is advised that anyone who is responding to a tender should carefully read the following documents.</p>\n" +
  "<ul>\n" +
  '    <li><a href="#">RFT</a></li>\n' +
  '    <li><a href="#">FAQs</a></li>\n' +
  '    <li><a href="#">Addenda</a></li>\n' +
  "</ul>\n" +
  "<h3>Link to RFT</h3>\n" +
  "<h3>Conditions of participation</h3>\n" +
  "<p>Please review the conditions of participation. These are the conditions that you must meet to be successful in the tender.</p>\n" +
  "<ul>\n" +
  "    <li>lorem ipsum</li>\n" +
  "    <li>lorem ipsum</li>\n" +
  "    <li>lorem ipsum</li>\n" +
  "    <li>lorem ipsum</li>\n" +
  "    <li>lorem ipsum</li>\n" +
  "</ul>\n" +
  "<h3>Help Guide</h3>\n" +
  "<p>You are about to begin a response to <strong>Harvest Trail Services Request for Tender</strong>. The following resources are available to assist you by giving you information about how to complete the response.</p>\n" +
  "<ul>\n" +
  '    <li><a href="#">How do I respond to a tender?</a></li>\n' +
  '    <li><a href="#">FAQ</a></li>\n' +
  '    <li><a href="#">How do I use the portal?</a></li>\n' +
  "</ul>\n" +
  "<h3>Contact</h3>\n" +
  '<p>If you have an enquiry which is not answered by the Help Guide, <a href="/contact">please contact the employment services purchasing hotline</a></p>\n' +
  "\n"
