<template>
  <div class="form-input text-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>

        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div
          class="flex items-stretch text-gray-500 bg-gray-100 border border-gray-500 rounded-md"
          :class="{ 'border-error border-2': errors.length > 0 && !changed }"
        >
          <div class="h-full p-5 border-r border-gray-500 icon rounded-r-md calendar-icon" @click="$refs.input.$refs.input.focus()"></div>
          <date-picker
            prefix-class="xmx"
            v-model="date"
            :append-to-body="appendBody"
            :popup-style="{ left: 0, marginTop: '10px' }"
            format="DD-MM-YYYY"
            placeholder="DD-MM-YYYY - DD-MM-YYYY"
            :input-attr="{ name: `input_${id}_${name}`, id: `input_${id}_${name}` }"
            ref="input"
            range
            @input="updated"
            rangeSeparator=" - "
            v-debounce:300="updated"
          ></date-picker>
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :print="print"
        :type="type"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import { isEmpty } from "lodash"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  components: { ReadOnlyString, FieldError, DatePicker },
  name: "DateRangeInput",
  mixins: [InputMixin, ConditionalMixin],
  data() {
    return {
      appendBody: false,
      date: "",
      auto: false,
    }
  },
  methods: {
    load() {
      this.formatDates()
    },
    updated() {
      if (this.date[0] && this.date[1]) {
        let fromDate = this.$moment(this.date[0]).format("DD-MM-YYYY")
        let toDate = this.$moment(this.date[1]).format("DD-MM-YYYY")
        this.current = `${fromDate} - ${toDate}`
      } else {
        this.current = ""
        this.date = ""
      }

      this.input()
      this.update()
    },
    formatDates() {
      if (isEmpty(this.value)) {
        return
      }

      let splitDate = this.value.split(" - ")
      if (splitDate.length === 2) {
        this.date = [this.$moment(splitDate[0], "DD-MM-YYYY").toDate(), this.$moment(splitDate[1], "DD-MM-YYYY").toDate()]
      }
    },
  },
}
</script>
