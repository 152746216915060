<template>
  <div
    class="shadow-xl rounded p-4 md:p-7 mb-6 relative"
    :class="{ 'bg-white': theme === 'light', 'bg-primary text-white': theme !== 'light', 'min-h-200': loading }"
  >
    <loader v-if="loading"></loader>
    <h3 v-if="title" v-text="title" :class="titleClass"></h3>
    <slot name="header"></slot>
    <div class="flex-grow" :class="contentClass">
      <slot />
    </div>
    <slot name="footer"></slot>
  </div>
</template>
<script>
import Loader from "@/components/Loader"
export default {
  components: {
    Loader,
  },
  props: {
    title: {
      type: [String, Boolean],
      default: false,
    },
    titleClass: {
      type: [String, Object, Array],
      default: "",
    },
    contentClass: {
      type: [String, Object, Array],
      default: "",
    },
    theme: {
      type: String,
      default: "light",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
