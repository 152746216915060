<template>
  <div class="flex justify-between w-full">
    <router-link
      :to="{ name: inReview, params: { id: $route.params.id, section: inPageNavigation.prev.id } }"
      v-if="inPageNavigation.prev"
      class="flex items-center justify-start w-1/2 pr-4 no-underline text-primary-dark"
    >
      <svg width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
        <path d="M26 44L6 24L26 4" stroke="#287DB2" stroke-width="8" stroke-linecap="round" />
      </svg>
      {{ inPageNavigation.prev.title }}
    </router-link>
    <router-link
      :to="{ name: inReview, params: { id: $route.params.id, section: inPageNavigation.next.id } }"
      v-if="inPageNavigation.next"
      class="flex items-center justify-end w-1/2 pl-4 ml-auto text-right no-underline text-primary-dark"
    >
      {{ inPageNavigation.next.title }}
      <svg width="30" height="48" viewBox="0 0 30 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-4">
        <path d="M4 4L24 24L4 44" stroke="#287DB2" stroke-width="8" stroke-linecap="round" />
      </svg>
    </router-link>
    <router-link v-if="!readOnly && inPageNavigation.last" :to="{ name: 'submit' }" class="button button--filled">Continue to submission</router-link>
  </div>
</template>
<script>
export default {
  props: {
    formSections: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inReview() {
      return this.$route.name === "section-review" ? "section-review" : "response-section"
    },
    inPageNavigation() {
      let x = this.formSections.findIndex((section) => section.id === this.$route.params.section)
      x = x === -1 ? 0 : x
      let prev = this.formSections[x - 1]
      let next = this.formSections[x + 1]
      let last = this.formSections.length - 1 == x
      return { prev, next, last }
    },
  },
}
</script>
