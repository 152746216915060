<template>
  <div v-if="timeRemainingLabel" class="p-2 rounded font-bold text-center" :class="remainingClasses">
    {{ timeRemainingLabel }}
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    remainingClasses() {
      if (this.$moment(this.item.close_at).isBefore(this.$moment().add(7, "days"))) {
        return ["bg-red-100", "text-red-800"]
      }

      if (this.$moment(this.item.close_at).isBefore(this.$moment().add(14, "days"))) {
        return ["bg-orange-100", "text-orange-800"]
      }

      return ["bg-green-100", "text-green-800"]
    },
    atmType() {
      return this.item.type === "notice" ? "Notice" : "Tender"
    },
    timeRemainingLabel() {
      return this.$moment(this.item.close_at).isBefore(this.$moment()) ? null : this.$moment(this.item.close_at).fromNow(true) + " left to respond"
    },
  },
}
</script>
