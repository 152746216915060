<template>
  <div>
    <article
      v-for="(addendum, ind) in addenda"
      :key="ind"
      class="pb-8 border-b border-gray-400"
      :class="ind === addenda.length - 1 ? 'border-b-0' : 'mb-10'"
    >
      <h2>Addendum No.{{ ind + 1 }}</h2>
      <h3 class="mb-0">{{ addendum.title }}</h3>
      <time :datetime="addendum.created_at" class="block mt-2 mb-4 font-bold">{{ getHumanDate(addendum.created_at) }}</time>
      <router-link :to="{ name: 'tender-addendum', params: { addendumId: addendum.id } }" class="button inline-flex button--filled"
        >Read addendum</router-link
      >
    </article>
  </div>
</template>

<script>
export default {
  props: {
    tender: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    addenda() {
      return this.tender?.addenda ?? []
    },
  },
  methods: {
    getHumanDate(date) {
      return this.$moment(date, "YYYY-MM-DD").format("DD MMMM YYYY")
    },
    setReadStatus(addenda) {
      addenda.read = true
    },
  },
}
</script>
