<template>
  <div v-if="$feature('login')">
    <div v-if="authenticated" class="relative z-50 p-2 rounded-t rounded-bl" v-click-outside="close" :class="{ 'bg-white shadow-lg': open }">
      <button @click.prevent="open = !open" class="flex justify-center items-center">
        <div class="rounded-full bg-green-700 h-12 w-12 flex items-center leading-none justify-center">
          <span class="text-white" v-text="initials"></span>
        </div>
        <div class="pl-4 flex-col items-end justify-center hidden lg:flex">
          <div class="text-lg" v-text="`${name ? name : 'User Account'}`"></div>
        </div>
      </button>
      <div class="absolute top-full right-0 bg-white shadow-lg p-4 z-50 rounded-b flex flex-col items-end w-64 lg:w-auto" v-if="open">
        <div class="text-lg block lg:hidden" v-text="`${name}`"></div>

        <ul class="list-none p-0 m-0">
          <li class="py-2">
            <a class="block no-underline hover:underline" href="#" @click.prevent="editProfile()">Edit profile</a>
          </li>
          <li class="py-2"><logout /></li>
        </ul>
      </div>
    </div>
    <button class="button inline-flex button--filled" @click="login()" v-else-if="loginAvailable">
      Login / register
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"
import Logout from "@/components/layout/Logout"

export default {
  components: {
    Logout,
  },
  data: () => {
    return {
      loading: false,
      open: false,
    }
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      loginPopup: "auth/loginPopup",
      logout: "auth/logout",
      editProfile: "auth/editProfile",
    }),
    close() {
      this.open = false
    },
  },
  computed: {
    ...mapGetters({
      account: "auth/account",
      name: "auth/name",
      loginAvailable: "auth/loginAvailable",
    }),
    role() {
      return this.account?.idTokenClaims?.jobTitle ?? ""
    },
    initials() {
      return this.name
        ? this.name
            .match(/\b(\w)/g)
            .slice(0, 2)
            .join("")
        : ""
    },
  },
  created() {
    this.$events.$on("menu:close", () => {
      this.close()
    })
  },
  destroyed() {
    this.$events.$off("menu:close")
  },
}
</script>
