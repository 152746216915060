<template>
  <div class="form-input text-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div
          class="flex items-stretch text-gray-500 bg-gray-100 border border-gray-500 rounded-md"
          :class="{ 'border-error border-2': errors.length && !changed }"
        >
          <div class="h-full px-4 py-2 overflow-hidden border-r border-gray-500 rounded-r-md" @click="$refs.input.focus()">$</div>
          <input
            type="text"
            ref="input"
            :name="name"
            class="border-0 rounded-none"
            :id="`input_${id}_${name}`"
            v-model="current"
            :placeholder="placeholder"
            v-debounce:300="update"
          />
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :print="print"
        :label="label"
        :value="value"
        :type="type"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  name: "CurrencyInput",
  components: { ReadOnlyString, FieldError },
  mixins: [InputMixin, ConditionalMixin],
  watch: {
    deep: true,
    current(newVal) {
      if (newVal.includes(".")) {
        this.current = newVal.split(".")[0] + "." + newVal.split(".")[1].slice(0, 2)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
input[type="number"] {
  @apply flex-grow rounded-l-none border-0;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
