import endpoint from "./endpoint"
import endpointSilent from "./endpoint-silent"

const collection = (params = {}) => {
  //#todo this better
  params.per_page = 1000
  return endpoint.get("/responses", params)
}

const single = (id, params = {}) => {
  return endpoint.get(`/responses/${id}`, params)
}

const create = (data = {}) => {
  return endpoint.post(`/responses`, data)
}

const update = (id, data = {}) => {
  return endpointSilent.patch(`/responses/${id}`, data)
}

const submit = (id, data = {}) => {
  return endpoint.patch(`/responses/${id}/submit`, data)
}

const withdraw = (id) => {
  return endpoint.patch(`/responses/${id}/withdraw`)
}

const remove = (id) => {
  return endpoint.delete(`/responses/${id}`)
}

const download = (responseId, fileId, filename) => {
  return endpoint.download(`/response/${responseId}/attachment/${fileId}/${filename}`, filename)
}

const sectionsRead = (id, data = {}) => {
  return endpointSilent.patch(`/responses/${id}/sections/read`, data)
}

const sectionsComplete = (id, data = {}) => {
  return endpointSilent.patch(`/responses/${id}/sections/complete`, data)
}

export default {
  collection,
  single,
  create,
  update,
  submit,
  withdraw,
  remove,
  download,
  sectionsRead,
  sectionsComplete,
}
