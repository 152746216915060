<template>
  <div>
    <h2>{{ textType }} detail</h2>
    <div class="xl:flex mb-4" v-if="tender">
      <div class="table-overflow-wrapper xl:flex-1 xl:mr-4 mt-0">
        <table class="introduction-table">
          <tr v-if="tender.rft_id">
            <td>ATM ID</td>
            <td class="font-bold">{{ tender.rft_id }}</td>
          </tr>
          <tr v-if="tender.type">
            <td>Type</td>
            <td class="font-bold capitalize">{{ tender.type }}</td>
          </tr>
          <tr v-if="tender.close_at">
            <td>Publish date</td>
            <td class="font-bold" v-text="$moment(tender.release_at).format('Do MMMM YYYY')"></td>
          </tr>
          <!-- <tr v-if="tender.category">
            <td>Category</td>
            <td>Category</td>
            <td class="font-bold">{{ tender.category }}</td>
          </tr>
          <tr v-if="tender.locations && tender.locations.length > 0">
            <td>Location</td>
            <td class="font-bold">{{ tender.locations.join(", ") }}</td>
          </tr> -->
          <tr v-if="tender.agency">
            <td>Agency</td>
            <td class="font-bold">{{ agency }}</td>
          </tr>
        </table>
      </div>
      <div class="xl:flex-1 xl:ml-4">
        <div class="xl:mb-12">
          <div class="mb-1 hidden md:block" v-if="tender.close_at">
            <strong>Closing date and time: </strong>
            <span v-text="$moment(tender.close_at).format('h:mma Do MMMM YYYY')"></span>
          </div>

          <time-remaining :item="tender" v-if="tender"></time-remaining>
        </div>
        <div class="contact-information p-4 bg-gray-200 mt-8 mb-8 lg:mb-0">
          <p>For further information, please contact the <strong>Employment Services Purchasing Hotline.</strong></p>
          <p>
            <strong>Phone</strong>
            <span class="block w-full">1300 733 514</span>
          </p>
          <p>
            <strong>Email</strong>
            <span class="block w-full">
                <a class="font-bold" href="mailto:espurchasing@dewr.gov.au" target="_blank">espurchasing@dewr.gov.au</a>
            </span>
          </p>
        </div>
      </div>
    </div>
    <div v-if="tender.description" class="mb-4">
      <h3>{{ textType }} description</h3>
      <div v-html="safeDescription"></div>
    </div>

    <!-- <div v-if="tender.locations && tender.locations.length > 0">
      <h3>Location</h3>
      <p v-text="tender.locations.join(', ')"></p>
    </div>
    -->
    <file-list v-if="files" :files="files"></file-list>
  </div>
</template>
<script>
import render from "@/plugins/render"
import ButtonLink from "@/components/ButtonLink"
import FileList from "@/components/FileList"
import TimeRemaining from "@/components/TimeRemaining"
export default {
  props: {
    tender: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    timeRemaining() {
      return this.$moment(this.tender.close_at).isBefore(this.$moment())
        ? `${this.textType} has closed`
        : this.$moment(this.tender.close_at).fromNow(true) + " left to respond"
    },
    safeDescription() {
      if (!this.tender?.description) {
        return ""
      }
      // this render function uses dompurify so we don't need to run it through the v-dompurify-html attribute
      return render.editor(this.tender.description)?.innerHTML ?? ""
    },
    files() {
      if (!this.tender?.files) {
        return false
      }

      let found = this.tender.files.filter((f) => f.type !== "ATM")

      return found.length > 0 ? found : false
    },
    textType() {
      return this.tender?.type && this.tender.type === "notice" ? "Notice" : "Tender"
    },
    agency() {
        let mogDate = new Date("2022-03-20");        
        return this.$moment(this.tender.close_at).isBefore(this.$moment(mogDate)) ?
            "Department of Education, Skills and Employment" :
            "Department of Employment and Workplace Relations"
    }
  },
  components: {
    TimeRemaining,
    FileList,
    ButtonLink,
  },
}
</script>
