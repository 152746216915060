<template>
  <alert v-if="tender && tender.addenda_count" class="mb-4" type="addendum">
    This tender has
    <router-link v-if="!isResponse" :to="{ name: 'tender-addenda', params: { id: tender.id } }"
      >{{ tender.addenda_count }} {{ tender.addenda_count == 1 ? "Addendum" : "Addenda" }}</router-link
    >
    <router-link v-if="isResponse" target="_blank" :to="{ name: 'tender-addenda', params: { id: tender.id } }"
      >{{ tender.addenda_count }} {{ tender.addenda_count == 1 ? "Addendum" : "Addenda" }}</router-link
    >. You are responsible for ensuring you have read all Addenda issued and understand how they may influence your response.
  </alert>
</template>
<script>
import Alert from "@/components/Alert"
export default {
  components: { Alert },
  props: {
    tender: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isResponse() {
      return this.$route.name === "response"
    },
  },
}
</script>
