<template>
  <div class="form-input abn-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      ref="provider"
      :rules="!hasData && validation.includes('required') ? 'required' : null"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div class="border rounded p-4 border-b border-gray-300" v-if="filled">
          <div class="flex items-start">
            <abn-lookup-details :item="current" class="w-full"></abn-lookup-details>
            <button type="button" @click.prevent="clear()" class="inline-flex ml-2 w-4">
              <img src="@/assets/images/cancel.svg" alt="Change" />
            </button>
          </div>
        </div>
        <input
          v-else
          type="text"
          ref="input"
          v-model="search"
          @keydown.enter.prevent="find()"
          maxlength="100"
          :name="`input_${id}_${name}`"
          :id="`input_${id}_${name}`"
          class="search-input"
          :class="{ error: errors.length > 0 && !changed }"
        />
        <div v-if="hasData" class="shadow-xl rounded relative top-[-1px] mb-6 relative border-gray-500 bg-white p-1 border">
          <button
            v-for="(item, index) in abnResult"
            :key="index"
            :class="{ 'pb-2': index + 1 < abnResult.length }"
            class="block w-full text-left rounded hover:bg-blue-100 focus:bg-blue-200 p-2 leading-tight"
            @click.prevent="select(item)"
          >
            <div>
              <strong>Name: </strong>
              <span v-text="item.name"></span>
            </div>
            <div>
              <strong>ABN: </strong>
              <span v-text="item.abn"></span>
            </div>
            <div>
              <strong>Current: </strong>
              <span v-text="item.isCurrent ? 'Yes' : 'No'"></span>
            </div>
            <div v-if="item.type">
              <strong>Type: </strong>
              <span v-text="item.type"></span>
            </div>
            <div>
              <strong>Location: </strong>
              <span v-text="`${item.postcode} ${item.postcode && item.state ? ' - ' : ''} ${item.state}`"></span>
            </div>
          </button>
        </div>
        <div v-if="!filled">
          <button
            type="button"
            @click.prevent="find()"
            :disabled="!search.length"
            class="inline-flex mt-2 button button--outline"
            v-text="!loading ? 'Lookup ABN' : 'Searching'"
          ></button>
        </div>
        <field-error v-if="apiErrors.length > 0" :errors="apiErrors" :id="id"></field-error>
        <field-error v-if="errors.length > 0 && !changed" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-abn
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="current"
        :type="type"
        :errors="errors"
        :id="id"
        :print="print"
      ></read-only-abn>
    </validation-provider>
  </div>
</template>
<script>
import api from "@/store/api/abn"
import { isEmpty } from "lodash"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyAbn from "@/components/form/read-only/ReadOnlyAbn"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import AbnLookupDetails from "@/components/form/AbnLookupDetails"

export default {
  name: "AbnLookupInput",
  components: { ReadOnlyAbn, FieldError, AbnLookupDetails },
  mixins: [InputMixin, ConditionalMixin],
  data() {
    return {
      results: {},
      open: false,
      loading: false,
      search: "",
      current: {},
      apiErrors: [],
    }
  },
  computed: {
    filled() {
      return !isEmpty(this.current)
    },
    abnResult() {
      let data = []

      if (this.results.abnCheckResponse) {
        data.push({
          ...this.results.abnCheckResponse,
          isCurrent: this.results.abnCheckResponse.abnStatus === "Active",
          name: this.results.abnCheckResponse.entityName,
          state: this.results.abnCheckResponse.addressState,
          postcode: this.results.abnCheckResponse.addressPostcode,
        })
      }

      if (this.results.abnSearchResponse?.length) {
        return [...data, ...this.formatSearch(this.results.abnSearchResponse)]
      }

      return data
    },
    hasData() {
      return this.results.abnCheckResponse != null || this.results.abnSearchResponse != null
    },
    currentCheck() {
      return this.current.entityStatus.entityStatusCode === "Active" ? "Yes" : "No"
    },
    formattedValue() {
      return `${this.current?.ABN.identifierValue} - ${this.current?.legalName.givenName} ${this.current?.legalName.otherGivenName || ""} ${
        this.current?.legalName.familyName
      } - Current: ${this.currentCheck} - ${this.current?.mainBusinessPhysicalAddress.postcode} ${
        this.current?.mainBusinessPhysicalAddress.stateCode
      }`
    },
  },
  mounted() {
    if (!isEmpty(this.current)) {
      this.$refs.provider.syncValue(this.current)
    }
  },
  methods: {
    formatSearch(items) {
      return items.map((item) => {
        let name = ""
        let type = ""

        if (item?.businessName?.organisationName) {
          name = item?.businessName?.organisationName
          type = "Business Name"
        } else if (item?.mainName?.organisationName) {
          name = item?.mainName?.organisationName
          type = "Entity Name"
        } else if (item?.otherTradingName?.organisationName) {
          name = item?.otherTradingName?.organisationName
          type = "Other Name"
        } else if (item?.legalName?.fullName) {
          name = item?.legalName?.fullName
          type = "Entity Name"
        } else if (item?.mainTradingName?.organisationName) {
          name = item?.mainTradingName?.organisationName
          type = "Entity Name"
        }

        return {
          // the search only finds active items
          abn: item?.ABN?.identifierValue,
          isCurrent: item?.ABN?.identifierStatus === "Active",
          name: name,
          type,
          state: item?.mainBusinessPhysicalAddress?.stateCode || "",
          postcode: item?.mainBusinessPhysicalAddress?.postcode || "",
        }
      })
    },
    load() {
      const parsed = jsonParse(this.value, {
        value: {},
      })

      this.current = parsed?.value || {}
    },
    clear() {
      this.search = ""
      this.current = {}
      this.apiErrors = []
      this.$refs.provider.syncValue("")
      this.updated()
    },
    async find() {
      this.apiErrors = []

      if (!this.search) {
        this.apiErrors.push(`Please enter and business name or abn`)
        return
      }

      this.loading = true

      api
        .search(this.search)
        .then((response) => {
          this.results = response.data

          if (this.results.abnCheckResponse == null && this.results.abnSearchResponse == null) {
            throw new Error("no results")
          }
        })
        .catch(() => {
          this.apiErrors.push(`${this.search} is not a valid ABN or business name. Please review and try again`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    async fetchDetails(item) {
      return await api.details(item.abn)
    },
    updated() {
      this.update()
      this.input()
    },
    select(item) {
      this.fetchDetails(item).then((data) => {
        let result = data.data;
        if (result?.ABN && Array.isArray(result?.ABN) && result?.ABN.length > 0) {
              let sorted = result.ABN.sort((a, b) => {
                return new Date(b.replacedFrom) - new Date(a.replacedFrom);
            });
            result.ABN = sorted[0]
        }
        this.current = data.data
        this.results = {}
        this.$refs.provider.syncValue(this.current)
        this.updated()
      })
    },
    update() {
        if (this.updatesForm) {
        this.$emit(
          "updated",
          {
            type: "abn",
            value: this.current,
          },
          {
            id: this?.response?.id ?? "",
            question_id: this.id,
            type: this.type,
            name: this.name,
            files: this.files,
          },
        )
      } else {
        this.$emit("changed", {
          value: {
            type: "abn",
            value: this.current,
          },
          // id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.label,
          files: this.files,
        })
      }
    },
  },
}
</script>
