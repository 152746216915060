<template>
  <div class="form-input read-only-input" :class="{ 'rounded bg-red-100 p-2.5': errors.length }">
    <div v-if="showLabel">
      <div class="mb-2">
        <span v-text="label" :class="{ 'text-required': errors.length }"></span>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <div :class="{ 'field-border': !errors.length }">
        <attachment-input-file-list :files="processedValue" :print="print" :read-only="true" />
      </div>
      <field-error :errors="errors" :id="id"></field-error>
    </div>
  </div>
</template>
<script>
import inputProps from "./../helpers/inputProps"
import FieldError from "@/components/form/FieldError"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import { isArray, isEmpty } from "lodash"
import AttachmentInputFileList from "@/components/form/AttachmentInputFileList"
export default {
  components: { AttachmentInputFileList, FieldError },
  props: {
    ...inputProps,
  },
  computed: {
    processedValue() {
      if (isArray(this.value)) {
        return this.value
      }

      const parsed = jsonParse(this.value, this.value)

      if (!isEmpty(parsed)) {
        return parsed?.value || []
      }

      return []
    },
  },
}
</script>
