<template>
  <card>
    <div v-html="safeDescription" class="text-content mb-4"></div>
    <file-list v-if="help.files" :files="help.files"></file-list>
  </card>
</template>
<script>
import DynamicTitleMixin from "@/mixins/dynamic-title"
import Card from "@/components/Card"
import FileList from "@/components/FileList"
import render from "@/plugins/render"

export default {
  mixins: [DynamicTitleMixin],
  components: {
    Card,
    FileList,
  },
  data: () => ({
    open: false,
    loading: false,
  }),
  computed: {
    help() {
      return this.$store.getters["knowledge/find"](this.$route.params.path) ?? false
    },
    title() {
      return this.help.title
    },
    safeDescription() {
      if (!this.help?.description) {
        return ""
      }

      // this render function uses dompurify so we don't need to run it through the v-dompurify-html attribute
      let text = render.editor(this.help.description)

      Array.from(text.querySelectorAll("h2"), (element, ind) => {
        element.id = `heading_${ind}`
      })

      return text ? text.innerHTML : ""
    },
    headings() {
      if (!this.description) {
        return []
      }

      return [...this.description.querySelectorAll("h2")].map((el) => el.innerText)
    },
    activeHeading() {
      return parseInt((this.$route?.hash || "0").replace("#heading_", ""))
    },
  },
  created() {
    this.loading = true
    this.$store.dispatch("knowledge/one", this.$route.params.path).then(() => {
      this.loading = false
    })
  },
}
</script>
