<template>
  <card :loader="true" :loading="loading">
    <div class="relative flex">
      <div class="py-4 flex-1 flex-grow" v-if="content">
        <h3>{{ content.title }}</h3>
        <p class="mb-8" v-dompurify-html="content.description"></p>
        <a class="button inline-flex button--filled" target="_blank" href="https://www.tenders.gov.au/App/Show/626677ec-e632-e8d8-9f80-cd74cbbb3677"
          >Go to Austender</a
        >
      </div>
      <div class="relative lg:-mr-8 lg:-my-8 w-44 h-full flex-none hidden md:block">
        <img v-if="loading !== true" src="@/assets/images/binoculars.svg" alt="Whiteboard" class="w-full" />
      </div>
    </div>
  </card>
</template>
<script>
import Card from "@/components/Card"
export default {
  components: {
    Card,
  },
  computed: {
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("future_tenders") ?? false
    },
    loading() {
      return !this.$store.getters["blockContent/findByMachineName"]("future_tenders")
    },
  },
}
</script>
