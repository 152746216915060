import { fieldComponents } from "@/components/form/Fields"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import { validate } from "vee-validate"
import { mapActions } from "vuex"

export default {
  methods: {
    ...mapActions({
      completeSectionId: "response/completeSection",
      incompleteSectionId: "response/incompleteSection",
    }),
    async checkCompletedSections() {
      let result = ""
      let sectionErrors = []
      for await (const section of this.builtSections) {
        result = ""
        sectionErrors = []
        for await (const field of section.fields) {
          //check if any field is invailid

          if (field.type in fieldComponents) {
            result = await validate(field?.response?.value || "", field.validation.join("|"))
            sectionErrors.push(result.valid)
          } else if (field.type === "table") {
            if (field.response) {
              const tableJson = jsonParse(field.response.value)
              if (tableJson?.value) {
                for (const row of tableJson.value) {
                  for (const column of field.fields) {
                    const cell = row.find((cell) => {
                      return cell?.question_id?.includes(column.id)
                    })
                    let result = await validate(cell?.value || "", column.validation.join("|"))
                    sectionErrors.push(result.valid)
                  }
                }
              } else if (field.validation.includes("required") || field.fields.filter((column) => column.validation.length > 0).length > 0) {
                sectionErrors.push(false)
              }
            } else if (field.validation.includes("required") || field.fields.filter((column) => column.validation.length > 0).length > 0) {
              sectionErrors.push(false)
            }
          }
        }

        if (sectionErrors.includes(false)) {
          this.incompleteSectionId(section.id)
        } else {
          this.completeSectionId(section.id)
        }
      }
    },
  },
}
