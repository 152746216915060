<template>
  <div v-if="hasError" class="fixed inset-0 bg-white z-50 p-4">
    <card theme="light" v-if="type === '404'" class="h-full overflow-y-auto md:overflow-visible">
      <div class="max-w-xl m-auto md:py-12 text-center">
        <h2>Page not found</h2>
        <img src="@/assets/images/not-found.svg" class="m-auto max-w-md	mb-6 w-full" alt="" />
        <div class="mb-6 flex justify-center gap-4">
          <a href="" class="button button--filled">Reload</a>
          <Logout class="button button--filled" />
        </div>
        <h3>The address you've entered doesn't exist</h3>
        <div>
          Please ensure the address you have entered is correct. If you can’t find what you are looking for please return to the
          <router-link :to="{ name: 'home' }">home page</router-link> and try searching for keys words. If you require assistance, please
          <router-link :to="{ name: 'contact-us' }">contact us</router-link> for help.
        </div>
      </div>
    </card>
    <card theme="light" class="h-full overflow-y-auto md:overflow-visible" v-else>
      <div class="max-w-xl m-auto md:py-12 text-center">
        <h2 class="mb-0">System error</h2>
        <span class="mb-8 block text-sm text-gray-500">{{ date }}</span>
        <img src="@/assets/images/system-error.svg" class="m-auto max-w-md mb-6 w-full" alt="" />
        <div class="mb-6 flex justify-center gap-4">
          <a href="" class="button button--filled">Reload</a>
          <Logout class="button button--filled" />
        </div>
        <h3 class="text-base md:text-xl">We are having technical issues</h3>
        <div>Please try again later or contact us if you need help.</div>
        <div class="pt-8">
          <strong class="block">By phone</strong>
          <p><a href="tel:1300 733 514" class="tel">1300 733 514</a></p>
          <strong class="block pt-4">By email</strong>
          <p><a href="mailto:espurchasing@dewr.gov.au">espurchasing@dewr.gov.au</a></p>
          <div>
            To assist us in helping you, please attach a screenshot of this page, including the timestamp.
          </div>
        </div>
      </div>
    </card>
  </div>
</template>
<script>
import Card from "@/components/Card"
import Logout from "@/components/layout/Logout"

export default {
  components: {
    Card,
    Logout,
  },
  data() {
    return {
      type: false,
    }
  },
  computed: {
    hasError() {
      return !!this.type
    },
    date() {
      return new Date().toString()
    },
  },
  mounted() {
    this.$events.$on("error", (type = "error") => {
      this.type = type
    })
  },
  destroyed() {
    this.$events.$off("error")
  },
}
</script>
