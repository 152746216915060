import endpointSilent from "./endpoint-silent"

/**
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
const validate = (params = {}) => {
  return endpointSilent.post(`/auth`, params)
}

export default {
  validate,
}
