import HelpHome from "@/routes/help/children/HelpHome"
import HelpPage from "@/routes/help/children/HelpPage"
import Faq from "@/routes/help/children/Faq"
import WithdrawTender from "@/routes/help/children/WithdrawTender"
export default [
  {
    path: "",
    name: "help",
    component: HelpHome,
    meta: { label: "Introduction" },
  },
  {
    path: "faq",
    name: "faq",
    component: Faq,
    meta: { label: "Portal FAQ" },
  },
  {
    path: "withdraw-tender",
    name: "withdraw-tender",
    component: WithdrawTender,
    meta: { label: "Withdraw tender" },
  },
  {
    path: ":path*",
    name: "help-page",
    component: HelpPage,
    meta: { label: false },
  },
]
