<template>
  <div class="form-input text-input">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="label">
      <div class="flex justify-between mb-2">
        <label v-text="label"></label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <input
        :type="type"
        :name="name"
        :id="`input_${name}`"
        v-model="current"
        @input="input"
        :placeholder="placeholder"
        :class="{ error: errors.length }"
      />
      <field-error v-if="errors.length" :errors="errors"></field-error>
    </validation-provider>
  </div>
</template>
<script>
import FieldError from "@/components/form/FieldError"

export default {
  name: "TextInput",
  components: { FieldError },
  props: {
    type: {
      type: String,
      default: "text",
    },
    name: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    validation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: "",
    }
  },
  created() {
    this.current = this.value
  },
  methods: {
    input() {
      this.$emit("input", this.current)
    },
  },
}
</script>
