<template>
  <div>
    <h2>Faq</h2>
    <div class="mt-8" v-dompurify-html="description"></div>
  </div>
</template>
<script>
import render from "@/plugins/render"
export default {
  props: {
    tender: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    description() {
      if (!this.tender?.faqs) {
        return document.createElement("div")
      }

      return render.editor(this.tender.faqs)
    },
  },
}
</script>
