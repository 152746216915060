<template>
  <div>
    <card theme="light">
      <div class="max-w-xl m-auto py-12 text-center">
        <h2>Page not found</h2>
        <img src="@/assets/images/not-found.svg" class="m-auto max-w-md	mb-8 w-full" alt="" />
        <h3>The address you've entered doesn't exist</h3>
        <div>
          Please ensure the address you have entered is correct. If you can’t find what you are looking for please return to the
          <router-link :to="{ name: 'home' }">home page</router-link> and try searching for keys words. If you require assistance, please
          <router-link :to="{ name: 'contact-us' }">contact us</router-link> for help.
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from "@/components/Card"
export default {
  components: {
    Card,
  },
}
</script>
