<template>
  <response-list :per-page="10" :show-pagination="true" class="block"></response-list>
</template>
<script>
import ResponseList from "@/components/responses/ResponseList.vue"
export default {
  components: {
    ResponseList,
  },
}
</script>
