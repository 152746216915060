<template>
  <li class="p-2 rounded-md">
    <div class="flex">
      <router-link class="py-1 title w-full flex-grow" :to="{ name: 'tender', params: { id: item.tender.id } }">{{ item.tender.title }}</router-link>
      <response-actions :item="item" drop-position="right" class="flex justify-center"></response-actions>
    </div>
    <router-link v-if="item.tender.addenda_count" class="w-full flex-grow no-underline" :to="{ name: 'tender', params: { id: item.tender.id } }"
      >&nbsp;<span class="addenda-pill">Addenda</span></router-link
    >
    <div class="inline-block pb-2 w-full capitalize" v-text="completionStatus"></div>
    <div class="mb-2 text-xs">
      <div class="mb-1">
        <strong>Close date: </strong>
        <span v-text="$moment(item.tender.close_at).format('Do MMMM YYYY')"></span>
      </div>
      <time-remaining :item="item.tender"></time-remaining>
    </div>
    <div class="w-full">
      <router-link
        v-if="item.status === 'draft' && item.tender.status !== 'closed'"
        class="text-center title w-full rounded-full"
        :to="{ name: 'response', params: { id: item.id } }"
        ><div class="px-4 py-1 text-white rounded-full bg-primary-light w-full">Continue</div></router-link
      >
    </div>
  </li>
</template>
<script>
import ResponseListItem from "./mixins/response-list-item"
import TimeRemaining from "@/components/TimeRemaining"
import ResponseActions from "@/components/responses/ResponseActions"
export default {
  components: { ResponseActions, TimeRemaining },
  mixins: [ResponseListItem],
}
</script>
