<template>
  <div>
    <div v-if="formSection">
      <h2 v-text="formSection.title" class="mb-4"></h2>
      <div v-html="safeDescription" class="mb-8 text-content"></div>
      <fields :form-section="formSection" :section="section" v-model="items" :readOnly="readOnly" :immediate="immediate"></fields>
    </div>
  </div>
</template>
<script>
import Fields from "@/components/form/Fields"
import render from "@/plugins/render"
import { mapActions } from "vuex"
import NavCatch from "./../mixins/navCatch"
export default {
  components: {
    Fields,
  },
  mixins: [NavCatch],
  props: {
    response: {
      type: Object,
      default: () => ({}),
    },
    formSection: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
    builtSections: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: {},
    }
  },
  created() {
    this.readSection({ section_id: this.formSection.id, section_name: this.formSection.name })
  },
  computed: {
    safeDescription() {
      if (!this.formSection?.description) {
        return ""
      }

      // this render function uses dompurify so we don't need
      // to run it through the v-dompurify-html attribute
      let text = render.editor(this.formSection.description)

      return text ? text.innerHTML : ""
    },
  },
  methods: {
    ...mapActions({
      readSection: "response/readSection",
    }),
  },
}
</script>
