<template>
  <button @click="toggle" class="order-1 hamburger lg:hidden lg:w-auto lg:flex-grow-0" type="button" :class="{ open }"></button>
</template>
<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      this.$emit("toggle", !this.open)
    },
  },
}
</script>
<style lang="scss">
.hamburger {
  @apply relative w-8 h-8 m-4 ml-0;
  z-index: 30;
  background: url("~@/assets/images/menu--open.svg") no-repeat center;
  &:focus {
    outline: none;
  }
}

.hamburger.open {
  background: url("~@/assets/images/menu--close.svg") no-repeat center;
}
</style>
