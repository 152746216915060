<template>
  <div v-if="exists" class="help" v-dompurify-html="help"></div>
</template>
<script>
import { decodeHtml } from "@/plugins/util"
export default {
  props: {
    help: {
      type: String,
      default: "",
    },
  },
  computed: {
    exists() {
      let exists = decodeHtml(this.help.replace(/<[^>]*>?/gm, ""))
      exists = exists.trim()
      return !!exists
    },
  },
}
</script>
