import api from "@/store/api"
import { useDummy } from "@/plugins/util"

let items = []

if (useDummy()) {
  items = require("../../mocks/data/knowledge").default
}

// state
const state = () => ({
  items,
})

// getters
const getters = {
  faqKnowledgeItems: (state, getters) => {
    return getters.whereType("faq")
  },
  helpKnowledgeItems: (state, getters) => {
    return getters.whereType("help")
  },
  allKnowledgeItems: (state) => {
    return state.items
  },
  whereType: (state, getters) => (type, limit = 0) => {
    if (limit > 0) {
      return getters.allKnowledgeItems.filter((t) => t.type === type).slice(0, limit)
    }

    return getters.allKnowledgeItems.filter((t) => t.type === type)
  },

  pages: (state, getters) => {
    return getters.whereType("page") ?? []
  },

  findByMachineName: (state, getters) => (search) => {
    return getters.allKnowledgeItems.find((knowledgeItem) => knowledgeItem.machine_name === search)
  },

  find: (state, getters) => (id) => {
    return getters.allKnowledgeItems.find((knowledgeItem) => knowledgeItem.id === id)
  },

  existsByMachineName: (state, getters) => (search) => {
    return getters.findByMachineName(search) ?? false
  },
}

// actions
const actions = {
  add({ commit }, knowledgeItem) {
    commit("addKnowledgeItem", { knowledgeItem })
  },

  load({ commit }, { type, parentId = "" }) {
    return api.knowledge.collection({ type, parentId }).then((response) => {
      commit("setKnowledgeItems", {
        data: response.data,
      })
    })
  },

  one({ commit }, id, params = {}) {
    return api.knowledge.single(id, params).then((response) => {
      commit("updateKnowledgeItem", response)
    })
  },

  byMachineName({ commit, getters }, machineName, params = {}) {
    if (getters.existsByMachineName(machineName)) {
      return
    }

    return api.knowledge.byMachineName(machineName, params).then((response) => {
      commit("updateKnowledgeItem", response)
    })
  },
}

// mutations
const mutations = {
  addKnowledgeItem(state, { knowledgeItem }) {
    state.items.push(knowledgeItem)
  },
  updateKnowledgeItem(state, { data }) {
    if (!data?.id) {
      return
    }
    let index = state.items.findIndex((t) => t.id === data.id)
    if (index > -1) {
      state.items = [...state.items.slice(0, index), data, ...state.items.slice(index + 1)]
    } else {
      state.items.push(data)
    }
  },
  setKnowledgeItems(state, { data }) {
    if (!data?.data) {
      return
    }

    state.items = data.data.reduce(
      (acc, el) => {
        if (state.items.findIndex((current) => current.id === el.id) === -1) {
          acc.push(el)
        }
        return acc
      },
      [...state.items],
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
