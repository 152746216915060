import { jsonParse } from "@/components/form/helpers/fieldParser"

export default {
  methods: {
    load() {
      const parsed = jsonParse(this.value, {
        value: [],
      })

      this.current = parsed?.value || []
    },
    updated() {
      this.input()
      this.update()
    },
    buildValue() {
      return {
        type: this.type,
        value: this.current.sort((a, b) => {
          return this.items.indexOf(a) - this.items.indexOf(b)
        }),
      }
    },
    update() {
      if (this.updatesForm) {
        this.$emit("updated", this.buildValue(), {
          id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.name,
          files: this.files,
        })
      } else {
        this.$emit("changed", {
          value: this.buildValue(),
          question_id: this.id,
          type: this.type,
          name: this.label,
          files: this.files,
        })
      }
    },
    input() {
      this.$emit("input", this.current)
    },
  },
}
