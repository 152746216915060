export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    editable() {
      return this.item.status === "draft" && this.item.tender.status !== "closed"
    },
    completionStatus() {
      if (this.item.tender.status === "closed") {
        return this.item.status === "draft" ? "Incomplete" : "Submitted"
      }

      return this.item.status === "draft" ? "Draft" : "Submitted"
    },
  },
}
