<template>
  <article v-if="addendum">
    <h2 class="font-normal">Addendum {{ addendumNumber }}</h2>
    <div v-if="false" class="inline-block px-2 my-5 text-xs bg-green-100 rounded-full">
      <div class="flex items-center text-green-800">
        Read
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" class="inline-block w-3 ml-1">
          <circle cx="6.5" cy="8.5" r="5.5" stroke="#036E37" stroke-width="2" />
          <path
            class="text-green-100 stroke-current"
            d="M13.2594 2.815c.45-.69553.251-1.62419-.4445-2.074236-.6955-.450044-1.6242-.251045-2.0743.444476l-4.49196 6.9422-1.18798-1.18798c-.58579-.58579-1.53553-.58579-2.12132 0-.58579.58579-.58579 1.53553 0 2.12132l2.5 2.50002c.32031.3203.76822.4787 1.21869.431.45046-.0478.85524-.2965 1.10133-.6768l5.50004-8.5Z"
            fill="#036E37"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div v-if="false" class="inline-block px-2 my-5 text-xs text-white bg-red-600 rounded-full">Unread</div>
    <h3 class="mb-3">{{ addendum.title }}</h3>
    <time :datetime="addendum.created_at" class="block font-bold mb-4">Date of Issue: {{ getHumanDate(addendum.updated_at) }}</time>
    <div v-dompurify-html="addendum.message"></div>
    <file-list v-if="files" :files="files" :show-title="false"></file-list>
    <!-- <ul class="p-0 list-none">
      <a
        v-for="(file, ind) in currentAddendum.attachedFiles"
        :key="ind"
        :href="file.url"
        class="flex items-center justify-between p-4 mb-3 no-underline border rounded border-primary-light hover:bg-gray-200"
      >
        <li>{{ file.filename }}</li>
        <svg width="29" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14.379 14.794a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414l-5.657 5.657-5.657-5.657A1 1 0 1 0 8.015 8.43l6.364 6.364ZM14.086 0v14.087h2V0h-2Z"
            fill="#287DB2"
          />
          <path d="M1 14.087v8.217h27v-8.217" stroke="#287DB2" stroke-width="2" />
        </svg>
      </a>
    </ul> -->
  </article>
</template>
<script>
import FileList from "@/components/FileList.vue"

export default {
  components: { FileList },
  props: {
    tender: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    addendum() {
      return this.tender.addenda.find((addenda) => addenda.id === this.$route.params.addendumId)
    },
    addendumNumber() {
      return this.tender.addenda.findIndex((addenda) => addenda.id === this.$route.params.addendumId) + 1
    },
    files() {
      return this.addendum.files
    },
  },
  methods: {
    getHumanDate(date) {
      return this.$moment(date).format("DD MMMM YYYY")
    },
    setReadStatus() {
      //addenda.read = true
    },
  },
}
</script>
