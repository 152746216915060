import { config } from "@/config"

export default function response({ next }) {
  if (!config.features.response) {
    return next({
      name: "home",
    })
  }

  return next()
}
