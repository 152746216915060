<template>
  <ul class="p-0 list-none" v-if="files.length">
    <li class="flex items-center py-1 text-sm border-t border-gray-500" :class="{ 'py-4': !print }" v-for="(file, ind) in files" :key="ind">
      <div class="w-8 mr-2">
        <img v-if="file.status === 'success'" src="~@/assets/images/paper-clip.svg" alt="paper clip file icon" />
        <img
          v-if="['added', 'queued'].includes(file.status)"
          src="~@/assets/images/spinner.svg"
          alt="loading file icon"
          class="animate-spin-slow h-[22px] w-[22px]"
        />
        <img v-if="['error', 'canceled'].includes(file.status)" src="~@/assets/images/error.svg" alt="delete file icon" />
      </div>
      <div class="flex flex-row flex-grow lg:flex-grow-0" :class="{ 'w-1/2': !readOnly, 'w-full': readOnly }">
        <div
          :class="{
            'text-red-600': file.status === 'error',
            'cursor-pointer': readOnly,
          }"
        >
          <span @click="downloadFile(file)" class="underline" v-text="file.name"></span>
          <br />
          <span v-if="file.status === 'success'">
            <span class="uppercase" v-text="ext(file.name)"></span> - <span v-text="formatBytes(file)"></span> -
            <span v-text="file.uploaded_at"></span>
          </span>
          <span v-if="file.status === 'error' && file.response.message"> {{ file.response.message }}</span>
          <span v-else-if="file.status === 'error' && file.response"> {{ file.response }}</span>
        </div>
      </div>
      <img
        v-if="downloading && downloadId === file.id"
        src="~@/assets/images/spinner.svg"
        alt="loading file icon"
        class="animate-spin-slow h-[22px] w-[22px]"
      />
      <div class="flex-shrink px-2" v-if="!readOnly">
        <button type="button" @click.prevent="remove(file)" title="Remove file">
          <img src="~@/assets/images/bin.svg" alt="delete file icon" />
        </button>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapActions } from "vuex"
import { fileExt, humanFileSize } from "@/plugins/util"

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    files: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Array,
      default: () => [],
    },
    print: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      downloading: false,
      downloadId: "",
    }
  },
  methods: {
    ...mapActions({
      download: "responses/download",
    }),
    remove(file) {
      this.$emit("remove", file)
    },
    formatBytes(file) {
      if (file?.upload?.bytesSent) {
        return humanFileSize(file.upload.bytesSent)
      }

      if (file?.size) {
        return humanFileSize(file.size)
      }

      return ""
    },
    async downloadFile(file) {
      this.downloading = true
      await this.download({ responseId: this.$route.params.id, fileId: file.id, filename: file.filename })
      this.downloading = false
    },
    ext(name) {
      return fileExt(name)
    },
  },
}
</script>
