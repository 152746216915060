<template>
  <div :class="classList" class="alert">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    classList() {
      let classes = [this.classes]

      if (this.type) {
        classes.push(`alert--${this.type}`)
      }

      return classes
    },
  },
}
</script>
<style lang="scss">
.alert {
  @apply rounded border-primary-light border-solid border-2 p-4 pl-16 relative bg-white;
  &:before {
    @apply w-12 absolute bg-no-repeat bg-center bg-primary-light;
    /* weird edge windows issue */
    top: -1px;
    left: -1px;
    bottom: -1px;
    right: -1px;
    content: "";
    background-image: url("~@/assets/images/info.svg");
  }
  &.alert--secondary,
  &.alert--addendum {
    @apply border-primary-alert;
    &:before {
      @apply bg-primary-alert;
    }
  }

  p {
    @apply mb-0;
    + p {
      @apply mt-2;
    }
  }
}
</style>
