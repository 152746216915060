<template>
  <div v-if="files.length > 0">
    <h3 v-if="showTitle">Downloads</h3>
    <button-link v-for="link in files" :key="link.url" :to="link.url" :outline="true" :download="true" :external="true" classes="w-full">
      {{ link.name ? link.name : link.filename }}
    </button-link>
  </div>
</template>
<script>
import ButtonLink from "./ButtonLink"
export default {
  components: {
    ButtonLink,
  },
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
