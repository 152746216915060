<template>
  <li class="relative w-full" v-if="conditionsMet">
    <div
      v-if="sectionInd !== 0"
      class="absolute w-6 h-full min-h-80 left-9 transform -translate-x-1/2 bottom-0"
      :class="[inCurrentTrail ? 'bg-primary-light' : 'bg-gray-300', sectionSelected ? 'bg-primary-light' : '']"
    ></div>
    <router-link
      v-if="isEnabled"
      :to="{ name: inReview, params: { id: $route.params.id, section: section.id } }"
      :class="{ 'bg-primary-highlight': sectionInd === currentSectionIndex }"
      class="flex items-center pl-4 mb-10 no-underline rounded-lg"
    >
      <div class="relative z-10 flex items-center">
        <div
          v-if="isEnabled"
          class="relative flex items-center justify-center w-10 h-10 border-2 rounded-full"
          :class="{
            'bg-dark-blue border-primary-light border-4': complete,
            'bg-primary-light border-4 border-primary-light': (isEnabled && inCurrentTrail) || sectionSelected || read,
            'bg-white border-2 border-gray-500': !complete && !((isEnabled && inCurrentTrail) || sectionSelected || read),
          }"
        >
          <svg
            v-if="sectionInd !== 0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 52.828 27.779 27.876"
            class="absolute -top-1/2 -z-1 fill-current"
            :class="[inCurrentTrail ? 'text-primary-light' : sectionSelected ? 'text-primary-light' : 'text-gray-300']"
          >
            <path
              d="M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 27.779 27.876"
            class="absolute -bottom-1/2 -z-1 fill-current"
            :class="[
              inCurrentTrail && sectionInd !== currentSectionIndex ? 'text-primary-light' : sectionSelected ? 'text-primary-light' : 'text-gray-300',
            ]"
          >
            <path
              d="M1.008 8.942C-1.587 5.149 1.129 0 5.725 0h16.329c4.596 0 7.312 5.149 4.717 8.942-.254.371-.55.71-.882 1.011l-3.418 3.092c-4.191 3.791-6.582 9.179-6.582 14.831h-4c0-5.652-2.391-11.04-6.581-14.831L1.89 9.953c-.332-.301-.629-.64-.882-1.011Z"
            />
          </svg>

          <img v-if="complete" src="@/assets/images/tick.svg" alt="Tick" class="w-1/2" />
          <div v-else-if="!complete" class="text-white"></div>
        </div>
        <div v-else class="flex items-center justify-center w-10 h-10 bg-white border-2 border-gray-300 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.779 27.876" class="absolute -bottom-1/2 -z-1 fill-current text-gray-300">
            <path
              d="M1.008 8.942C-1.587 5.149 1.129 0 5.725 0h16.329c4.596 0 7.312 5.149 4.717 8.942-.254.371-.55.71-.882 1.011l-3.418 3.092c-4.191 3.791-6.582 9.179-6.582 14.831h-4c0-5.652-2.391-11.04-6.581-14.831L1.89 9.953c-.332-.301-.629-.64-.882-1.011Z"
            />
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 52.828 27.779 27.876" class="absolute -top-1/2 -z-1 fill-current text-gray-300">
            <path
              d="M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"
            />
          </svg>
        </div>
      </div>

      <div class="block w-full">
        <span
          v-if="section"
          v-text="section.title"
          class="relative block w-full px-2 py-5 ml-2 text-black no-underline rounded-r-md before:absolute before:w-14 before:h-full before:-left-14 before:top-0 before:rounded-l-md before:bg-opacity-20 bg-opacity-20 before:-z-1"
          :class="[
            sectionSelected
              ? 'bg-transparent'
              : isEnabled && currentSectionIndex === section.id
              ? 'bg-primary-extralight before:bg-primary-extralight'
              : '',
          ]"
        ></span>
      </div>
    </router-link>
    <div class="flex items-center pl-4 mb-10" v-else>
      <div>
        <div class="relative z-50 flex items-center justify-center w-10 h-10 bg-white border-2 border-gray-300 rounded-full"></div>
      </div>
      <span class="relative block w-full px-2 py-5 ml-2 text-gray-400 no-underline rounded-md" v-if="section">{{ section.title }}</span>
    </div>
    <div
      class="absolute w-6 h-full min-h-90 left-9 transform -translate-x-1/2 top-1/2"
      :class="[inCurrentTrail && sectionInd !== currentSectionIndex ? 'bg-primary-light' : 'bg-gray-300', sectionSelected ? 'bg-primary-light' : '']"
    ></div>
  </li>
</template>

<script>
import { processConditions } from "@/components/form/helpers/conditionProcessor"

export default {
  props: {
    currentSection: {
      type: Object,
      default: () => {},
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
    read: {
      type: Boolean,
      default: false,
    },
    sectionInd: {
      type: Number,
      default: 0,
    },
    section: {
      type: Object,
      default: () => {},
    },
    currentSectionIndex: {
      type: Number,
      default: 0,
    },
    sectionSelected: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    menuLength: {
      type: Number,
      default: 0,
    },
    complete: {
      type: Boolean,
      default: false,
    },
    conditional_statements: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      conditionsMet: true,
    }
  },
  created() {
    this.testCondition()
  },
  computed: {
    inCurrentTrail() {
      return this.sectionInd <= this.currentSectionIndex
    },
    inReview() {
      return this.$route.name === "section-review" ? "section-review" : "response-section"
    },
  },
  mounted() {
    this.$events.$on("value:updated", this.testCondition)
  },
  destroyed() {
    this.$events.$off("value:updated", this.testCondition)
  },
  watch: {
    conditionsMet() {
      if (this.$store.getters["response/isDirty"]) {
        const response = this.$store.getters["response/get"]
        if (!this.conditionsMet) {
          const filterSections = response.sections.filter((section) => section.id == this.section.id)
          filterSections.forEach(async (seciton) => {
            await seciton.fields.forEach((field) => {
              this.$store.dispatch("response/storeField", {
                section_id: seciton.id,
                field: {
                  id: field.id,
                  name: field.name,
                  question_id: field.question_id,
                  response_id: field.response_id,
                  section_id: field.section_id,
                  value: field.value,
                  status: false,
                },
              })
            })
          })
        }
      }
    },
  },
  methods: {
    testCondition() {
      this.conditionsMet = processConditions(this.section.conditional_statements)
    },
  },
}
</script>
