const fileList = require("./files")

export default [
  {
    id: "3e1d8518-e6a6-44c4-9273-2f2c10e31f98",
    title: "Lorem ipsum",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "General",
    type: "faq",
  },
  {
    id: "28fe2f8b-ce33-4ad4-8929-b610a24eab00",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur dog adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "Tender portal",
    type: "faq",
  },
  {
    id: "ba620898-00c5-4fdd-8eb9-18a10cc022dc",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "RTF",
    type: "help",
    files: fileList,
  },
  {
    id: "7c4fa22e-4d84-4169-90dc-72649db0dfbc",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "General",
    type: "faq",
  },
  {
    id: "e3bd7fff-1807-4eb8-8243-74db017b8a92",
    title: "Sed eros magna, ullamcorper id purus eget, vehicula ullamcorper massa",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "Tender portal",
    type: "faq",
  },
  {
    id: "d9b1b4bd-cbda-4878-b3f4-6572a8e9610d",
    title: " In gravida ullamcorper tellus, in porttitor nulla egestas eget",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "RTF",
    type: "help",
    files: fileList,
  },
  {
    id: "a9b7ad24-1731-4647-97a7-d281f995138e",
    title: "Lorem ipsum",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "General",
    type: "faq",
  },
  {
    id: "f39aa2f6-9bda-4a1c-9977-e728d1953c9e",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "Responding",
    type: "faq",
  },
  {
    id: "1dfcfbce-2a7d-4a31-b5e3-27b633e29b43",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique senectus",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "RTF",
    type: "help",
    files: fileList,
  },
  {
    id: "d8ac71c5-0449-411d-af69-66126be63d69",
    title: "Aliquam erat volutpat. Pellentesque habitant morbi tristique",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "Responding",
    type: "faq",
  },
  {
    id: "ea2dd93d-0694-4c39-af6d-e7e278faaafd",
    title: "Sed eros magna, ullamcorper id purus eget, vehicula ullamcorper massa",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "Responding",
    files: fileList,
    type: "faq",
  },
  {
    id: "ceae6cec-7f32-42e5-88a7-61f322544f93",
    title: " In gravida ullamcorper tellus, in porttitor nulla egestas eget",
    summary: "Nullam et tortor consequat, dignissim dui sed, scelerisque nisi.",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus ligula eget mauris imperdiet, at ultricies quam faucibus. Aliquam at nibh vitae nisi interdum malesuada. Vivamus vel neque quam.",
    category: "RTF",
    files: fileList,
    type: "help",
  },
  {
    id: "30d196f5-54f8-4e1d-96a2-7bd905c5159f",
    machine_name: "responding-to-an-approach-to-market",
    title: "Responding to an approach to market",
    summary: "Responding to an approach to market summary",
    description: require("./html/help-page"),
    type: "help",
    category: false,
    files: fileList,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a2-7bd905c5859f",
    machine_name: "welcome_message",
    title: "Employment Services Tenders",
    summary: "Find out more about the portal",
    description:
      "The system will drive quality outcomes for job seekers and employers. By simplifying the approach for providers to enter and exit the market while reducing the cost and disruption of procurement",
    type: "block",
    category: false,
  },
 /*  {
    id: "c0d196f5-54f8-4e1d-96a1-7bd905c5858g",
    machine_name: "maintenance",
    title: "Maintenance test",
    summary: "test",
    description: "Maintenance will happen soon",
    type: "block",
    category: false,
  }, */
  {
    id: "c0d196f5-54f8-4e1d-96a1-7bd905c5858g",
    machine_name: "maintenance",
    title: "Maintenance test",
    summary: "test",
    description: "Maintenance will happen soon",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a2-7bd905c5859f",
    machine_name: "help_guides",
    title: "View our help guides for all your information for responding to our tenders",
    summary: "Discover and explore the help guides",
    description:
      "<div class='pb-4'> The help guides is one stop shop for all your document: <div class='block flex pt-4 pb-4'><div class='flex-none mr-8'> - Tutorials<br> - DIY guides </div><div class='flex-auto'> - Video tutorials<br> - Frequently asked questions </div></div> To discover answers and assistance in responding to tenders the Department of Education, Skill and Employment publish. </div>",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a2-7bd905c5858f",
    machine_name: "help_guides_empty",
    title: "View our help guides for all your information for responding to our tenders",
    summary: "Discover and explore the help guides",
    description:
      "The help guides is one stop shop for all your document tutorials, DIY guides, and answers to frequently asked questions to discover answers and assistance in responding to tenders the Department of Education, Skill and Employment publish.",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a1-7bd905c5858f",
    machine_name: "help_guides_intro",
    title: "",
    summary: "",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Assumenda, at atque aut consequatur consequuntur, enim eum expedita hic illum iste magnam maiores officia perspiciatis ratione, reiciendis reprehenderit sed sint voluptate.",
    type: "block",
    category: false,
  },
  {
    id: "30d196f5-54f8-4e1d-96a2-7bd905c5166f",
    machine_name: "about",
    title: "About",
    summary: "",
    description: require("./html/about-us"),
    type: "page",
    category: false,
    files: fileList,
  },
  {
    id: "c0d296f5-54f8-4e1d-96a1-7bd905c5858f",
    machine_name: "responding_atm_help",
    title: "Responding to an approach to market",
    summary: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus.",
    type: "block",
    category: false,
  },
  {
    id: "c0d197f5-54f8-4e1d-96a1-7bd905c5858f",
    machine_name: "procurement_help",
    title: "Using the Procurement and Licence management system",
    summary: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus.",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f7-4e1d-96a1-7bd905c5858f",
    machine_name: "faq_help",
    title: "FAQs",
    summary: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut luctus.",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a2-7bd905c5859f",
    machine_name: "future_tenders",
    title: "Looking for future tenders?",
    summary: "",
    description: "Find information about upcoming employment services tenders on Austender",
    type: "block",
    category: false,
  },
  {
    id: "c0d196f5-54f8-4e1d-96a1-7bd905c5858f",
    machine_name: "faq_introduction",
    title: "FAQ introductions",
    summary: "",
    description: "The FAQ document will help you find answers to the questions you may have.",
    type: "block",
    category: false,
  },
 
]
