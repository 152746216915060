<template>
  <tr v-if="fields">
    <td v-for="(field, cellId) in fields" :key="field.id" :class="{ 'p-0': inlineFields }" :data-label="field.label">
      <component
        :is="field.component"
        v-bind="field"
        :show-label="false"
        :updates-form="false"
        :id="`${field.id}[${rowIndex}][${cellId}]`"
        :value="current[cellId] ? current[cellId].value : ''"
        :name="`${field.name}[${rowIndex}][${cellId}]`"
        :class="{ 'inline-fields': inlineFields }"
        :read-only="readOnly"
        :immediate="readOnly"
        :print="print"
        :name-prefix="`${prefix} ${rowIndex + 1} - `"
        @changed="changed(cellId, $event)"
      >
      </component>
    </td>
    <td class="sticky right-0 bg-white remove-row" v-if="!readOnly && rowIndex > 0">
      <button @click.prevent="removeRow()">
        <svg width="20" height="21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.9 18.327 2.237 5.737h15.526l-1.664 12.59A2.5 2.5 0 0 1 13.62 20.5H6.379A2.5 2.5 0 0 1 3.9 18.327ZM17.791 5.515ZM18.248 3.216a.441.441 0 0 1-.331.732H2.083a.441.441 0 0 1-.33-.732l.352-.401a.682.682 0 0 1 .512-.231h14.766c.196 0 .382.084.512.23l.353.402Z"
            stroke="#656B70"
          />
          <path
            d="M9.999 7.6v11.035M14.167 7.864l-.833 10.51M5.833 7.864l.833 10.51"
            stroke="#656B70"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M7.499 2.576V1h5.833v1.576" stroke="#656B70" stroke-linejoin="round" />
        </svg>
      </button>
    </td>
  </tr>
</template>
<script>
import { fieldComponentMixin } from "./helpers/fieldTypes"
import rowMixin from "./mixins/row"
export default {
  name: "HorizontalTableRow",
  mixins: [fieldComponentMixin, rowMixin],
  computed: {
    inlineFields() {
      if (this.fields.length <= 1) return

      return this.fields.every((field) => field.type === "multi_linetext")
    },
  },
}
</script>
