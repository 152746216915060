import { intersection, isArray } from "lodash"

const equal = {
  operator: "==",
  match(rule, value) {
    if (isArray(value)) {
      return intersection(value, rule.value.split("|"))?.length
    }

    return value === rule.value.trim()
  },
}

const notEqual = {
  operator: "!=",
  match(rule, value) {
    return !equal.match(rule, value)
  },
}

const lessThan = {
  operator: "<",
  match(rule, value) {
    return parseFloat(value) < parseFloat(rule.value)
  },
}

const greaterThan = {
  operator: ">",
  match(rule, value) {
    return parseFloat(value) > parseFloat(rule.value)
  },
}

/**
 * @type {({match(*, *): boolean, operator: string}|{match(*, *): boolean, operator: string})[]}
 */
export const rules = [equal, notEqual, lessThan, greaterThan]

/**
 * @param statement
 * @param value
 * @returns {null|boolean}
 */
export const matchCondition = (statement, value) => {
  const rule = rules.find((r) => r.operator === statement.operator)

  if (!rule) {
    return null
  }

  return rule.match(statement, value)
}
