import { mapActions } from "vuex"

export default {
  methods: {
    ...mapActions({
      saveSection: "response/saveSection",
      submitSection: "response/submit",
      completeSectionId: "response/completeSection",
      incompleteSectionId: "response/incompleteSection",
    }),
    async submit() {
      this.saving = true

      // resetting the changed var each time we click save
      await this.$refs.form.reset()

      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.incompleteSectionId(this.sectionId)
        this.formErrors = Object.values(this.currentErrors).filter((error) => error.length > 0)
        this.modalOpen = true
        return
      }
      this.completeSectionId(this.sectionId)
      this.saveSection({ section_id: this.formSection.id })
        .then(() => {
          this.sectionComplete = true
          this.$events.$emit("form:saved")
        })
        .finally(() => {
          this.saving = false
        })
    },
    cancel() {
      if (this.formErrors.length > 0) {
        const el = document.querySelector(".field-error:first-of-type")
        if (el.parentElement.parentElement) {
          el.parentElement.parentElement.scrollIntoView()
        }
      }

      this.modalOpen = false
      this.saving = false
      this.submitting = false
    },
    forceSubmit() {
      this.modalOpen = false
      this.saving = true
      this.saveSection({ section_id: this.formSection.id })
        .then(() => {
          this.$events.$emit("form:saved")
        })
        .finally(() => {
          this.saving = false
        })
    },
    fixErrors() {
      this.modalOpen = false
      this.submitting = false
      const firstErrorSection = this.formSections.find((section) => section.id === this.formErrors[0].sectionId)
      this.$router.push({
        name: "response-section",
        params: { id: this.$route.params.id, section: firstErrorSection.id },
      })
    },
    onError(errors) {
      this.formErrors = errors
    },
  },
}
