<template>
  <div class="form-input select-input">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="label">
      <div class="mb-2">
        <label :for="`input_${name}`" v-text="label"></label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <multiselect
        :id="`input_${name}`"
        :name="`input_${name}`"
        v-model="current"
        :options="items"
        :placeholder="value ? value : placeholder"
        :multiple="false"
        :close-on-select="true"
        :clear-on-select="true"
        :hide-selected="false"
        :show-labels="false"
        :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length }]"
        :value="value"
        @input="updated"
      >
      </multiselect>
      <field-error v-if="errors.length" :errors="errors"></field-error>
    </validation-provider>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect"
import InputMixin from "./../mixins/input"
import FieldError from "@/components/form/FieldError"

export default {
  name: "DropDownInput",
  mixins: [InputMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
    validation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: [],
      multiple: false,
    }
  },
  methods: {
    input() {
      this.$emit("input", this.current)
    },
    updated() {
      this.input()
      this.update()
    },
  },
  components: {
    FieldError,
    Multiselect,
  },
}
</script>
