<template>
  <div class="form-input yes-no-input" v-if="conditionsMet">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="`${id}-${namePrefix}${label}`" :immediate="immediate">
      <template v-if="!readOnly">
        <div class="w-full mb-2">
          <span v-text="label"></span>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div class="flex flex-wrap mb-2">
          <label class="flex items-center mr-4">
            <input type="radio" v-model="current" :name="`input_${id}_${name}_yes`" value="Yes" class="mr-2" @change="updated" />
            <span>Yes</span>
          </label>
          <label class="flex items-center">
            <input type="radio" v-model="current" :name="`input_${id}_${name}_no`" value="No" class="mr-2" @change="updated" />
            <span>No</span>
          </label>
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :print="print"
        :type="type"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  name: "YesNoInput",
  components: { ReadOnlyString, FieldError },
  mixins: [InputMixin, ConditionalMixin],
  data() {
    return {
      auto: false,
    }
  },
  methods: {
    updated() {
      this.input()
      this.$nextTick(() => {
        this.update()
      })
    },
  },
}
</script>
