import endpoint from "./endpoint-silent"

/**
 * @param address
 * @param params
 * @returns {Promise<{}>|Promise<AxiosResponse<any>>}
 */
const lookup = (address, params = {}) => {
  return endpoint.get(`/address/${address}`, {
    //timeout: 1500,
    params,
  })
}

/**
 * @param id
 * @param params
 * @returns {Promise<{}>|Promise<AxiosResponse<any>>}
 */
const getAddress = (id, params = {}) => {
    return endpoint.get(`/addressId/${id}`, {
        params
    })
}

export default {
    lookup,
    getAddress
}
