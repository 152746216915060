import { isArray } from "lodash"

export default {
  computed: {
    form() {
      return this.$store.getters["form/current"]
    },
    formSections() {
      if (!isArray(this?.form?.sections)) {
        return []
      }

      return this?.form?.sections
      //return this?.form?.sections.filter((s) => s.title.toLowerCase() !== "overview") ?? []
    },
    formSection() {
      if (this.$route.params?.section && this.formSections.length > 0) {
        return this.formSections.find((section) => section.id === this.$route.params.section)
      }

      return this.formSections.length > 0 ? this.formSections[0] : false
    },
  },
}
