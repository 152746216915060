<template>
  <div class="form-input read-only-input" :class="{ 'rounded bg-red-100 p-2.5': errors.length }">
    <div class="mb-2" v-if="showLabel">
      <span v-text="label" :class="{ 'text-required': errors.length }"></span>
      <span class="text-required" v-if="validation.includes('required')"> *</span>
    </div>
    <div :class="{ 'field-border': !errors.length, 'py-3': processedValue && !print }">
      <ul v-if="displayType === 'loop'" class="m-0 list">
        <li v-for="(item, ind) in processedValue" :key="ind" v-text="item" class="break-all"></li>
      </ul>
      <span v-else v-text="processedValue" class="break-all"></span>
    </div>
    <field-error :errors="errors"></field-error>
  </div>
</template>
<script>
import inputProps from "./../helpers/inputProps"
import FieldError from "@/components/form/FieldError"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import { isArray, isEmpty, isPlainObject } from "lodash"
export default {
  components: { FieldError },
  props: {
    ...inputProps,
  },
  computed: {
    processedValue() {
      const parsed = jsonParse(this.value, this.value)

      if (!isEmpty(parsed)) {
        return parsed?.value || parsed
      }

      return ""
    },
    displayType() {
      if (isArray(this.processedValue) || isPlainObject(this.processedValue)) {
        return "loop"
      }

      return "string"
    },
  },
}
</script>
