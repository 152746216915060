<template>
  <footer class="flex w-full px-4 py-6 bg-white rounded-t-lg shadow-top fixed bottom-0 left-0 lg:left-18rem z-50">
    <div class="flex w-full main-container xl:justify-end">
      <div class="flex items-center justify-between w-full xl:justify-end lg:px-12">
        <div class="relative flex items-center justify-between flex-grow xl:ml-auto">
          <slot name="review"></slot>
          <div class="flex">
            <transition name="fade-enter-leave" mode="out-in">
              <div key="changes-saved" v-if="saved" class="flex items-center text-green-500 mr-7">
                <svg width="29" height="27" fill="none" xmlns="http://www.w3.org/2000/svg" class="text-green-500 fill-current stroke-current">
                  <circle cx="12.5" cy="14.5" r="11.5" fill="transparent" stroke-width="2" />
                  <path
                    d="M11.216 20.351l.355.36.356-.36L27.875 4.193a2.18 2.18 0 000-3.057 2.128 2.128 0 00-3.035 0L11.57 14.58l-3.41-3.456a2.128 2.128 0 00-3.036 0 2.18 2.18 0 000 3.056l6.09 6.171z"
                    stroke-linecap="round"
                    stroke="white"
                  /></svg
                ><span class="whitespace-nowrap">Changes saved</span>
              </div>
            </transition>
            <slot name="submit"></slot>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      saved: false,
    }
  },
  created() {
    this.$events.$on("form:saved", () => {
      this.saved = true
      setTimeout(() => {
        this.saved = false
      }, 2000)
    })
  },
  destroyed() {
    this.$events.$off("form:saved")
  },
}
</script>
<style>
.shadow-top {
  box-shadow: 2px -8px 16px 0px rgba(24, 50, 115, 0.1);
}
</style>
