<template>
  <div class="wrapper">
    <div>
      <card>
        <div v-html="safeDescription" class="text-content max-w-2xl m-auto py-6"></div>
        <div class="max-w-2xl m-auto" v-if="page.files">
          <file-list :files="page.files"></file-list>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Card from "@/components/Card"
import FileList from "@/components/FileList"
import DynamicTitleMixin from "@/mixins/dynamic-title"
import render from "@/plugins/render"
export default {
  components: {
    Card,
    FileList,
  },
  mixins: [DynamicTitleMixin],
  computed: {
    title() {
      return this.page?.title
    },
    page() {
      return this.$store.getters["knowledge/findByMachineName"](this.$route.params.path) ?? false
    },
    safeDescription() {
      if (!this.page?.description) {
        return ""
      }

      // this render function uses dompurify so we don't need to run it through the v-dompurify-html attribute
      return render.editor(this.page.description)?.innerHTML ?? ""
    },
  },
  created() {
    this.$store.dispatch("knowledge/byMachineName", this.$route.params.path)
  },
}
</script>
