<template>
  <div class="items-center mt-8 footer xl:flex justify-evenly" v-if="searchResult">
    <div class="flex-auto pb-4 text-center select-wrapper xl:text-left xl:pb-0">
      <select
        name="paginationTotal"
        v-if="searchResult.pagination.total > 10"
        :value="perPage"
        id="paginationCount"
        class="py-2 pl-4 pr-12 bg-white shadow"
        @change="updatePageCount($event)"
      >
        <option v-if="searchResult.pagination.total > 10" selected="selected" :value="10"><strong>Show: </strong>10</option>
        <option v-if="searchResult.pagination.total > 25" :value="25"><strong>Show: </strong>25</option>
        <option v-if="searchResult.pagination.total > 50" :value="50"><strong>Show: </strong>50</option>
        <option value="all"><strong>Show: </strong>All</option>
      </select>
    </div>
    <div class="flex-auto pb-4 text-right pagination-bar xl:pb-0">
      <paginate
        v-if="perPage < searchResult.pagination.total"
        :page-count="Math.ceil(searchResult.pagination.total / searchResult.pagination.per_page)"
        :click-handler="goToPage"
        :prev-text="'Previous'"
        v-model="initialPage"
        :next-text="'Next'"
        :container-class="'pagination'"
      >
      </paginate>
    </div>
    <div class="flex-auto text-center total-count xl:text-right"><strong>Total: </strong> {{ searchResult.pagination.total }}</div>
  </div>
</template>
<script>
export default {
  props: {
    searchResult: {
      type: Object,
      default: () => {},
    },
    perPage: {
      type: [Number, String],
      default: 10,
    },
    page: {
      type: [Number, String],
      default: 1,
    },
  },
  data() {
    return {
      initialPage: parseInt(this.page),
    }
  },
  methods: {
    goToPage(page) {
      this.$emit("goToPage", page)
    },
    updatePageCount(e) {
      this.$emit("updatePageCount", e.target.value)
    },
  },
}
</script>
