//import { getAccount } from "@/store/modules/auth"
import { main } from "@/main"
import axios from "axios"
import qs from "qs"
import { useDummy } from "@/plugins/util"
//import { router } from "@/routes"
import store from "@/store"

const instance = axios.create({
  baseURL: process.env.VUE_APP_ENDPOINT,
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" })
  },
})

//axios.defaults.baseURL = process.env.VUE_APP_ENDPOINT
instance.defaults.headers.common["X-Api-Key"] = process.env.VUE_APP_API_TOKEN

instance.interceptors.request.use(
  async (config) => {
    // Do something before request is sent
    let token = store.getters["auth/token"]

    if (token?.accessToken) {
      config.headers.common["Authorization"] = `Bearer ${token.accessToken}`
    }

    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  },
)

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response.status >= 200 && response.status < 300) {
      return response
    }

    return Promise.reject("Response failed")
  },
  (error) => {
    console.log(error)
    console.log(error.response)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let type = "error"
    switch (error.response?.status) {
      case 401:
        type = "error"
        break
      case 404:
        type = "404"
        break
      case 400:
        type = "404"
        break
    }
    //router.push(type)
    main?.$events.$emit("error", type)
    return Promise.reject(error)
  },
)

const endpoint = {
  get: (url, params = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.get(url, { params })
  },
  post: (url, data = {}, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.post(url, data, config)
  },
  put: (url, data = {}, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.put(url, data, config)
  },
  patch: (url, data = {}, config = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.patch(url, data, config)
  },
  delete: (url, params = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.delete(url, { params })
  },
  download: (url, filename, params = {}) => {
    if (useDummy()) {
      return Promise.resolve({})
    }

    return instance.get(url, { params, responseType: "blob" }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", filename)
      link.click()
    })
  },
}

export default endpoint
