<template>
    <div class="form-input address-input" v-if="conditionsMet">
        <div class="print-label" v-if="showLabel">
            <strong v-text="label"></strong>
        </div>

        <validation-provider mode="eager"
                             ref="provider"
                             :rules="lookupRules"
                             v-slot="{ errors, changed }"
                             :name="`${id}-${namePrefix}${label}`"
                             :immediate="immediate">
            <template v-if="!readOnly">
                <div class="mb-2" v-if="showLabel && $feature('address')">
                    <label :for="`input_${id}_${name}`">Search for address</label>
                    <span class="text-required" v-if="validation.includes('required')"> *</span>
                </div>
                <input type="text"
                       maxlength="100"
                       :name="`input_${id}_${name}`"
                       :id="`input_${id}_${name}`"
                       v-model="search"
                       @keydown.enter.prevent="checkAddress()"
                       class="search-input"
                       :class="{ error: errors.length > 0 && !changed }" v-if="$feature('address')" />
                <card v-if="results.length > 0" class="">
                    <button v-for="(item, index) in results"
                            :key="index"
                            class="pb-2 hover:bg-blue-100 w-full focus:bg-blue-200 block text-left"
                            @click.prevent="select(item)">
                        <div class="pl-2">
                            <div>
                                {{ item.SingleLineAddress }}
                            </div>
                        </div>
                    </button>
                    <button class="pb-2 hover:bg-blue-100 w-full focus:bg-blue-200 block text-left" @click.prevent="manual()">
                        <div class="pl-2">
                            <div>
                                <p>Use manual address</p>
                            </div>
                        </div>
                    </button>
                </card>
                <button type="button"
                        @click.prevent="checkAddress()"
                        :disabled="!search.length"
                        class="mt-2 mb-2 button inline-flex button--outline"
                        v-text="!loading ? 'Look up address' : 'Searching'"
                        v-if="$feature('address')"></button>
                <button type="button" @click.prevent="manual()" class="ml-2 mt-2 mb-2 button inline-flex button--outline" v-if="$feature('address')">
                    Use manual address
                </button>
                <field-error v-if="apiErrors.length > 0" :errors="apiErrors" :id="id"></field-error>
                <field-error v-if="errors.length > 0 && !changed" :errors="errors" :id="id"></field-error>
                <field-meta v-if="help && showLabel" :help="help"></field-meta>
            </template>
        </validation-provider>

        <span v-show="showFields">
            <field-meta v-if="help && showLabel" :help="help"></field-meta>

            <validation-provider mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Address Line 1`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_address_line_1`">Address line 1</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="mb-4">
                        <input type="text"
                               :id="`input_${id}_address_line_1`"
                               v-model="form.addressLine1"
                               v-debounce:300="updated"
                               :class="{ error: errors.length > 0 && !changed }" />
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Address Line 1"
                                  :show-label="showLabel"
                                  :value="form.addressLine1"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider mode="eager" v-slot="{ errors, changed }" :name="`${id}-${namePrefix}Address Line 2`" tag="div" :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_address_line_2`">Address line 2</label>
                    </div>
                    <div class="mb-4">
                        <input type="text"
                               :id="`input_${id}_address_line_2`"
                               v-model="form.addressLine2"
                               v-debounce:300="updated"
                               :class="{ error: errors.length && !changed }" />
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Address Line 2"
                                  :value="form.addressLine2"
                                  :show-label="showLabel"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider mode="eager" v-slot="{ errors, changed }" :name="`${id}-${namePrefix}Address Line 3`" tag="div" :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_address_line_3`">Address line 3</label>
                    </div>
                    <div class="mb-4">
                        <input type="text"
                               :id="`input_${id}_address_line_3`"
                               v-model="form.addressLine3"
                               v-debounce:300="updated"
                               :class="{ error: errors.length && !changed }" />
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Address Line 3"
                                  :value="form.addressLine3"
                                  :show-label="showLabel"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Suburb`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_suburb`">Suburb</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="md:w-1/2">
                        <div class="mb-4">
                            <input type="text"
                                   :id="`input_${id}_suburb`"
                                   v-model="form.suburb"
                                   v-debounce:300="updated"
                                   :class="{ error: errors.length && !changed }" />
                            <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                        </div>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Suburb"
                                  :value="form.suburb"
                                  :type="type"
                                  :show-label="showLabel"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}State`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-4">
                        <div class="mb-2">
                            <label :for="`input_${id}_type_of_service`">State</label>
                            <span class="text-required" v-if="validation.includes('required')"> *</span>
                        </div>
                        <multiselect :id="`input_${id}_state`"
                                     :name="`input_${id}_state`"
                                     v-model="form.state"
                                     :options="states"
                                     placeholder="Select State"
                                     :multiple="false"
                                     :close-on-select="true"
                                     :clear-on-select="true"
                                     :hide-selected="false"
                                     :show-labels="false"
                                     :errors="errors"
                                     @input="updated"
                                     :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length && !changed }]">
                        </multiselect>
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="State"
                                  :value="form.state"
                                  :show-label="showLabel"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider mode="eager"
                                 :rules="textRules + `|numeric|min:4|max:4`"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Post Code`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_postcode`">Postcode</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="md:w-1/2">
                        <div class="mb-4">
                            <input type="text"
                                   v-model="form.postCode"
                                   :id="`input_${id}_postcode`"
                                   v-debounce:300="updated"
                                   :class="{ error: errors.length && !changed }" />
                            <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                        </div>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Postcode"
                                  :show-label="showLabel"
                                  :value="form.postCode"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <card :loader="true" :loading="loadingMap" v-if="loadingMap && $feature('map')">
            </card>

            <Gmap-Map v-if="this.form.addressId && $feature('map')"
                      class="mb-2 w-100" style="height: 300px;"
                      :zoom="17" :center="{lat: this.form.latitude, lng: this.form.longitude}">
                <Gmap-Marker v-if="this.form.addressId"
                             :label="this.form.label"
                             :position="{ lat: this.form.latitude, lng: this.form.longitude }">
                </Gmap-Marker>
            </Gmap-Map>

            <validation-provider v-if="format === 'siteaddress'"
                                 mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Types of Service Delivery`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2">
                        <label :for="`input_${id}_type_of_service`">Service delivery type</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="mb-4">
                        <multiselect :id="`input_${id}_type_of_service`"
                                     :name="`input_${id}_type_of_service`"
                                     v-model="form.typesOfService"
                                     :options="serviceDelivery"
                                     placeholder="Service Delivery Type"
                                     :multiple="false"
                                     :close-on-select="true"
                                     :clear-on-select="true"
                                     :hide-selected="false"
                                     :show-labels="false"
                                     @input="updated"
                                     @change="setHours"
                                     :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length > 0 && !changed }]">
                        </multiselect>
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Type of service"
                                  :show-label="showLabel"
                                  :value="form.typesOfService"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider v-if="format === 'siteaddress'"
                                 mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Frequency of service`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_frequency`">Frequency of service</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="mb-4">
                        <input type="text"
                               v-model="form.frequencyOfService"
                               :id="`input_${id}_frequency`"
                               v-debounce:300="updated"
                               :class="{ error: errors.length && !changed }" />
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Frequency of service"
                                  :show-label="showLabel"
                                  :value="form.frequencyOfService"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>

            <validation-provider v-if="format === 'siteaddress'"
                                 mode="eager"
                                 :rules="textRules"
                                 v-slot="{ errors, changed }"
                                 :name="`${id}-${namePrefix}Hours of Service`"
                                 tag="div"
                                 :immediate="immediate">
                <template v-if="!readOnly">
                    <div class="mb-2" v-if="showLabel">
                        <label :for="`input_${id}_hours`">Hours of service</label>
                        <span class="text-required" v-if="validation.includes('required')"> *</span>
                    </div>
                    <div class="mb-4">
                        <input type="text"
                               v-model="form.hoursOfService"
                               :id="`input_${id}_hours`"
                               v-debounce:300="updated"
                               :class="{ error: errors.length && !changed }" />
                        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
                    </div>
                </template>
                <read-only-string v-else
                                  class="flex flex-col flex-wrap"
                                  label="Hours of service"
                                  :show-label="showLabel"
                                  :value="form.hoursOfService"
                                  :type="type"
                                  :id="id"
                                  :errors="errors"></read-only-string>
            </validation-provider>
        </span>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect"
    import InputMixin from "./mixins/input"
    import { jsonParse } from "@/components/form/helpers/fieldParser"
    import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
    import ConditionalMixin from "./mixins/conditional"
    import { isEqual } from "lodash"
    import Card from "@/components/Card"
    import api from "@/store/api"

    let structure = {
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        suburb: "",
        state: "",
        postCode: "",
        frequencyOfService: "",
        hoursOfService: "",
        typesOfService: "",
        format: ""
    }

    export default {
        inheritAttrs: false,
        components: {
            ReadOnlyString,
            Multiselect,
            Card,
        },
        name: "AddressInput",
        mixins: [InputMixin, ConditionalMixin],
        data() {
            return {
                states: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
                serviceDelivery: ["Full Time", "Part Time", "Outreach"],
                addressSubtype: ["Permanent site", "Temporary site", "Informal site"],
                form: { ...structure, format: this.format },
                isFullTime: false,
                showFields: false,
                search: "",
                apiErrors: [],
                results: {},
                loading: false,
                loadingMap: false
            }
        },
        watch: {
            "form.typesOfService"() {
                this.setHours()
            },
        },
        methods: {
            select(address) {
                this.form.addressLine1 = address.StreetAddress
                this.form.suburb = address.Locality
                this.form.postCode = address.Postcode
                this.form.state = address.State
                this.showFields = true
                this.results = []
                this.resetAddress();
                this.update()

                if (this.$feature('map')) {
                    if (address.Id) {
                        this.loadingMap = true;
                        api.address
                            .getAddress(address.Id)
                            .then((response) => {
                                let result = response?.data?.Data || null;
                                if (result) {
                                    this.form.addressId = address.Id;
                                    this.form.latitude = result.Latitude;
                                    this.form.longitude = result.Longitude;
                                    this.form.label = result.FormattedAddress;
                                    this.loadingMap = false;
                                    this.update();
                                }
                            })
                            .catch(() => {
                                this.resetAddress();
                                this.loadingMap = false;
                                this.apiErrors.push(`The address lookup service is current experiencing problems, please enter the address using the manual option`)
                            })
                            .finally(() => {

                            })
                    }
                }
            },
            manual() {
                this.showFields = true
                this.results = []
                this.apiErrors = []
                this.resetAddress();
            },
            resetAddress() {
                if (this.$feature('map')) {
                    this.form.addressId = null;
                    this.form.latitude = -999;
                    this.form.longitude = -999;
                    this.form.label = null;
                }
            },
            updated() {
                this.input()
                this.update()
            },
            load() {
                const parsed = jsonParse(this.value, {
                    value: { ...structure, ...{ format: this.format } },
                })

                if (this.$feature('map') && parsed && parsed.value) {
                    //handling update on existing applications
                    let mapAttributes = {
                        latitude: -1,
                        longitude: -1,
                        addressId: null,
                        label: null
                    };                    
                    let existMapAttributes = Object.keys(parsed.value).includes("addressId");
                    if (!existMapAttributes) {
                        parsed.value = {
                            ...parsed.value,
                            ...mapAttributes
                        }
                    }                    
                }

                this.form = parsed?.value || { ...structure }
                if (!isEqual(this.form, structure)) {
                    this.showFields = true
                }
            },
            setHours() {
                if (this.form.typesOfService === "Full Time") {
                    this.form.hoursOfService = "9 am - 5 pm"
                    this.form.frequencyOfService = "Monday - Friday"
                    this.isFullTime = true
                } else {
                    this.isFullTime = false
                }
            },
            async checkAddress() {
                this.apiErrors = []
                this.loading = true
                this.results = []
                api.address
                    .lookup(this.search)
                    .then((response) => {
                        //console.log("found response", response)

                        this.results = response?.data?.Data || []
                        if (!this.results.length) {
                            this.apiErrors.push(`${this.search} is an invalid address. Please review and try again or enter an address manually.`)
                        }
                    })
                    .catch(() => {
                        this.apiErrors.push(`The address lookup service is current experiencing problems, please enter the address using the manual option`)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            update() {
                if (this.updatesForm) {
                    this.$emit(
                        "updated",
                        {
                            type: "address",
                            value: this.form,
                        },
                        {
                            id: this?.response?.id ?? "",
                            question_id: this.id,
                            type: this.type,
                            name: this.name,
                            files: this.files,
                        },
                    )
                } else {
                    this.$emit("changed", {
                        value: {
                            type: "address",
                            value: this.form,
                        },
                        question_id: this.id,
                        type: this.type,
                        name: this.label,
                        files: this.files,
                    })
                }
            },
        },
        computed: {
            textRules() {
                return this.validation.includes("required") ? "required" : ""
            },
            lookupRules() {
                if (!this.showFields) {
                    return this.validation.includes("required") ? "required" : ""
                }

                return ""
            },
        },
    }
</script>
