<template>
  <div class="lg:px-0 lg:container h-full">
    <div class="items-center flex flex-col items-baseline h-full inner">
      <div class="pt-4 top ">
        <div class="items-center justify-end mb-4 sm:mb-0 sm:mr-4 ">
          <router-link :to="{ name: 'home' }" class="flex flex-col py-4 my-4 ml-8 mt-8 no-underline text-lg">
            <div class="pr-4 pb-4">
              <img alt="Australian Government Logo" src="../../assets/crest-white.png" />
            </div>
            <strong class="font-bold site-name">Employment Services Tenders</strong>
          </router-link>
          <button-router-link v-if="$feature('login')" @click="$msal.signIn()" :outline="false" class="login-button">
            Login / register
          </button-router-link>
        </div>
        <main-menu class="py-4 text-white" />
      </div>

      <div class="bottom flex-grow w-full py-4 pb-20 items-center bg-top">
        <secondary-menu class="pt-20" />
        <div class="p-4 m-4" v-if="authenticated">
          <logout :icon="true" class="text-white"></logout>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainMenu from "@/components/layout/MainMenu"
import SecondaryMenu from "@/components/layout/SecondaryMenu"
import Logout from "@/components/layout/Logout"
import Cables from "@/assets/images/cables.svg"
import CablesMobile from "@/assets/images/mobile-cables.svg"
import ButtonRouterLink from "@/components/ButtonRouterLink"
export default {
  components: {
    MainMenu,
    SecondaryMenu,
    Logout,
    ButtonRouterLink,
  },
  data: () => ({
    Cables,
    CablesMobile,
  }),
}
</script>
<style lang="scss">
.site-name {
  font-size: 1.6rem;
}
.top {
  @apply w-full;
  // background: linear-gradient(190deg, rgba(0, 45, 63, 1) 0%, rgba(0, 23, 32, 1) 50%);
  a,
  strong,
  span {
    @apply text-white;
  }

  @screen lg {
    @apply flex-grow bg-left-top bg-no-repeat;
    /*background-image: url("~@/assets/images/cables--corner.png");*/
    a,
    strong,
    span {
      // @apply text-primary-dark;
    }
  }

  .logout {
    border-color: rgba(240, 248, 255, 0.5);
  }
  .login-button {
    @apply block ml-4 mr-4 text-center;
    @screen lg {
      @apply hidden;
    }
  }
}
</style>
