<template>
  <div class="form-input text-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="flex items-end justify-between mb-2" v-if="showLabel">
          <div>
            <label :for="`input_${name}`" v-text="label"></label>
            <span class="text-required" v-if="validation.includes('required')"> *</span>
          </div>
          <div v-if="charactersLeft" class="text-xs text-right" :class="isOutOfRange ? '' : 'text-red-500'">{{ charactersLeft }} characters</div>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>

        <input
          :type="type"
          :name="name"
          :id="`input_${name}`"
          v-model="current"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="{ error: errors.length && !changed }"
          v-debounce:300="update"
        />
        <reusable-modal v-if="reusable" :reusable-id="reusable" @reuse="reuse"></reusable-modal>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :value="value"
        :type="type"
        :print="print"
        :show-label="showLabel"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
import ReusableModal from "@/components/form/ReusableModal"

export default {
  name: "TextInput",
  components: { ReadOnlyString, FieldError, ReusableModal },
  mixins: [InputMixin, ConditionalMixin],
  props: {
    type: {
      type: String,
      default: "text",
    },
  },
}
</script>
