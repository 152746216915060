<template>
  <div class="form-input content-input" v-if="conditionsMet">
    <div class="mb-2" v-if="showLabel">
      <div v-text="label"></div>
    </div>
    <field-meta v-if="help && showLabel" :help="help"></field-meta>
    <file-list v-if="attachments && attachments.length > 0" :files="attachments" :show-title="false"></file-list>
  </div>
</template>
<script>
import FileList from "../FileList.vue"
import InputMixin from "./mixins/input"
export default {
  components: { FileList },
  mixins: [InputMixin],
}
</script>
