import endpoint from "./endpoint-silent"

const search = (search) => {
  return endpoint.get(`/abn/search/${search}`)
}

const details = (abn) => {
  return endpoint.get(`/abn/details/${abn}`)
}

export default {
  search,
  details,
}
