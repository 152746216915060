export default {
  created() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav)
    })
  },

  beforeRouteUpdate(to, from, next) {
    if (this.$store.getters["response/isDirty"]) {
      if (!window.confirm("You have unsaved changes that will be lost. Are you sure you want to leave this page?")) {
        return
      } else {
        this.$store.commit("response/setClean")
      }
    }
    next()
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters["response/isDirty"]) {
      if (!window.confirm("You have unsaved changes that will be lost. Are you sure you want to leave this page?")) {
        return
      } else {
        this.$store.commit("response/setClean")
      }
    }
    next()
  },

  methods: {
    preventNav(event) {
      if (!this.$store.getters["response/isDirty"]) return
      event.preventDefault()
      event.returnValue = ""
    },
  },
}
