<template>
  <div>
    <guest-home v-if="!authenticated"></guest-home>
    <auth-home v-if="authenticated"></auth-home>
  </div>
</template>
<script>
import GuestHome from "./GuestHome.vue"
import AuthHome from "./AuthHome.vue"

export default {
  components: {
    GuestHome,
    AuthHome,
  },
}
</script>
