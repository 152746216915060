import { processConditions } from "@/components/form/helpers/conditionProcessor"

export default {
  data: () => ({
    conditionsMet: true,
  }),
  created() {
    this.load()
    this.testCondition()
  },
  mounted() {
    this.$events.$on("value:updated", this.testCondition)
  },
  destroyed() {
    this.$events.$off("value:updated", this.testCondition)
  },
  watch: {
    conditionsMet() {
      if (this.$store.getters["response/isDirty"]) {
        this.input()
        this.update()
      }
    },
  },
  methods: {
    testCondition() {
      this.conditionsMet = processConditions(this.conditional_statements)
    },
  },
}
