<template>
  <div class="py-6 px-4 lg:px-12" v-if="content">
    <alert><div v-dompurify-html="content.description"></div></alert>
  </div>
</template>
<script>
import Alert from "@/components/Alert"
export default {
  components: {
    Alert,
  },
  created() {
    this.$store.dispatch("blockContent/load", ["maintenance"])
  },
  computed: {
    content() {
      let contentText = this.$store.getters["blockContent/findByMachineName"]("maintenance") || false
      if (contentText.description === "") {
        return null
      }
      return contentText
    },
  },
}
</script>
