import api from "@/store/api"
import { useDummy } from "@/plugins/util"

let items = []
let addenda = []

if (useDummy()) {
  items = require("../../mocks/data/tenders").default
}

// state
const state = () => ({
  items: items,
  addenda: addenda,
  page: 1,
  limit: 100,
})

const formatTenders = (t) => ({
  ...t,
  status: t.status === "open" ? "current" : t.status,
  locations_string: t.locations.join(" "),
})

// getters
const getters = {
  openTenders: (state, getters) => {
    return getters.whereStatus("current")
  },

  allTenders: (state) => state.items.map(formatTenders),

  tenders: (state, getters) => (status) => {
    if (getters.allTenders && getters.allTenders.length < 1) {
      return []
    }

    return getters.allTenders.filter((t) => t.status === status)
  },

  whereStatus: (state, getters) => (status, limit = 0) => {
    if (limit > 0) {
      return getters.tenders(status).slice(0, limit)
    }

    return getters.tenders(status)
  },

  hasTenders: (state, getters) => (status = false) => {
    if (status) {
      return getters.tenders(status).length > 0
    }

    return getters.allTenders.length > 0
  },

  find: (state, getters) => (id) => {
    return getters.allTenders.find((tender) => tender.id === id)
  },

  latest: (state, getters) => (status, limit = 0) => {
    const latestTenders = getters.tenders(status).sort((a, b) => {
      return new Date(a.close_at) - new Date(b.close_at)
    })
    return limit > 0 ? latestTenders.slice(0, limit) : latestTenders
  },

  oldest: (state, getters) => (status, limit = 0) => {
    const oldestTenders = getters.tenders(status).sort((a, b) => {
      return new Date(b.close_at) - new Date(a.close_at)
    })
    return limit > 0 ? oldestTenders.slice(0, limit) : oldestTenders
  },

  findAddendaIndex: (state, getters) => (tenderId, addendum) => {
    return getters.getAddenda(tenderId).indexOf(addendum)
  },

  getAddenda: (state) => (tenderId) => {
    return state.addenda.filter((tender) => tender.tender_id === tenderId)
  },
}

// actions
const actions = {
  add({ commit }, tender) {
    commit("addTender", { tender })
  },

  load({ commit }, { status }) {
    status = status.map((x) => (x === "current" ? "open" : x))
    return api.tenders.collection({ status }).then((response) => {
      commit("setTenders", {
        data: response.data,
      })
    })
  },

  one({ commit }, id, params = {}) {
    return api.tenders.single(id, params).then((response) => {
      commit("updateTender", response)
    })
  },
}

// mutations
const mutations = {
  addTender(state, { tender }) {
    state.items.push(tender)
  },
  updateTender(state, { data }) {
    if (!data?.id) {
      return
    }
    let index = state.items.findIndex((t) => t.id === data.id)
    data.detailed = true

    if (index > -1) {
      state.items = [...state.items.slice(0, index), data, ...state.items.slice(index + 1)]
    } else {
      state.items.push(data)
    }
  },
  setTenders(state, { data }) {
    if (!data?.data) {
      return
    }

    state.items = data.data.reduce(
      (acc, el) => {
        if (state.items.findIndex((current) => current.id === el.id) === -1) {
          acc.push(el)
        }
        return acc
      },
      [...state.items],
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
