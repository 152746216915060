<template>
  <div class="relative flex flex-wrap items-center justify-between p-4 lg:flex-nowrap lg:px-12 lg:mt-0">
    <slot class="" name="menu"></slot>
    <h1 v-text="title ? title : ''" class="flex-grow order-3 w-full mb-0 lg:flex-grow-0 lg:order-2 lg:w-2/3"></h1>
    <div class="flex-grow w-full order-3 mb-0 lg:flex-grow-0 lg:order-1 lg:whitespace-nowrap" v-if="authenticated && isHome">
      <welcome-card></welcome-card>
    </div>
    <user-menu class="order-2 top-4 lg:top-0 right-4 lg:right-0 lg:relative lg:order-3 lg:whitespace-nowrap" />
  </div>
</template>
<script>
import WelcomeCard from "@/components/cards/WelcomeCard"
import UserMenu from "@/components/layout/UserMenu"
export default {
  components: {
    UserMenu,
    WelcomeCard,
  },
  props: {
    title: {
      type: [String, Boolean],
      default: "",
    },
  },
  computed: {
    isHome() {
      return this.$route.name === "home"
    },
  },
}
</script>
<style lang="scss" scoped>
.not-home {
  @apply hidden;
  @screen md {
    @apply block;
  }
}
</style>
