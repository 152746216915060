import tenders from "./tenders"
import responses from "./responses"
import knowledge from "./knowledge"
import blockContent from "./block-content"
import abn from "./abn"
import auth from "./auth"
import address from "./address"
import reusable from "./reusable"

export default {
  tenders,
  responses,
  knowledge,
  blockContent,
  auth,
  abn,
  address,
  reusable,
}
