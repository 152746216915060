<template>
  <div class="form-input table-input" v-if="conditionsMet">
    <template>
      <div class="mb-2">
        <label :for="`input_${name}`" v-text="label"></label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <field-meta v-if="help && showLabel" :help="help"></field-meta>
      <div class="w-full mb-4 lg:overflow-x-scroll">
        <table v-if="fields.length > 0" class="border-t border-gray-300 horizontal-table">
          <thead>
            <tr>
              <th v-for="field in fields" :key="field.id">
                <span v-text="field.label"></span>
                <span class="text-required" v-if="field.validation && field.validation.includes('required')"> *</span>
              </th>
              <th v-if="!readOnly"></th>
            </tr>
          </thead>
          <tbody>
            <horizontal-table-row
              :fields="builtFields"
              v-model="current[ind]"
              @updated="updated"
              v-for="(row, ind) in current"
              :key="ind"
              :read-only="readOnly"
              :immediate="immediate"
              :print="print"
              :rowIndex="ind"
              @removeRow="removeRow(ind)"
            >
            </horizontal-table-row>
          </tbody>
        </table>
      </div>
      <button class="flex px-3 py-2 text-white rounded bg-primary-light" v-if="!readOnly && canAddRows" @click.prevent="addRow">
        <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
          <path d="M11.5 0v23M0 11.5h23" stroke="#fff" stroke-width="7" />
        </svg>
        Add Section
      </button>
      <field-error v-if="errors.length > 0" :errors="errors"></field-error>
    </template>
  </div>
</template>
<script>
import { fieldComponentMixin } from "./helpers/fieldTypes"
import tableInputMixin from "./mixins/table"
import conditionalInputMixin from "./mixins/conditional"
import HorizontalTableRow from "./HorizontalTableRow.vue"
import FieldError from "@/components/form/FieldError"
import FieldMeta from "@/components/form/FieldMeta"
export default {
  inheritAttrs: false,
  components: { FieldError, HorizontalTableRow, FieldMeta },
  name: "HorizontalTableInput",
  mixins: [fieldComponentMixin, tableInputMixin, conditionalInputMixin],
  data() {
    return {
      current: [],
    }
  },
  props: {
    value: {
      type: [String, Object, Array],
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
