<template>
    <div>
        <div v-if="name">
            <strong>Name: </strong>
            <span v-text="name"></span>
        </div>
        <div v-if="abn">
            <div v-if="abn.identifierValue">
                <strong>ABN: </strong>
                <span v-text="abn.identifierValue"></span>
            </div>
            <div v-if="abn.isCurrentIndicator">
                <strong>Current: </strong>
                <span v-text="abn.isCurrentIndicator === 'Y' ? 'Yes' : 'No'"></span>
            </div>
        </div>
        <div v-if="address">
            <strong>Location: </strong>
            <span v-text="address"></span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            item: {
                type: Object,
                default: () => { },
            },
        },
        computed: {
            name() {
                if (this.item?.mainName?.organisationName) {
                    return this.item.mainName.organisationName
                }

                if (this.item?.legalName?.givenName && this.item?.legalName?.familyName) {
                    return [this.item?.legalName?.givenName, this.item?.legalName?.otherGivenName, this.item?.legalName?.familyName]
                        .filter((name) => !!name)
                        .join(" ")
                }

                if (this.item?.legalName?.fullName) {
                    return this.item?.legalName?.fullName
                }

                return false
            },
            address() {
                let address = []
                if (this.item?.mainBusinessPhysicalAddress?.postcode) {
                    address.push(this.item.mainBusinessPhysicalAddress.postcode)
                }

                if (this.item?.mainBusinessPhysicalAddress?.stateCode) {
                    address.push(this.item.mainBusinessPhysicalAddress.stateCode)
                }

                return address.join(" - ")
            },
            abn() {
                if (this.item?.ABN && Array.isArray(this.item?.ABN) && this.item?.ABN.length > 0) {
                    let sorted = this.item.ABN.sort((a, b) => {
                        return new Date(b.replacedFrom) - new Date(a.replacedFrom);
                    });
                    return sorted[0];
                }
                else {
                    return this.item?.ABN;
                }
            }
        },
    }
</script>
