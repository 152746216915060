/**
 * @param conditional_statements
 */
import { fieldValue } from "@/components/form/helpers/fieldParser"
import { every, some } from "lodash"
import store from "../../../store"
import { matchCondition } from "./conditionalRules"

export const processConditions = (conditional_statements, savedFieldsOnly = false) => {
  if (!conditional_statements) {
    return true
  }

  let ands = []
  let ors = []
  for (const statements of conditional_statements) {
    ands = []
    for (const statement of statements) {
      const conditionalId = statement.field
      const field = store.getters["form/fieldByConditionalId"](conditionalId)
      if (field) {
        const value = fieldValue(field, store.getters["response/fieldValue"](field.id, savedFieldsOnly))
        ands.push(matchCondition(statement, value))
      }
    }
    // check all the and statements are true and add that boolean to the ors
    ands.length > 0 && ors.push(every(ands))
  }

  // if we have some ors check if any of them are true
  if (ors.length > 0) {
    return some(ors)
  }

  return false
}
