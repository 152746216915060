<template>
  <div v-click-outside="hide" class="relative actions">
    <a @click="open = !open" class="inline-flex cursor-pointer leading-none w-8 h-8 items-center justify-center">
      <span v-if="label">{{ label }}</span>
      <span>
        <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 4C7.89543 4 7 3.10457 7 2C7 0.895431 7.89543 -3.91405e-08 9 -8.74228e-08C10.1046 -1.35705e-07 11 0.89543 11 2C11 3.10457 10.1046 4 9 4Z"
            fill="#717579"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2 4C0.89543 4 -3.91405e-08 3.10457 -8.74228e-08 2C-1.35705e-07 0.895431 0.89543 -3.91405e-08 2 -8.74228e-08C3.10457 -1.35705e-07 4 0.89543 4 2C4 3.10457 3.10457 4 2 4Z"
            fill="#717579"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 4C14.8954 4 14 3.10457 14 2C14 0.895431 14.8954 -3.91405e-08 16 -8.74228e-08C17.1046 -1.35705e-07 18 0.89543 18 2C18 3.10457 17.1046 4 16 4Z"
            fill="#717579"
          />
        </svg>
      </span>
    </a>
    <div
      class="absolute rounded z-10 shadow-xl border border-gray-border bg-white text-left top-full w-56"
      :class="{ 'right-1/2 translate-x-1/2': dropPosition === 'center', 'right-0': dropPosition === 'right' }"
      v-if="open"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Boolean],
      default: false,
    },
    dropPosition: {
      type: String,
      default: "center",
    },
  },
  data() {
    return {
      open: false,
    }
  },
  methods: {
    hide() {
      this.open = false
    },
  },
}
</script>
