<template>
  <router-link
    class="flex flex-col px-2 py-4 no-underline rounded item sm:flex-row sm:flex-wrap md:items-center"
    :to="{ name: 'tender', params: { id: tender.id } }"
  >
    <div class="mb-2 text-sm text-gray-900 sm:w-1/2 md:w-1/3 lg:w-1/2 2xl:w-1/3 md:text-center md:mb-0" v-if="tender.status === 'current'">
      <div class="mb-2">
        <strong>Close date : </strong>
        <span v-text="$moment(tender.close_at).format('Do MMMM YYYY')"></span>
      </div>
      <time-remaining :item="tender"></time-remaining>
    </div>
    <div class="py-2 text-sm text-gray-900 sm:w-1/2 md:w-1/3 lg:w-1/2 2xl:w-1/3" v-if="tender.status === 'closed'">
      <div class="block">
        <strong>Closed : </strong>
        <span v-text="$moment(tender.close_at).format('Do MMMM YYYY')"></span>
      </div>
    </div>
    <div class="w-full mb-2 md:mb-0 title sm:pl-4 sm:w-1/2 md:w-2/3 lg:w-1/2 2xl:w-2/3">
      <span class="text" v-text="title"></span>
      &nbsp;<span v-if="tender.addenda_count" class="addenda-pill">Addenda</span>
    </div>
    <div class="w-full text-black" v-if="showLocation">
      <strong>Location: </strong> <span v-for="location in tender.locations" :key="location">{{ location }} </span>
    </div>
  </router-link>
</template>
<script>
import TimeRemaining from "@/components/TimeRemaining"
export default {
  components: { TimeRemaining },
  props: {
    tender: {
      default: () => {},
      type: Object,
    },
    showLocation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    remaining() {
      return this.$moment(this.tender.close_at).fromNow(true)
    },
    title() {
      return this.tender.type === "notice" ? this.tender.title + " (Notice)" : this.tender.title
    },
  },
}
</script>
<style lang="scss" scoped>
.item {
  .title {
    @apply text-primary-light;
  }
  &:hover {
    .title {
      span.text {
        @apply underline;
      }
    }
  }
}
@screen md {
  .item {
    @apply flex;
  }
}
</style>
