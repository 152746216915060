<template>
  <span v-if="errors.length > 0" class="flex items-center py-1 text-red-700 field-error">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
      <path d="M9 5L9 9" stroke="#CF1D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="text-red-700 stroke-current" />
      <circle cx="9" cy="9" r="8" stroke-width="2" class="text-red-700 stroke-current" />
      <circle cx="9" cy="12" r="1" class="text-red-700 fill-current" />
    </svg>
    {{ errors[0].replace(`${id}-`, "") }}
  </span>
</template>
<script>
export default {
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "",
    },
  },
}
</script>
