<template>
  <div class="tenders">
    <search-input v-model="query" class="mt-8 mb-4 search-input lg:mb-0 xl:w-2/5" :placeholder="`Search all ${status} tenders`"></search-input>
    <div class="relative hidden w-full mt-4 bg-white rounded shadow-xl table-wrapper md:table px-7 pb-7">
      <loader v-if="loading"></loader>
      <table class="w-full sortable-table">
        <thead>
          <tr>
            <th
              v-if="status !== 'upcoming'"
              @click="toggleSort('close_at')"
              class="default_sort"
              :class="{ asc: sort === 'close_at_asc', desc: sort === 'close_at_desc' }"
            >
              <span>Close date</span>
            </th>
            <th @click="toggleSort('title')" class="default_sort" :class="{ asc: sort === 'title_asc', desc: sort === 'title_desc' }">Title</th>
            <th @click="toggleSort('location')" class="default_sort" :class="{ asc: sort === 'location_asc', desc: sort === 'location_desc' }">
              Location
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in searchResult.data.items" :key="row.id">
            <td v-if="status === 'current'">
              <div class="text-xs text-center">
                <div class="mb-1">
                  <strong>Close date: </strong>
                  <span v-text="$moment(row.close_at).format('Do MMMM YYYY')"></span>
                </div>
                <time-remaining :item="row"></time-remaining>
              </div>
            </td>
            <td v-if="status === 'closed'">
              <div class="text-xs">
                <strong>Closed date: </strong>
                <span v-text="$moment(row.close_at).format('Do MMMM YYYY')"></span>
              </div>
            </td>
            <td>
              <router-link class="title" :to="{ name: 'tender', params: { id: row.id } }">{{ checkNoticeType(row) }} </router-link>
              &nbsp;<span v-if="row.addenda_count" class="addenda-pill">Addenda</span>
            </td>
            <td>
              <div class="table-caption">{{ row.locations_string }}</div>
            </td>
          </tr>
          <tr v-if="searchResult.data.items.length < 1 && !loading">
            <td colspan="4">
              <span v-text="`No ${status} tenders.`"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="p-4 bg-white rounded-lg shadow mobile-list md:hidden">
      <ul class="p-0 m-0 mb-4 list-none">
        <li v-for="(tender, ind) in searchResult.data.items" :key="tender.id" :class="{ 'bg-gray-200': ind % 2 != 0 }">
          <tender-list-item :tender="tender" :show-location="true"></tender-list-item>
        </li>
        <li v-if="searchResult.data.items.length < 1 && !loading">
          <span v-text="`No ${status} tenders.`"></span>
        </li>
      </ul>
    </div>
    <table-pagination
      v-if="searchResult"
      :search-result="searchResult"
      :page="page"
      :per-page="per_page"
      @updatePageCount="updatePageCount"
      @goToPage="goToPage"
    ></table-pagination>
  </div>
</template>
<script>
import SearchInput from "@/components/form/SearchInput"
import TenderListItem from "@/components/tenders/TenderListItem"
import Loader from "@/components/Loader"
import TimeRemaining from "@/components/TimeRemaining"
import TablePagination from "@/components/TablePagination"

const configuration = {
  searchableFields: ["title", "locations"],
  sortings: {
    title_asc: {
      field: "title",
      order: "asc",
    },
    title_desc: {
      field: "title",
      order: "desc",
    },
    close_at_asc: {
      field: "close_at",
      order: "asc",
    },
    close_at_desc: {
      field: "close_at",
      order: "desc",
    },
    location_asc: {
      field: "locations_string",
      order: "asc",
    },
    location_desc: {
      field: "locations_string",
      order: "desc",
    },
    category_asc: {
      field: "category",
      order: "asc",
    },
    category_desc: {
      field: "category",
      order: "desc",
    },
  },
  aggregations: {
    timeRemaining: {
      title: "Time remaining",
      size: 10,
      conjunction: false,
      sort: "close_at",
    },
    locations: {
      title: "locations",
      size: 10,
      conjunction: false,
      sort: "close_at",
    },
    category: {
      title: "category",
      size: 10,
      conjunction: false,
      sort: "category",
    },
  },
}

export default {
  components: {
    TablePagination,
    TimeRemaining,
    SearchInput,
    TenderListItem,
    Loader,
  },
  props: {
    status: {
      type: String,
      default: "current",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // adding pagination variables
    let page = this.$route?.query?.page || 1
    let pagination = this.$store.getters["auth/pagination"]

    return {
      query: "",
      filters: {},
      itemsjs: {},
      allChecked: true,
      page: page,
      per_page: pagination.per_page,
      sort_keys: Object.keys(configuration.sortings),
      sort: this.status === "current" ? "close_at_asc" : "close_at_desc",
    }
  },
  computed: {
    tenders() {
      return this.$store.getters["tenders/whereStatus"](this.status)
    },
    searchResult() {
      let filters = {}
      Object.keys(this.filters).forEach((k) => {
        filters[k] = [this.filters[k]]
      })
      return this.itemsjs.search({
        query: this.query,
        filters: filters,
        per_page: this.per_page === "all" ? this.tenders.length : this.per_page,
        page: this.page,
        sort: this.sort,
      })
    },
    categories() {
      return [...new Set(this.tenders.map((k) => k.category))]
    },
    locations() {
      return [...new Set(this.tenders.map((k) => k.locations))]
    },
    timeRemaining() {
      return [...new Set(this.tenders.map((k) => k.created_at))]
    },
  },
  methods: {
    build() {
      this.resetFilters()
      const data = this.tenders
      this.itemsjs = this.$itemsjs(data, configuration)
    },
    resetFilters() {
      let filters = {}
      Object.keys(configuration.aggregations).forEach(function(v) {
        filters[v] = []
      })
      this.filters = []
    },

    reset() {
      this.resetFilters()
      this.page = 1
      this.query = ""
    },
    goToPage(page) {
      this.$emit("pagination-change", page)
      this.page = page
    },
    toggleSort(column) {
      this.sort = this.sort === `${column}_asc` ? `${column}_desc` : `${column}_asc`
    },
    checkNoticeType(row) {
      return row.type === "notice" ? row.title + " (Notice)" : row.title
    },
    updatePageCount(per_page) {
      this.per_page = per_page
      this.$store.commit("auth/setPagination", { per_page })
    },
  },
  watch: {
    per_page() {
      this.page = 1
    },
    query() {
      this.page = 1
    },
    tenders() {
      this.build()
    },
  },
  created() {
    this.build()
  },
}
</script>
