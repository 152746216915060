<template>
  <div class="wrapper">
    <div class="xl:flex">
      <div class="xl:w-1/4 xl:pr-16 relative">
        <h3>Contact Phone</h3>
        <p><a href="tel:1300 733 514" class="tel">1300 733 514</a></p>
        <h3>Email</h3>
        <p><a :href="`mailto:${recipient}`" v-text="recipient">espurchasing@dewr.gov.au</a></p>
      </div>
      <div class="xl:w-3/4 relative">
        <loader v-if="loaderActive" />
        <card>
          <h2>Online contact form</h2>
          <contact-form :recipient="recipient"></contact-form>
          <div class="alert">
            If you have requested a response, we will contact you by email within two business days. For further information, please email the
            Employment Services Purchasing Mailbox at <a href="mailto:espurchasing@dewr.gov.au">espurchasing@dewr.gov.au</a> or alternatively, call
            the Employment Services Purchasing Hotline on <a href="tel:1300 733 514" class="tel">1300 733 514</a> (Monday to Friday, 9.00 am to 5.00
            pm Canberra time, excluding ACT and national public holidays).
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card"
import ContactForm from "@/components/form/contact/ContactForm"
import Loader from "@/components/Loader"
export default {
  mixins: [],
  components: {
    Card,
    ContactForm,
    Loader,
  },
  data: () => ({
    recipient: "espurchasing@dewr.gov.au",
    open: false,
    loaderActive: false,
    submitted: false,
  }),
}
</script>
<style lang="scss" scoped>
.wrapper {
  @apply relative;
  @screen 2xl {
    &:after {
      @apply block bg-contain absolute bg-no-repeat;
      content: "";
      height: 342px;
      width: 652px;
      z-index: -1;
      bottom: -6rem;
      left: 0;
      background-image: url("~@/assets/images/pages/contact-us.png");
    }
  }
}
</style>
