import * as msal from "@azure/msal-browser"
import { config } from "@/config"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { AuthError } from "@azure/msal-common"
import { router } from "@/routes"

class MSAL {
  constructor(options) {
    this.tokenExpirationTimers = {}
    this.debug = options?.debug ?? false
    this.library = {}

    this.policies = {
      names: {
        signUpSignIn: "B2C_1_signup_signin",
        forgotPassword: "B2C_1_reset",
        editProfile: "B2C_1_edit_profile",
      },
      authorities: {
        signUpSignIn: {
          authority: "https://[your-b2c-instance].b2clogin.com/[your-b2c-instance].onmicrosoft.com/B2C_1_signup_signin",
        },
        forgotPassword: {
          authority: "https://[your-b2c-instance].b2clogin.com/[your-b2c-instance].onmicrosoft.com/B2C_1_reset",
        },
        editProfile: {
          authority: "https://[your-b2c-instance].b2clogin.com/[your-b2c-instance].onmicrosoft.com/B2C_1_edit_profile",
        },
      },
      authorityDomain: "[your-b2c-instance].b2clogin.com",
    }

    this.data = {
      isAuthenticated: false,
      loginAvailable: false,
      accessToken: "",
      idToken: "",
      user: { name: "", userName: "" },
      custom: {},
      account: {
        accountIdentifier: "",
        homeAccountIdentifier: "",
        userName: "",
        name: "",
        idToken: {},
        idTokenClaims: {},
        sid: "",
        environment: "",
      },
    }

    this.policies = { ...this.policies, ...options.policies }

    // Config object to be passed to Msal on creation.
    // For a full list of msal.js configuration parameters,
    // visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html

    this.auth = {
      clientId: "", // This is the ONLY mandatory field that you need to supply.
      authority: "", // Choose SUSI as your default authority.
      knownAuthorities: [], // Mark your B2C tenant's domain as trusted.
      redirectUri: "/", // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
      postLogoutRedirectUri: "/", // Indicates the page to navigate after logout.
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    }

    this.cache = {
      //cacheLocation: "sessionStorage",
      //cacheLocation: "cookieStorage",
      cacheLocation: "localStorage",
      //storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
      secureCookies: true,
    }
    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    this.loginRequest = {
      scopes: ["openid", "profile", "User.Read"],
    }
    // Add here scopes for access token to be used at MS Graph API endpoints.
    this.tokenRequest = {
      scopes: ["User.Read"],
    }

    if (!options.auth.clientId) {
      throw new Error("auth.clientId is required")
    }

    this.auth = { ...this.auth, ...options.auth }
    this.cache = { ...this.cache, ...options.cache }
    this.loginRequest = { ...this.loginRequest, ...options.loginRequest }
    this.tokenRequest = { ...this.tokenRequest, ...options.tokenRequest }

    this.system = {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return
          }
          switch (level) {
            case msal.LogLevel.Error:
              console.error(message)
              return
            case msal.LogLevel.Info:
              console.info(message)
              return
            case msal.LogLevel.Verbose:
              console.debug(message)
              return
            case msal.LogLevel.Warning:
              console.warn(message)
              return
          }
        },
      },
    }

    const config = {
      auth: this.auth,
      cache: this.cache,
    }

    this.library = new msal.PublicClientApplication(config)
  }
}

const Auth = new MSAL(config.b2c)

export const getAccount = () => {
  const currentAccounts = Auth.library.getAllAccounts()
  return currentAccounts.length > 0 ? currentAccounts[0] : false
}

// state
const state = () => ({
  token: {},
  account: {},
  loginAvailable: false,
  userLocation: "",
  pagination: {
    per_page: 10,
  },
})

// getters
const getters = {
  token: (state) => {
    return state.token?.account ? state.token : false
  },

  name: (state, getters) => {
    if (!getters.token) {
      return ""
    }

    let name = []

    if (getters.token?.idTokenClaims?.given_name) {
      name.push(getters.token?.idTokenClaims?.given_name)
    }

    if (getters.token?.idTokenClaims?.family_name) {
      name.push(getters.token?.idTokenClaims?.family_name)
    }

    return name.join(" ")
  },

  authenticated: (state) => {
    let tokenDate = Date.parse(state.token?.expiresOn)
    return tokenDate > new Date() || false
  },

  loginAvailable: (state) => {
    return state.loginAvailable
  },

  account: (state) => {
    return state.account ? state.account : getAccount()
  },

  userLocation: (state) => {
    return state.userLocation
  },

  pagination: (state) => {
    return state.pagination
  },
}

// actions
const actions = {
  async login({ commit }) {
    commit("setUserLocation", router.currentRoute.fullPath)
    await Auth.library
      .loginRedirect(Auth.loginRequest)
      .then((loginResponse) => {
        Auth.debug && console.log("--- loginRedirect start ---")
        Auth.debug && console.log(loginResponse)
        Auth.debug && console.log("--- loginRedirect end ---")
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          Auth.debug && console.error("here")
          Auth.debug && console.error(error)
        }
        if (error instanceof AuthError) {
          Auth.debug && console.error("AuthError")
          Auth.debug && console.error(error.errorCode)
          Auth.debug && console.error(error.errorMessage)
        }
      })
  },
  async loginPopup({ commit, dispatch }) {
    await Auth.library
      .loginPopup(Auth.loginRequest)
      .then((loginResponse) => {
        Auth.debug && console.log(loginResponse)
        if (loginResponse !== null) {
          commit("setAccount", loginResponse)
        }
        dispatch("load")
      })
      .catch((error) => {
        console.error(error)
      })
  },
  async editProfile() {
    await Auth.library.loginRedirect(Auth.policies.authorities.editProfile)
  },
  logout({ commit, getters }) {
    const logoutRequest = {
      account: Auth.library.getAccountByHomeId(getters.account.homeAccountId),
    }

    Auth.library.logoutRedirect(logoutRequest).then(() => {
      commit("setAccount", {})
      commit("setToken", {})
    })
  },
  logoutPopup({ commit, getters }) {
    const logoutRequest = {
      account: Auth.library.getAccountByHomeId(getters.account.homeAccountId),
    }

    Auth.library.logoutPopup(logoutRequest).then(() => {
      commit("setAccount", {})
      commit("setToken", {})
    })
  },
  async load({ commit, dispatch }) {
    dispatch("syncAccount")

    await Auth.library
      .handleRedirectPromise()
      .then((response) => {
        Auth.debug && console.log("---- handle redirect promise start ----")
        Auth.debug && console.log(response)
        Auth.debug && console.log("---- handle redirect promise end ----")
        if (response !== null) {
          commit("setToken", response)
          dispatch("syncAccount")
        }
      })
      .catch((error) => {
        if (error.errorMessage.indexOf("AADB2C90091:") === 0) {
          console.debug(error.errorMessage)
          router.push({ name: "home", force: true }).catch((err) => {})
        } else {
          console.error(error)
        }
      })
      .finally(() => {
        commit("setLoginAvailable", true)
        dispatch("acquireToken")
      })
  },
  async acquireToken({ commit, getters, dispatch }) {
    if (getters.account) {
      Auth.debug && console.log(getters.account)

      /**
       * In order to obtain the ID Token in the cached obtained previously, you can initiate a
       * silent token request by passing the current user's account and the scope "openid".
       */
      Auth.library
        .acquireTokenSilent({
          ...Auth.loginRequest,
          account: Auth.library.getAccountByHomeId(getters.account.homeAccountId),
        })
        .then((response) => {
          Auth.debug && console.log(response)
          commit("setToken", response)
          dispatch("syncAccount")
        })
        .catch(() => {
          Auth.debug && console.log("Token failed")

          commit("setToken", {})
          commit("setAccount", {})
        })
    }
  },
  async syncAccount({ commit }) {
    const currentAccounts = Auth.library.getAllAccounts()
    if (currentAccounts.length > 0) {
      commit("setAccount", currentAccounts[0])
    }
  },
  async poll({ state, dispatch }) {
    if (!state.token?.expiresOn || !(state.token.expiresOn instanceof Date)) {
      dispatch("acquireToken")
      return
    }

    // if the token is going to expire in 10 minutes go refresh it
    if (new Date(state.token.expiresOn.getTime() - 1000 * 60 * 10) < new Date()) {
      dispatch("acquireToken")
    }
  },
  async maybeLoad({ state, dispatch }) {
    if (!state.token?.expiresOn || !(state.token.expiresOn instanceof Date)) {
      await dispatch("load")
      return
    }

    // if the token is going to expire in 10 minutes go refresh it
    if (new Date(state.token.expiresOn.getTime() - 1000 * 60 * 10) < new Date()) {
      await dispatch("load")
    }
  },
}

// mutations
const mutations = {
  setAccount(state, account) {
    state.account = account
  },
  setToken(state, token) {
    state.token = token
  },
  setLoginAvailable(state, status) {
    state.loginAvailable = status
  },
  setUserLocation(state, location) {
    state.userLocation = location
  },

  setPagination(state, pagination = {}) {
    state.pagination = {
      ...pagination,
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
