<template>
  <a class="button inline-flex" :class="classList" :href="link" :target="external ? '_blank' : '_self'">
    <slot></slot>
  </a>
</template>
<script>
export default {
  props: {
    to: {
      type: [String, Object],
      default: "",
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    download: {
      type: Boolean,
      default: false,
    },
    external: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: "",
    },
  },
  computed: {
    link() {
      if (this.to.includes("javascript:")) {
        return "#"
      }

      return this.to
    },
    classList() {
      let classes = [this.classes]

      if (this.arrow) {
        classes.push("button--arrow-right")
      }

      if (this.download) {
        classes.push("button--download")
      }

      if (this.outline) {
        classes.push("button--outline")
      } else {
        classes.push("button--filled")
      }

      return classes
    },
  },
}
</script>
