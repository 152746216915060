<template>
  <div>
    <div>
      <welcome-card></welcome-card>
      <div class="xl:flex flex-row-reverse">
        <div class="flex-1">
          <login-register-card v-if="$feature('login') && !authenticated"></login-register-card>
          <future-tenders-card></future-tenders-card>
          <help-guides-card></help-guides-card>
        </div>
        <div class="xl:w-7/12 xl:mr-4">
          <tender-list title="Current tenders, consultations and notices" status="current" :loading="loading" type="latest" :limit-list="3">
            <template v-slot:footer>
              <button-router-link :to="{ name: 'tenders-status', params: { status: 'current' } }" v-if="hasOpenTenders">
                See all
              </button-router-link>
            </template>
          </tender-list>
          <tender-list
            title="Closed tenders, consultations and notices"
            status="closed"
            :loading="loading"
            type="latest"
            :reverse="true"
            :limit-list="3"
          >
            <template v-slot:footer>
              <button-router-link :to="{ name: 'tenders-status', params: { status: 'closed' } }" v-if="hasClosedTenders">
                See all
              </button-router-link>
            </template>
          </tender-list>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TenderList from "@/components/tenders/TenderList"
import ButtonRouterLink from "@/components/ButtonRouterLink"
import WelcomeCard from "@/components/cards/WelcomeCard"
import Computer from "@/assets/images/computer.svg"
import HelpGuidesCard from "@/components/cards/HelpGuidesCard"
import LoginRegisterCard from "@/components/cards/LoginRegisterCard"
import FutureTendersCard from "@/components/cards/FutureTendersCard"
export default {
  components: {
    LoginRegisterCard,
    HelpGuidesCard,
    WelcomeCard,
    TenderList,
    ButtonRouterLink,
    FutureTendersCard,
  },
  data: () => ({
    Computer,
    loading: true,
  }),
  computed: {
    hasOpenTenders() {
      return this.$store.getters["tenders/hasTenders"]("current")
    },
    hasClosedTenders() {
      if (this.$route.name === "empty") {
        return false
      }
      return this.$store.getters["tenders/hasTenders"]("closed")
    },
  },
  methods: {
    load() {
      this.$store
        .dispatch("tenders/load", {
          status: ["current", "closed"],
        })
        .finally(() => {
          this.loading = false
        })
      this.$store.dispatch("blockContent/load", ["welcome_message", "help_guides", "help_guides_empty", "future_tenders"])
    },
  },
  created() {
    this.load()
  },
}
</script>
