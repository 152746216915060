<template>
  <card theme="light">
    <div class="flex flex-col-reverse md:flex-row items-start">
      <div>
        <h4 class="mb-4">Apply to respond to tenders with our easy-to-use system</h4>
        <div class="pb-4">Login or register to have an account with PaLMS to Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        <button class="button inline-flex button--filled" @click="login()" v-if="loginAvailable" :outline="false">
          Login / register
        </button>
      </div>
      <img class="ml-4 mb-4 md:mb-0" src="@/assets/images/computer.svg" alt="Login register icon" />
    </div>
  </card>
</template>
<script>
import Card from "@/components/Card.vue"
import Computer from "@/assets/images/computer.svg"
import { mapGetters, mapActions } from "vuex"

export default {
  components: {
    Card,
  },
  data: () => ({
    Computer,
  }),
  computed: {
    ...mapGetters({
      account: "auth/account",
      name: "auth/name",
      loginAvailable: "auth/loginAvailable",
    }),
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      loginPopup: "auth/loginPopup",
      logout: "auth/logout",
      editProfile: "auth/editProfile",
    }),
  },
}
</script>
