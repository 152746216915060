import api from "@/store/api"
import { useDummy } from "@/plugins/util"

let item = {}

if (useDummy()) {
  item = require("../../mocks/data/tender-form").default
}

// state
const state = () => ({
  item: item,
})

// getters
const getters = {
  current: (state) => {
    return state?.item?.sections ? state.item : false
  },
  fieldByConditionalId: (state) => (conditionalId) => {
    for (const section of state.item.sections) {
      for (const field of section.fields) {
        if (field.conditional_id === conditionalId) {
          return field
        }
      }
    }

    return null
  },
}

// actions
const actions = {
  load({ commit }, tender_id, params = {}) {
    return api.tenders.form(tender_id, params).then((response) => {
      commit("setForm", response.data)
    })
  },
}

// mutations
const mutations = {
  setForm(state, form) {
    state.item = form
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
