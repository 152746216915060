<template>
  <div class="menu secondary-menu rounded-t overflow-hidden">
    <ul class="list-none items-center justify-start m-0 p-0 pb-1">
      <li v-for="(page, ind) in pages" :key="ind">
        <router-link :to="{ name: 'page', params: { path: page.machine_name } }" exact v-text="page.title"></router-link>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters({
      pages: "knowledge/pages",
    }),
  },

  methods: {
    load() {
      if (!this.$route?.name) {
        return
      }

      if (["page-not-found", "system-error"].includes(this.$route.name)) {
        return
      }

      this.$store.dispatch("knowledge/load", {
        type: "page",
      })
    },
  },
  watch: {
    $route() {
      if (this.pages.length < 1) {
        this.load()
      }
    },
  },
  created() {
    this.load()
  },
}
</script>
<style lang="scss">
.secondary-menu {
  a {
    @apply no-underline p-2 pl-8 block text-white;
    @include transition-off;
    &.active-trail {
      text-decoration: underline;
    }
    &:hover {
      @include transition-on;
      text-decoration: underline;
    }
  }
}
</style>
