<template>
  <div class="max-w-3xl m-auto">
    <div class="relative mb-8 flex flex-col sm:flex-row items-start items-center md:items-center">
      <img src="@/assets/images/welcome-person.svg" alt="" :class="{ [` mr-8 w-32 md:w-auto`]: !authenticated, [`mr-8 w-24`]: authenticated }" />
      <div>
        <p class="mb-0">Welcome to</p>
        <h2 class="titleClass mb-4 mt-0" v-if="!authenticated">Employment Services Tenders</h2>
        <h3 class="titleClass mb-4 mt-0" v-if="authenticated">Employment Services Tenders</h3>
        <div class="mb-4 hidden sm:block" v-dompurify-html="content.description" v-if="content && !authenticated"></div>
        <button-router-link :to="{ name: 'help' }" :outline="false" class="hidden lg:inline-flex">
          Find out more about the portal
        </button-router-link>
      </div>
    </div>
    <div class="sm:hidden">
      <div class="mb-4" v-dompurify-html="content.description" v-if="content && !authenticated"></div>
    </div>
  </div>
</template>
<script>
import ButtonRouterLink from "@/components/ButtonRouterLink"
export default {
  components: {
    ButtonRouterLink,
  },
  computed: {
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("welcome_message") || false
    },
  },
}
</script>
