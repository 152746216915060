import inputProps from "../helpers/inputProps"
import { jsonParse } from "@/components/form/helpers/fieldParser"
export default {
  props: {
    ...inputProps,
    id: {
      type: String,
      default: "",
    },
    row_label: {
      type: [String, Boolean],
      default: "",
    },
    format: {
      type: String,
      default: "vertical",
    },
    prefix: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Object, Array],
      default: () => [],
    },
  },
  data() {
    return {
      current: [],
      files: [],
    }
  },
  created() {
    this.load()
  },
  computed: {
    canAddRows() {
      if (this.max === -1) {
        return true
      }

      return this.max > this.current.length
    },
  },
  methods: {
    load() {
      let field = jsonParse(this.value, {
        value: [],
      })

      this.current = field?.value ?? []

      if (!this.current.length) {
        this.addRow()
      }
    },
    addRow() {
      this.current.push([])
    },
    removeRow(ind) {
      this.current.splice(ind, 1)
      this.updated()
    },
    input() {
      this.$emit("input", this.current)
    },
    async updated() {
      /**
       * to handle overwriting in components
       */
      await this.update()
    },
    async locateFiles() {
      this.files = this.current.reduce((acc, row) => {
        row.forEach((field) => {
          if (field?.files && field.files.length > 0) {
            acc = [...acc, ...field.files]
          }
        })

        return acc
      }, [])
    },
    async update() {
      await this.locateFiles()

      let value = null

      if (this.current.length > 0) {
        value = {
          type: "table",
          format: this.format,
          prefix: this.prefix,
          value: this.current,
        }
      }

      this.$emit("updated", value, {
        id: this?.response?.id ?? "",
        question_id: this.id,
        type: this.type,
        name: this.name,
        files: this.files,
      })
    },
  },
}
