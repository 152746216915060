const config = {
  title: parse(process.env.VUE_APP_TITLE, "Employment Services Tenders"),
  features: {
    login: parse(process.env.VUE_APP_FEATURE_LOGIN, false),
    response: parse(process.env.VUE_APP_FEATURE_RESPONSE, false),
    download: parse(process.env.VUE_APP_FEATURE_DOWNLOAD, false),
    responseFlowDemo: parse(process.env.VUE_APP_FEATURE_RESPONSE_FLOW_DEMO, false),
    address: parse(process.env.VUE_APP_FEATURE_ADDRESS, false),
    map: parse(process.env.VUE_APP_FEATURE_MAP, false),
  },
  b2c: {
    auth: {
      clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
      authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
      validateAuthority: false,
      knownAuthorities: [process.env.VUE_APP_MSAL_KNOWN_AUTHORITIES],
      redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URL,
      navigateToLoginRequestUrl: false,
    },
    loginRequest: {
      scopes: ["openid", "profile", `${process.env.VUE_APP_MSAL_SCOPE_BASE}/User.Read`],
      //scopes: ["openid", "profile"],
    },
    policies: {
      names: {
        signUpSignIn: "B2C_1_tenders_signup_signin",
        forgotPassword: "B2C_1_tenders_reset",
        editProfile: "B2C_1_tenders_profile_update",
      },
      authorities: {
        signUpSignIn: {
          authority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
        },
        forgotPassword: {
          authority: process.env.VUE_APP_MSAL_FORGOT_AUTHORITY,
        },
        editProfile: {
          authority: process.env.VUE_APP_MSAL_PROFILE_EDIT_AUTHORITY,
          redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URL,
          scopes: ["openid", "profile", `${process.env.VUE_APP_MSAL_SCOPE_BASE}/User.Read`],
        },
      },
      authorityDomain: process.env.VUE_APP_MSAL_KNOWN_AUTHORITIES,
    },
    debug: process.env.NODE_ENV === "development",
    //debug: false,
  },
}

function feature(name) {
  return config.features[name]
}

function parse(value, fallback) {
  if (typeof value === "undefined") {
    return fallback
  }
  switch (typeof fallback) {
    case "boolean":
      return !!JSON.parse(value)
    case "number":
      return JSON.parse(value)
    default:
      return value
  }
}

export { config }

export default {
  install(Vue) {
    Vue.appConfig = config
    Vue.feature = feature
    Vue.prototype.$appConfig = config
    Vue.prototype.$feature = feature
  },
}
