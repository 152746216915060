<template>
  <div class="form-input select-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <multiselect
          :id="`input_${id}_${name}`"
          :name="`input_${id}_${name}`"
          v-model="current"
          :options="items"
          :placeholder="value ? value : placeholder"
          :multiple="multiple"
          :close-on-select="!multiple"
          :clear-on-select="!multiple"
          :hide-selected="multiple"
          :show-labels="false"
          :errors="errors"
          :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length && !changed }]"
          :value="value"
          @input="updated"
        >
        </multiselect>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :type="type"
        :print="print"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect"
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  name: "DropDownInput",
  mixins: [InputMixin, ConditionalMixin],
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    errors: {
      type: [String, Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      current: [],
      multiple: false,
    }
  },
  methods: {
    input() {
      this.$emit("input", this.current)
    },
    updated() {
      this.input()
      this.update()
    },
  },
  components: {
    ReadOnlyString,
    FieldError,
    Multiselect,
  },
}
</script>
