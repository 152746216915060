<template>
  <card>
    <h2>Introduction to user guide</h2>
    <div v-if="content" v-dompurify-html="content.description" class="text-content"></div>
    <div class="flex items-center justify-center my-12">
      <img src="@/assets/images/help.svg" alt="Help page image" class="w-full md:w-2/3 lg:w-1/2" />
    </div>
  </card>
</template>
<script>
import Card from "@/components/Card"
import DynamicTitleMixin from "@/mixins/dynamic-title"

export default {
  components: {
    Card,
  },
  mixins: [DynamicTitleMixin],
  methods: {
    load() {
      this.$store.dispatch("blockContent/load", ["help_guides_intro"])
    },
  },
  created() {
    this.load()
  },
  computed: {
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("help_guides_intro") ?? false
    },
    title() {
      return "Help"
    },
  },
}
</script>
<style lang="scss" scoped>
.wrapper {
  @apply relative mb-16;
  @screen 2xl {
    &:after {
      @apply block bg-contain absolute bg-no-repeat;
      content: "";
      width: 453px;
      height: 282px;
      z-index: -1;
      bottom: -15rem;
      right: 5rem;
      background-image: url("~@/assets/images/pages/help.png");
    }
  }
}
</style>
