<template>
  <div class="form-input table-input vertical-table" v-if="conditionsMet">
    <div class="mb-2">
      <label v-text="label"></label>
      <span class="text-required" v-if="validation.includes('required')"> *</span>
    </div>
    <field-meta v-if="help && showLabel" :help="help"></field-meta>
    <div class="mb-2">
      <button class="button inline-flex button--outline mr-4" @click.prevent="toggle(true)">Expand all</button>
      <button class="button inline-flex button--outline" @click.prevent="toggle(false)">Collapse all</button>
    </div>
    <div>
      <vertical-table-row
        v-for="(row, ind) in current"
        :key="ind"
        :row_label="label"
        :fields="builtFields"
        :rowIndex="ind"
        v-model="current[ind]"
        @updated="updated"
        :read-only="readOnly"
        :print="print"
        :immediate="immediate"
        :prefix="prefix"
        @removeRow="removeRow(ind)"
      ></vertical-table-row>
    </div>
    <button class="flex px-3 py-2 text-white rounded bg-primary-light" v-if="!readOnly && canAddRows" @click.prevent="addRow">
      <svg width="23" height="23" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
        <path d="M11.5 0v23M0 11.5h23" stroke="#fff" stroke-width="7" />
      </svg>
      Add&nbsp;<span v-text="prefix"></span>
    </button>
    <field-error v-if="errors.length > 0" :errors="errors"></field-error>
  </div>
</template>
<script>
import VerticalTableRow from "./VerticalTableRow"
import { fieldComponentMixin } from "./helpers/fieldTypes"
import tableInputMixin from "./mixins/table"
import conditionalInputMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import FieldMeta from "@/components/form/FieldMeta"
export default {
  inheritAttrs: false,
  name: "VerticalTableInput",
  components: {
    FieldError,
    VerticalTableRow,
    FieldMeta,
  },
  mixins: [fieldComponentMixin, tableInputMixin, conditionalInputMixin],
  data() {
    return {
      current: [],
    }
  },
  props: {
    value: {
      type: [String, Object, Array],
      default: () => {},
    },
    row_label: {
      type: [String, Boolean],
      default: "",
    },
  },
  methods: {
    toggle(state) {
      this.$events.$emit("rows:toggle", state)
    },
  },
}
</script>
