<template>
  <div>
    <div class="responses">
      <response-list title="My tenders" class="block"></response-list>
      <tender-list title="Current tenders, consultations and notices" status="current" type="normal" class="lg:hidden">
        <template v-slot:footer>
          <button-router-link :to="{ name: 'tenders-status', params: { status: 'current' } }">
            See all
          </button-router-link>
        </template>
      </tender-list>
    </div>
    <div class="xl:flex flex-row-reverse">
      <div class="flex-1">
        <help-guides-card></help-guides-card>
      </div>
      <tender-list
        title="Current tenders, consultations and notices"
        status="current"
        type="normal"
        :loading="loading"
        :limitList="4"
        class="xl:w-7/12 xl:mr-6 !hidden xl:!block"
      >
        <template v-slot:footer>
          <button-router-link :to="{ name: 'tenders-status', params: { status: 'current' } }">
            See all
          </button-router-link>
        </template>
      </tender-list>
    </div>
  </div>
</template>
<script>
import TenderList from "@/components/tenders/TenderList"
import ButtonRouterLink from "@/components/ButtonRouterLink"
import ResponseList from "@/components/responses/ResponseList.vue"
import HelpGuidesCard from "@/components/cards/HelpGuidesCard"

export default {
  components: {
    HelpGuidesCard,
    TenderList,
    ButtonRouterLink,
    ResponseList,
  },
  data() {
    return {
      loading: true,
    }
  },
  created() {
    this.$store
      .dispatch("tenders/load", {
        status: ["current"],
      })
      .finally(() => {
        this.loading = false
      })
    this.$store.dispatch("blockContent/load", ["help_guides"])
  },
}
</script>
