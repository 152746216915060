<template>
  <tenders-table :status="status" :loading="loading" @pagination-change="setPage" />
</template>
<script>
import TendersTable from "@/components/tenders/TendersTable"
import DynamicTitleMixin from "@/mixins/dynamic-title"
export default {
  data() {
    return {
      loading: true,
    }
  },
  components: {
    TendersTable,
  },
  mixins: [DynamicTitleMixin],
  computed: {
    status() {
      return this.$route.params?.status
    },
    subTitle() {
      let subTitles = {
        current: "All current tenders available for response and the close dates for the current tenders",
        closed: "All closed tenders that are waiting for award process or already awarded",
      }

      if (!this.status) {
        return ""
      }

      return subTitles[this.status]
    },
    title() {
      return `${this.status === "current" ? "Current" : "Closed"} tenders, consultations and notices`
    },
  },
  watch: {
    $route() {
      this.titleSync()
    },
  },
  methods: {
    load() {
      this.$store
        .dispatch("tenders/load", {
          status: [this.status],
        })
        .finally(() => {
          this.loading = false
        })
    },
    setPage(page) {
      this.$router
        .push({
          name: "tenders-status",
          params: {
            status: this.status,
          },
          query: { page },
        })
        .catch(() => {})
    },
  },
  created() {
    this.load()
  },
}
</script>
