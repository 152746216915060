<template>
  <a href="#" @click="logout" :class="{ icon: icon }" v-text="loading ? 'Please wait..' : 'Logout'"> </a>
</template>
<script>
export default {
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      loading: false,
    }
  },
  methods: {
    logout() {
      this.loading = true

      this.$store.dispatch("auth/logout").then(() => {
        this.loading = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>
a {
  @apply no-underline;
  @include transition-off(all);
  &.icon {
    &:before {
      content: "";
      background: url("~@/assets/images/logout.svg") no-repeat center;
      vertical-align: middle;
      @apply h-4 w-8 inline-block mr-2;
    }
  }
  &:hover {
    @apply underline;
  }
}
</style>
