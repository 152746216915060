// normal inputs
import AbnInput from "../AbnInput"
import AbnLookupInput from "../AbnLookupInput"
import TextInput from "../TextInput"
import TextareaInput from "../TextareaInput"
import NumericInput from "../NumericInput"
import DropdownInput from "../DropDownInput"
import MultiSelectInput from "../MultiSelectInput"
import ContentInput from "../ContentInput"
import EmailInput from "../EmailInput"
import YesNoInput from "../YesNoInput"
import CurrencyInput from "../CurrencyInput"
import DateInput from "../DateInput"
import DateRangeInput from "../DateRangeInput"
import TimePickerRangeInput from "../TimePickerRangeInput"
import TimePickerInput from "../TimePickerInput"
import CheckboxInput from "../CheckboxInput"
import RadioInput from "../RadioInput"
import AttachmentInput from "../AttachmentInput"
import AddressInput from "../AddressInput"
import ContactInput from "../ContactInput"

// read only inputs
import ReadOnlyString from "../read-only/ReadOnlyString"
import ReadOnlyList from "../read-only/ReadOnlyList"

// Dynamics fields
// ABN => abn
// Address => address
// Attachment => attachment
// Check Box => checkbox
// Currency => currency
// Drop Down => dropdown
// Email => Email
// Multiselect => multiselect
// Numeric => numeric
// Radio Button => radiobutton
// Table => table
// Text => text
// Content => content
// Yes/No => yes_no

export const fieldComponents = {
  //abn: AbnInput,
  abn: AbnLookupInput,
  address: AddressInput,
  attachment: AttachmentInput,
  checkbox: CheckboxInput,
  currency: CurrencyInput,
  email: EmailInput,
  dropdown: DropdownInput,
  multiselect: MultiSelectInput,
  numeric: NumericInput,
  radiobutton: RadioInput,
  text: TextInput,
  multi_linetext: TextareaInput,
  date: DateInput,
  daterange: DateRangeInput,
  timerange: TimePickerRangeInput,
  time: TimePickerInput,
  contact: ContactInput,
  content: ContentInput,
  yes_no: YesNoInput,
}

export const readOnlyFieldComponents = {
  abn: ReadOnlyString,
  address: AddressInput,
  attachment: AttachmentInput,
  checkbox: ReadOnlyList,
  currency: ReadOnlyString,
  email: ReadOnlyString,
  dropdown: ReadOnlyString,
  multiselect: ReadOnlyList,
  numeric: ReadOnlyString,
  radiobutton: ReadOnlyString,
  text: ReadOnlyString,
  multi_linetext: ReadOnlyString,
  date: ReadOnlyString,
  daterange: ReadOnlyString,
  timerange: ReadOnlyString,
  time: ReadOnlyString,
  contact: ContactInput,
  yes_no: ReadOnlyString,
}

export const getComponent = (type) => {
  return fieldComponents.hasOwnProperty(type) ? fieldComponents[type] : false
}

export const fieldComponentMixin = {
  inheritAttrs: false,
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    builtFields() {
      return this.fields.map((field) => ({
        ...field,
        component: getComponent(field.type),
      }))
    },
  },
}
