<template>
  <div class="form-input checkbox-input" v-if="conditionsMet">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="`${id}-${namePrefix}${label}`" :immediate="immediate">
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>
        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div v-for="(item, ind) in items" class="flex items-center m-1" :key="ind">
          <input
            type="checkbox"
            :name="`checkbox_${id}_${ind}`"
            v-model="current"
            :id="`checkbox_${id}_${ind}`"
            class="mr-2"
            :disabled="disabled"
            @change="update"
            :value="item"
          />
          <label v-text="item" :for="`checkbox_${id}_${ind}`"></label>
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-list
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="current"
        :type="type"
        :print="print"
        :id="id"
        :errors="errors"
      ></read-only-list>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import MultiInputMixin from "./mixins/multiInput"
import ReadOnlyList from "@/components/form/read-only/ReadOnlyList"

export default {
  name: "CheckboxInput",
  components: { ReadOnlyList },
  mixins: [InputMixin, MultiInputMixin, ConditionalMixin],
  props: {
    items: Array,
  },
  data() {
    return {
      current: [],
    }
  },
  methods: {
    buildValue() {
      let value = this.current.sort((a, b) => {
        return this.items.indexOf(a) - this.items.indexOf(b)
      })

      if (value.length < 1) {
        return ""
      }

      return {
        type: "checkbox",
        value: value,
      }
    },
  },
}
</script>
