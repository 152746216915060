var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.conditionsMet)?_c('div',{staticClass:"form-input contact-input"},[(_vm.showLabel)?_c('div',[_c('strong',{staticClass:"print-label",domProps:{"textContent":_vm._s(_vm.label)}})]):_vm._e(),(_vm.help && _vm.showLabel)?_c('field-meta',{attrs:{"help":_vm.help}}):_vm._e(),_c('div',{staticClass:"mb-4"},[(_vm.showLabel && !_vm.readOnly)?_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":("input_" + _vm.id + "_firstName")}},[_vm._v("First Name")]),(_vm.validation.includes('required'))?_c('span',{staticClass:"text-required"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.textRules,"name":(_vm.id + "-" + _vm.namePrefix + "First Name"),"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [(!_vm.readOnly)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"},{name:"debounce",rawName:"v-debounce:300",value:(_vm.update),expression:"update",arg:"300"}],class:{ error: errors.length && !changed },attrs:{"type":"text","name":("input_" + _vm.id + "_firstName"),"id":("input_" + _vm.id + "_firstName")},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),(errors.length)?_c('field-error',{attrs:{"errors":errors,"id":_vm.id}}):_vm._e()]:_c('read-only-string',{staticClass:"flex flex-col flex-wrap",attrs:{"label":"First name","value":_vm.form.firstName,"type":_vm.type,"errors":errors,"print":_vm.print,"id":_vm.id,"validation":_vm.textRules.split('|')}})]}}],null,false,2239622518)})],1),_c('div',{staticClass:"mb-4"},[(_vm.showLabel && !_vm.readOnly)?_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":("input_" + _vm.id + "_lastName")}},[_vm._v("Last Name")]),(_vm.validation.includes('required'))?_c('span',{staticClass:"text-required"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.textRules,"name":(_vm.id + "-" + _vm.namePrefix + "Last Name"),"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [(!_vm.readOnly)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"},{name:"debounce",rawName:"v-debounce:300",value:(_vm.update),expression:"update",arg:"300"}],class:{ error: errors.length && !changed },attrs:{"type":"text","name":("input_" + _vm.id + "_lastName"),"id":("input_" + _vm.id + "_lastName")},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),(errors.length)?_c('field-error',{attrs:{"errors":errors,"id":_vm.id}}):_vm._e()]:_c('read-only-string',{staticClass:"flex flex-col flex-wrap",attrs:{"label":"Last name","value":_vm.form.lastName,"type":_vm.type,"errors":errors,"id":_vm.id,"validation":_vm.textRules.split('|')}})]}}],null,false,1162098720)})],1),_c('div',{staticClass:"mb-4"},[(_vm.showLabel && !_vm.readOnly)?_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":("input_" + _vm.id + "_position")}},[_vm._v("Position")]),(_vm.validation.includes('required'))?_c('span',{staticClass:"text-required"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.textRules,"name":(_vm.id + "-" + _vm.namePrefix + "Position"),"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [(!_vm.readOnly)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.position),expression:"form.position"},{name:"debounce",rawName:"v-debounce:300",value:(_vm.update),expression:"update",arg:"300"}],class:{ error: errors.length && !changed },attrs:{"type":"text","name":("input_" + _vm.id + "_position"),"id":("input_" + _vm.id + "_position")},domProps:{"value":(_vm.form.position)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "position", $event.target.value)}}}),(errors.length)?_c('field-error',{attrs:{"errors":errors,"id":_vm.id}}):_vm._e()]:_c('read-only-string',{staticClass:"flex flex-col flex-wrap",attrs:{"label":"Position","value":_vm.form.position,"type":_vm.type,"errors":errors,"id":_vm.id,"validation":_vm.textRules.split('|')}})]}}],null,false,3161908832)})],1),_c('div',{staticClass:"mb-4"},[(_vm.showLabel && !_vm.readOnly)?_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":("input_" + _vm.id + "_email")}},[_vm._v("Email")]),(_vm.validation.includes('required'))?_c('span',{staticClass:"text-required"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.emailRules,"name":(_vm.id + "-" + _vm.namePrefix + "Email"),"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [(!_vm.readOnly)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"debounce",rawName:"v-debounce:300",value:(_vm.update),expression:"update",arg:"300"}],class:{ error: errors.length && !changed },attrs:{"type":"text","name":("input_" + _vm.id + "_email"),"id":("input_" + _vm.id + "_email")},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),(errors.length)?_c('field-error',{attrs:{"errors":errors,"id":_vm.id}}):_vm._e()]:_c('read-only-string',{staticClass:"flex flex-col flex-wrap",attrs:{"label":"Email","value":_vm.form.email,"type":_vm.type,"errors":errors,"id":_vm.id,"validation":_vm.emailRules.split('|')}})]}}],null,false,1072722129)})],1),_c('div',{staticClass:"mb-4"},[(_vm.showLabel && !_vm.readOnly)?_c('div',{staticClass:"mb-2"},[_c('label',{attrs:{"for":("input_" + _vm.id + "_primaryContactNumber")}},[_vm._v("Primary Contact Number")]),(_vm.validation.includes('required'))?_c('span',{staticClass:"text-required"},[_vm._v(" *")]):_vm._e()]):_vm._e(),_c('validation-provider',{attrs:{"mode":"eager","rules":_vm.phoneRules,"name":(_vm.id + "-" + _vm.namePrefix + "Primary Contact Number"),"immediate":_vm.immediate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var changed = ref.changed;
return [(!_vm.readOnly)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.primaryContactNumber),expression:"form.primaryContactNumber"},{name:"debounce",rawName:"v-debounce:300",value:(_vm.update),expression:"update",arg:"300"}],class:{ error: errors.length && !changed },attrs:{"type":"tel","name":("input_" + _vm.id + "_primaryContactNumber"),"id":("input_" + _vm.id + "_primaryContactNumber")},domProps:{"value":(_vm.form.primaryContactNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "primaryContactNumber", $event.target.value)}}}),(errors.length)?_c('field-error',{attrs:{"errors":errors,"id":_vm.id}}):_vm._e()]:_c('read-only-string',{staticClass:"flex flex-col flex-wrap",attrs:{"label":"Primary Contact Number","value":_vm.form.primaryContactNumber,"type":_vm.type,"errors":errors,"id":_vm.id,"validation":_vm.phoneRules.split('|')}})]}}],null,false,456080545)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }