export default {
  id: {
    type: String,
    default: "",
  },
  sectionId: {
    type: String,
    default: "",
  },
  namePrefix: {
    type: String,
    default: "",
  },
  name: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  questiontype: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  format: {
    type: String,
    default: "",
  },
  reusable: {
    type: String,
    default: "",
  },
  value: {
    type: [String, Object, Boolean, Array, Date],
    default: "",
  },
  help: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "",
  },
  errors: {
    type: [String, Array, Object],
    default: () => [],
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  validation: {
    type: Array,
    default: () => [],
  },
  attachments: {
    type: Array,
    default: () => [],
  },
  response: {
    type: Object,
    default: () => ({}),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  updatesForm: {
    type: Boolean,
    default: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  immediate: {
    type: Boolean,
    default: false,
  },
  status: {
    type: Boolean,
    default: true,
  },
  print: {
    type: Boolean,
    default: false,
  },
  max: {
    type: Number,
    default: -1,
  },
  conditional_statements: {
    default: "",
  },
  conditional_id: {
    type: String,
    default: "",
  },
}
