<template>
  <div>
    <a @click.prevent="show()" class="block p-1 px-2 text-white no-underline cursor-pointer w-max bg-primary-dark rounded-b-md">
      Reuse response to similar question
    </a>
    <modal v-if="open" @close="cancel()" :fullscreen="false">
      <template v-if="loading" #content>
        <loader></loader>
      </template>
      <template v-else #content>
        <div class="overflow-y-auto max-w-xl max-h-[40rem]" v-if="results.length > 0">
          <div v-for="(info, ind) in results" :key="ind" class="p-3 even:bg-gray-200 overscroll-y-auto rounded-xl">
            <div>
              <p><span class="font-bold text-primary-light">Tender:</span> {{ info.tender }}</p>
              <p><span class="font-bold text-primary-light">Section:</span> {{ info.section }}</p>
              <p><span class="font-bold text-primary-light">Question:</span> {{ info.question }}</p>
              <div v-if="info.description">
                <div class="font-bold text-primary-light">Description:</div>
                <div v-dompurify-html="info.description"></div>
              </div>
              <hr class="my-1" />
              <div v-if="info.answer" class=" whitespace-pre-line" v-text="info.answer"></div>
            </div>
            <div class="mt-2 mb-2">
              <button class="inline-flex button button--filled" @click.prevent="reuse(info.answer)">Reuse response</button>
            </div>
          </div>
        </div>
        <div v-else>
          <h2>No results</h2>
          <p>You currently have no saved responses that can be reused with this question.</p>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Loader from "@/components/Loader"
import Modal from "@/components/Modal"
import api from "@/store/api/reusable"
export default {
  props: {
    reusableId: {
      type: String,
      default: "",
    },
  },
  components: {
    Loader,
    Modal,
  },
  data: () => ({
    open: false,
    loading: false,
    results: [],
    apiErrors: [],
  }),
  methods: {
    show() {
      this.open = true
      this.load()
    },
    cancel() {
      this.open = false
    },
    load() {
      this.loading = true
      this.apiErrors = []

      api
        .reusable(this.reusableId)
        .then((response) => {
          this.results = response.data
        })
        .catch(() => {
          this.apiErrors.push(`Error loading reusable questions`)
        })
        .finally(() => {
          this.loading = false
        })
    },
    reuse(answer) {
      this.$emit("reuse", answer)
      this.cancel()
    },
  },
}
</script>
