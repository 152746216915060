<template>
  <div class="form-input contact-input" v-if="conditionsMet">
    <div v-if="showLabel">
      <strong class="print-label" v-text="label"></strong>
    </div>
    <field-meta v-if="help && showLabel" :help="help"></field-meta>
    <div class="mb-4">
      <div class="mb-2" v-if="showLabel && !readOnly">
        <label :for="`input_${id}_firstName`">First Name</label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <validation-provider
        mode="eager"
        :rules="textRules"
        v-slot="{ errors, changed }"
        :name="`${id}-${namePrefix}First Name`"
        :immediate="immediate"
      >
        <template v-if="!readOnly">
          <input
            type="text"
            :name="`input_${id}_firstName`"
            :id="`input_${id}_firstName`"
            v-model="form.firstName"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          />
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          label="First name"
          :value="form.firstName"
          :type="type"
          :errors="errors"
          :print="print"
          :id="id"
          :validation="textRules.split('|')"
        ></read-only-string>
      </validation-provider>
    </div>

    <div class="mb-4">
      <div class="mb-2" v-if="showLabel && !readOnly">
        <label :for="`input_${id}_lastName`">Last Name</label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <validation-provider mode="eager" :rules="textRules" v-slot="{ errors, changed }" :name="`${id}-${namePrefix}Last Name`" :immediate="immediate">
        <template v-if="!readOnly">
          <input
            type="text"
            :name="`input_${id}_lastName`"
            :id="`input_${id}_lastName`"
            v-model="form.lastName"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          />
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          label="Last name"
          :value="form.lastName"
          :type="type"
          :errors="errors"
          :id="id"
          :validation="textRules.split('|')"
        ></read-only-string>
      </validation-provider>
    </div>

    <div class="mb-4">
      <div class="mb-2" v-if="showLabel && !readOnly">
        <label :for="`input_${id}_position`">Position</label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <validation-provider mode="eager" :rules="textRules" v-slot="{ errors, changed }" :name="`${id}-${namePrefix}Position`" :immediate="immediate">
        <template v-if="!readOnly">
          <input
            type="text"
            :name="`input_${id}_position`"
            :id="`input_${id}_position`"
            v-model="form.position"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          />
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          label="Position"
          :value="form.position"
          :type="type"
          :errors="errors"
          :id="id"
          :validation="textRules.split('|')"
        ></read-only-string>
      </validation-provider>
    </div>

    <div class="mb-4">
      <div class="mb-2" v-if="showLabel && !readOnly">
        <label :for="`input_${id}_email`">Email</label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <validation-provider mode="eager" :rules="emailRules" v-slot="{ errors, changed }" :name="`${id}-${namePrefix}Email`" :immediate="immediate">
        <template v-if="!readOnly">
          <input
            type="text"
            :name="`input_${id}_email`"
            :id="`input_${id}_email`"
            v-model="form.email"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          />
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          label="Email"
          :value="form.email"
          :type="type"
          :errors="errors"
          :id="id"
          :validation="emailRules.split('|')"
        ></read-only-string>
      </validation-provider>
    </div>

    <div class="mb-4">
      <div class="mb-2" v-if="showLabel && !readOnly">
        <label :for="`input_${id}_primaryContactNumber`">Primary Contact Number</label>
        <span class="text-required" v-if="validation.includes('required')"> *</span>
      </div>
      <validation-provider
        mode="eager"
        :rules="phoneRules"
        v-slot="{ errors, changed }"
        :name="`${id}-${namePrefix}Primary Contact Number`"
        :immediate="immediate"
      >
        <template v-if="!readOnly">
          <input
            type="tel"
            :name="`input_${id}_primaryContactNumber`"
            :id="`input_${id}_primaryContactNumber`"
            v-model="form.primaryContactNumber"
            :class="{ error: errors.length && !changed }"
            v-debounce:300="update"
          />
          <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
        </template>
        <read-only-string
          v-else
          class="flex flex-col flex-wrap"
          label="Primary Contact Number"
          :value="form.primaryContactNumber"
          :type="type"
          :errors="errors"
          :id="id"
          :validation="phoneRules.split('|')"
        ></read-only-string>
      </validation-provider>
    </div>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
const structure = {
  firstName: "",
  lastName: "",
  position: "",
  email: "",
  primaryContactNumber: "",
}

export default {
  name: "ContactInput",
  components: { ReadOnlyString, FieldError },
  mixins: [InputMixin, ConditionalMixin],
  data() {
    return {
      form: { ...structure },
    }
  },
  computed: {
    phoneRules() {
      return (this.validation.includes("required") ? "required|" : "") + "max:10|numeric"
    },
    textRules() {
      return (this.validation.includes("required") ? "required|" : "") + "max:100"
    },
    emailRules() {
      return (this.validation.includes("required") ? "required|" : "") + "email"
    },
  },
  methods: {
    load() {
      const parsed = jsonParse(this.value, {
        value: { ...structure },
      })

      this.form = parsed?.value || { ...structure }
    },
    updated() {
      this.input()
      this.update()
    },
    update() {
      if (this.updatesForm) {
        this.$emit(
          "updated",
          {
            type: "contact",
            value: this.form,
          },
          {
            id: this?.response?.id ?? "",
            question_id: this.id,
            type: this.type,
            name: this.name,
            files: this.files,
          },
        )
      } else {
        this.$emit("changed", {
          value: {
            type: "contact",
            value: this.form,
          },
          // id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.label,
          files: this.files,
        })
      }
    },
  },
}
</script>
