export default {
  sections: [
    {
      id: "674a0a46-2c08-476d-b534-b08bea52054b",
      title: "Overview",
      name: "overview",
      description: "Bla bla bla",
      order: 1,
      complete: true,
      fields: [
        {
          id: "45659da1-41b3-4179-952d-9cd8654fb208",
          type: "content",
          name: "content",
          label: "content",
          value: require("./html/overview"),
          order: 1,
          help: "Some help text under the label or the field",
        },
      ],
    },
    {
      id: "ec4fb118-becd-483f-b5a7-12955dfe2bc0",
      title: "Section 1 some really long name that pains me to type",
      name: "section_1",
      complete: false,
      fields: [
        {
          id: "70283a0a-263f-4bc2-8821-8e877e93663f",
          type: "abn",
          name: "abn",
          label: "ABN",
          placeholder: "ABN",
          validation: ["required", "abn"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: true,
        },
        {
          id: "cbf5e8e8-a8b3-4963-b3eb-7a57f37d7b69",
          type: "table",
          name: "table",
          format: "horizontal",
          label: "Horizontal Table Question",
          validation: ["required"],
          max: 10,
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "organisation_name",
          complete: true,
          fields: [
            {
              id: "fe300b32-f9f6-46f6-9d31-66da5296420d",
              type: "date",
              name: "organisation_name",
              label: "Organisation Name",
              help: "Trading name",
              placeholder: "Name",
              order: 1,
              validation: ["required", "max:300"],
              complete: true,
            },
            {
              id: "6d0a1402-d526-4e93-9eca-5964b8102d62",
              type: "dropdown",
              name: "organisation_type",
              label: "Organisation Type",
              items: ["Trust", "Sole trader", "Company", "Partnership"],
              help: "Type of organisation",
              order: 2,
              validation: ["required"],
              complete: true,
            },
            {
              id: "5cf075c7-673d-45a0-823c-88767a123e80",
              type: "email",
              name: "organisation_email",
              label: "Organisation Email",
              placeholder: "organisation@email.com",
              order: 3,
              validation: ["required", "min:3", "email"],
              complete: true,
            },
            {
              id: "ca266400-33da-45a4-9934-33cd64fb02dd",
              type: "yes_no",
              name: "organisation_gst",
              label: "GST Registered",
              order: 4,
              validation: [],
              complete: true,
            },
            {
              id: "fe300b32-f9f6-46f6-9d31-66da1296420d",
              type: "currency",
              name: "annual_income",
              label: "Annual Income",
              placeholder: "500000",
              order: 5,
              validation: ["required", "numeric"],
              complete: true,
            },
          ],
        },
      ],
    },
    {
      id: "640b5acd-b8ef-4500-afa2-372aa255be49",
      title: "Section 2",
      name: "section_2",
      complete: true,
      fields: [
        {
          id: "cf8888e7-740a-4cf3-86de-2332812801c7",
          type: "table",
          name: "table",
          format: "vertical",
          label: "Vertical Table Question",
          validation: ["required"],
          max: 10,
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "organisation_name",
          complete: true,
          fields: [
            {
              id: "b2b6125c-a51f-4cd6-80df-a8cba053a407",
              type: "text",
              name: "organisation_name",
              label: "Organisation Name",
              help: "Trading name",
              placeholder: "Name",
              order: 1,
              validation: ["required", "max:300"],
              complete: true,
            },
            {
              id: "4189bb65-a5fe-4cf6-b223-8861ebb57082",
              type: "dropdown",
              name: "organisation_type",
              label: "Organisation Type",
              items: ["Trust", "Sole trader", "Company", "Partnership"],
              help: "Type of organisation",
              order: 2,
              validation: ["required"],
              complete: true,
            },
            {
              id: "afaaad9e-574c-4271-819a-1aef7c99f167",
              type: "email",
              name: "organisation_email",
              label: "Organisation Email",
              placeholder: "organisation@email.com",
              order: 3,
              validation: ["required", "min:3", "email"],
              complete: true,
            },
            {
              id: "8a3651e6-dbb1-485d-908d-a1b603eabee4",
              type: "yes_no",
              name: "organisation_gst",
              label: "GST Registered",
              order: 4,
              validation: [],
              complete: true,
            },
            {
              id: "0be79c24-4bc5-4760-81ef-d5b3f101ffa2",
              type: "currency",
              name: "annual_income",
              label: "Annual Income",
              placeholder: "500000",
              order: 5,
              validation: ["required", "numeric"],
              complete: true,
            },
            {
              id: "e3fcc18a-008a-11ec-9a03-0242ac130003",
              type: "daterange",
              name: "dateRange",
              label: "Date Range",
              order: 6,
              validation: [],
            },
            {
              id: "9954ba03-2d69-4c34-994e-e8a872d26a37",
              type: "timerange",
              name: "timeRange",
              label: "Time Range",
              order: 6,
              validation: [],
            },
            {
              id: "661b1931-023b-4488-beaa-f95f4f141bd6",
              type: "checkbox",
              name: "check box",
              label: "checkbox",
              order: 7,
              validation: [],
              items: ["Option 1", "Option 2", "Option 3"],
            },
            {
              id: "9149ee3d-2ee3-4a33-9c49-fa596f72dc8b",
              type: "radiobutton",
              name: "Radio Select",
              label: "radio",
              order: 8,
              validation: [],
              items: ["Option 1", "Option 2", "Option 3"],
            },
          ],
        },
      ],
    },
    {
      id: "ec4fb118-becd-483f-b5a7-12952dfe2bc0",
      title: "Section 3",
      name: "section_3",
      complete: false,
      fields: [
        {
          id: "70283a0a-263f-4bc2-7821-8e877e93663f",
          type: "multi_linetext",
          name: "feedback",
          label: "Feedback",
          placeholder: "Some comments",
          validation: ["required"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: true,
        },
        {
          id: "32f515d4-e31c-4e85-91e9-441d72d2e2d2",
          type: "text",
          name: "test_text",
          label: "A text test field",
          help: "A helpful hint",
          placeholder: "Testing 123",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
        {
          id: "9149ee3d-2ee3-4a33-9c49-fa596f72dc8b",
          type: "attachment",
          name: "attachment input",
          label: "attachment field",
          uploadText: "Upload Fincancial Viability form",
          help: "A helpful hint",
          placeholder: "Testing 123",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
      ],
    },
    {
      id: "ff10d6d0-c10f-1108-ec36-bdea2f2a9ce4",
      title: "Section 4",
      name: "section_4",
      complete: false,
      fields: [
        {
          id: "77360f13-6bd3-5da7-3035-a06e48e0b456",
          type: "multi_linetext",
          name: "comments",
          label: "Other Comments",
          placeholder: "Some comments",
          validation: ["required"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: false,
        },
        {
          id: "8a3651e6-dff1-485a-908d-a1b912eabee4",
          type: "yes_no",
          name: "test_yes_no",
          label: "Is this a test",
          order: 4,
          validation: [],
          complete: false,
        },
        {
          id: "b12f136f-9bcd-4072-93d4-8d44c8e2b1b8",
          type: "timerange",
          name: "timeRange",
          label: "Time Range",
          order: 6,
          validation: [],
        },
        {
          id: "43ec568c-81ea-4741-a251-09f871264153",
          type: "time",
          name: "Time",
          label: "Time",
          order: 6,
          validation: [],
        },
      ],
    },
    {
      id: "9d119f3c-8372-4ded-84df-098cb9f46e68",
      title: "Section 5",
      name: "section_5",
      complete: false,
      fields: [
        {
          id: "d1ed1716-1d3a-4656-9c89-30e8c6155c35",
          type: "multi_linetext",
          name: "comments",
          label: "Other Comments",
          placeholder: "Some comments",
          validation: ["required"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: false,
        },
        {
          id: "8e41ba42-ff68-4a11-b073-c4f63077c7a6",
          type: "yes_no",
          name: "test_yes_no",
          label: "Is this a test",
          order: 4,
          validation: [],
          complete: false,
        },
        {
          id: "32f515d5-e31c-4e85-91e9-441d72d2e2d2",
          type: "text",
          name: "test_text",
          label: "A text test field",
          help: "A helpful hint",
          placeholder: "Testing 123",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
        {
          id: "fe300b32-f9f6-46f6-9d31-66da5296420d",
          type: "date",
          name: "date_tst",
          label: "Date Test",
          help: "Date test help",
          placeholder: "Name",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
      ],
    },
    {
      id: "e6f202c2-3b88-419a-83a0-b00bdbdc4994",
      title: "Section 6",
      name: "section_6",
      complete: false,
      fields: [
        {
          id: "aa0e3d09-9dec-475c-b53c-8bbed81b7592",
          type: "yes_no",
          name: "test_yes_no",
          label: "Is this a test",
          order: 1,
          validation: [],
          complete: false,
        },
        {
          id: "d8915956-cc28-457e-b44b-34b7cdc646c6",
          type: "text",
          name: "test_text",
          label: "A text test field",
          help: "A helpful hint",
          placeholder: "Testing 123",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
        {
          id: "d3b2accc-53be-4ffb-a0ea-fd268c53ae15",
          type: "date",
          name: "date_tst",
          label: "Date Test",
          help: "Date test help",
          placeholder: "Name",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
        {
          id: "336b7e38-ab15-4b49-98fc-8fe3ff2d009a",
          type: "multi_linetext",
          name: "comments",
          label: "Other Comments",
          placeholder: "Some comments",
          validation: ["required", "max:100"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: false,
        },
        {
          id: "1a8672f9-829e-4f94-81ba-b530028e7776",
          type: "abn",
          name: "abn",
          label: "ABN",
          placeholder: "ABN",
          validation: ["required", "abn"],
          order: 1,
          help: "Some help text under the label or the field",
          complete: true,
        },
        {
          id: "24224eb0-3938-4c41-9541-4d730d11aecf",
          type: "attachment",
          name: "attachment input",
          label: "attachment field",
          uploadText: "Upload Fincancial Viability form",
          help: "A helpful hint",
          placeholder: "Testing 123",
          order: 1,
          validation: ["required", "max:300"],
          complete: false,
        },
        {
          id: "c8a6b512-5c91-4123-bbaf-6e5640bdcf66",
          type: "currency",
          name: "annual_income",
          label: "Annual Income",
          placeholder: "500000",
          order: 5,
          validation: ["required", "numeric"],
          complete: true,
        },
        {
          id: "07e71fd5-27af-4ba2-baf5-f0ca0c69b462",
          type: "daterange",
          name: "dateRange",
          label: "Date Range",
          order: 6,
          validation: [],
        },
        {
          id: "d727843c-c055-4ed3-b614-dccd351c1051",
          type: "timerange",
          name: "timeRange",
          label: "Time Range",
          order: 6,
          validation: [],
        },
        {
          id: "1c1ee7c5-ecb0-4c45-8e91-9a05fb8d72a9",
          type: "checkbox",
          name: "check box",
          label: "checkbox",
          order: 7,
          validation: [],
          items: ["Option 1", "Option 2", "Option 3"],
        },
        {
          id: "4883582a-3c64-4f46-9250-2878438998b1",
          type: "radiobutton",
          name: "Radio Select",
          label: "radio",
          order: 8,
          validation: [],
          items: ["Option 1", "Option 2", "Option 3"],
        },
        {
          id: "e9639863-2308-490e-853e-77c0adb87637",
          type: "dropdown",
          name: "organisation_type",
          label: "Organisation Type",
          help: "Type of organisation",
          order: 2,
          validation: ["required"],
          complete: true,
          items: ["Trust", "Sole trader", "Company", "Partnership"],
        },
        {
          id: "c37f135f-f940-4e97-bf84-6e5fc00ffdc2",
          type: "email",
          name: "organisation_email",
          label: "Organisation Email",
          placeholder: "organisation@email.com",
          order: 3,
          validation: ["required", "min:3", "email"],
          complete: true,
        },
        {
          id: "99aed64a-e77e-4279-92c0-634b770626df",
          type: "content",
          name: "content",
          label: "content",
          value: require("./html/overview"),
          order: 1,
          help: "Some help text under the label or the field",
        },
        {
          id: "cbf5e8e8-a8b3-4963-b3eb-7a57f37d7b69",
          type: "table",
          name: "table",
          format: "horizontal",
          label: "Horizontal Table Question",
          validation: ["required"],
          max: 10,
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "organisation_name",
          complete: true,
          fields: [
            {
              id: "5dcd8667-5b84-4bdc-9cd9-d7516337c835",
              type: "multi_linetext",
              name: "comments",
              label: "Other Comments",
              placeholder: "Some comments",
              validation: ["required", "max:12000"],
              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
            {
              id: "91a8fb31-a23b-4c39-93c9-39701780710b",
              type: "multi_linetext",
              name: "comments",
              label: "Other Comments",
              placeholder: "Some comments",
              validation: ["required", "max:12000"],
              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
          ],
        },
        {
          id: "cbf5e8e8-a8b3-4963-b3eb-7a57f37d7b61",
          type: "table",
          name: "table",
          format: "horizontal",
          label: "Horizontal Table Question",
          validation: ["required"],
          max: 10,
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "organisation_name",
          complete: true,
          fields: [
            {
              id: "5dcd8667-5b84-4bdc-9cd9-d7516337c830",
              type: "multi_linetext",
              name: "comments",
              label: "Other Comments",
              placeholder: "Some comments",
              validation: ["required", "max:12000"],
              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
            {
              id: "91a8fb31-a23b-4c39-93c9-39701780710f",
              type: "multi_linetext",
              name: "comments",
              label: "Other Comments",
              placeholder: "Some comments",
              validation: ["required", "max:12000"],
              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
            {
              id: "c37f135f-f940-4e97-bf84-6e5fc00ffdc6",
              type: "email",
              name: "organisation_email",
              label: "Organisation Email",
              placeholder: "organisation@email.com",
              order: 3,
              validation: ["required", "min:3", "email"],
              complete: true,
            },
          ],
        },
        {
          id: "cbf5e8e8-a8b3-4963-b3eb-7a57f37d7b64",
          type: "table",
          name: "table",
          format: "horizontal",
          label: "Horizontal Table Question",
          validation: ["required"],
          max: 10,
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "organisation_name",
          complete: true,
          fields: [
            {
              id: "5dcd8667-5b84-4bdc-9cd9-d7516337c83d",
              type: "multi_linetext",
              name: "comments",
              label: "Other Comments",
              placeholder: "Some comments",
              validation: ["required", "max:12000"],
              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
          ],
        },
      ],
    },
    {
      id: "37d1010a-5e28-4c21-82fd-1a6a7305e820",
      title: "Section 7",
      name: "section_7",
      complete: false,
      fields: [
        {
          id: "b8f44be8-774a-47ef-b696-65c44bc36b49",
          type: "table",
          name: "table",
          format: "vertical",
          label: "Vertical Table Question",
          validation: ["required"],
          help: "Some help text under the label or the table",
          order: 2,
          row_label: "address",
          complete: true,
          fields: [
            {
              id: "d1ed1716-1d3a-4656-9c89-30e8c6155c40",
              type: "address",
              name: "address",
              label: "address",
              placeholder: "Some comments",
              validation: ["required"],

              order: 1,
              help: "Some help text under the label or the field",
              complete: false,
            },
            /*  {
               id: "0abaa6f3-fd08-4a86-a0aa-7d7c00886591",
               type: "text",
               name: "addressline1",
               label: "address line 1",
               help: "line 1",
               placeholder: "address",
               order: 1,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "c8696f77-cf5d-46df-8173-d82afded9830",
               type: "text",
               name: "addressline1",
               label: "address line 2",
               help: "line 1",
               placeholder: "address",
               order: 2,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "c072eecc-5366-433c-b282-4ea45a264b65",
               type: "text",
               name: "addressline1",
               label: "address line 3",
               help: "line 1",
               placeholder: "address",
               order: 1,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "b573d32a-5fef-4ee4-8681-0249fbcaae6c",
               type: "text",
               name: "Suburb",
               label: "Suburb",
               help: "Suburb",
               placeholder: "Suburb",
               order: 3,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "bf5d67cf-6252-4134-848f-f4ea84d5708f",
               type: "dropdown",
               name: "state",
               label: "state",
               items: ["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"],
               help: "Type of organisation",
               order: 4,
               validation: ["required"],
               complete: true,
             },
             {
               id: "4470dcad-ccdc-42ef-8a64-eea647d4dd87",
               type: "text",
               name: "Post Code",
               label: "Post Code",
               help: "Post Code",
               placeholder: "Post Code",
               order: 5,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "09d26532-7572-4170-960f-fdab376abafb",
               type: "text",
               name: "Hours of Service",
               label: "Hours of Service",
               help: "Hours of Service",
               placeholder: "Hours of Service",
               order: 6,
               validation: ["required", "max:300"],
               complete: true,
             },
             {
               id: "e4cf06e7-de0c-4975-8ea4-64050ceb09ff",
               type: "dropdown",
               name: "Types of Service Delivery",
               label: "Types of Service Delivery",
               items: ["Full Time", "Part Time", "Outreach"],
               help: "Types of Service Delivery",
               order: 7,
               validation: ["required"],
               complete: true,
             },
             {
               id: "774cb524-945d-42c0-b4d9-e2664a42afcd",
               type: "dropdown",
               name: "Address subtype",
               label: "Address subtype",
               items: ["building", "house"],
               help: "Address subtype",
               order: 8,
               validation: ["required"],
               complete: true,
             }, */
          ],
        },
      ],
    },
  ],
}
