import { fieldComponents as baseFieldComponents } from "@/components/form/helpers/fieldTypes"
import HorizontalTableInput from "@/components/form/HorizontalTableInput"
import VerticalTableInput from "@/components/form/VerticalTableInput"

const fieldComponents = {
  ...baseFieldComponents,
  table_vertical: VerticalTableInput,
  table_horizontal: HorizontalTableInput,
}

const getComponent = (field) => {
  let type = field.type
  if (type === "table") {
    type = `${type}_${field.format}`
  }

  return fieldComponents.hasOwnProperty(type) ? fieldComponents[type] : false
}

export default {
  computed: {
    builtComponentSections() {
      return this.formSections.map((formSection) => {
        if (!formSection.fields) {
          return formSection
        }

        let responseSectionIndex = -1
        if (this.response.sections) {
          responseSectionIndex = this.response.sections.findIndex((section) => section.id === formSection.id)
        }

        let fields = formSection.fields.map((field) => {
          let response = false

          if (responseSectionIndex > -1) {
            response = this.response.sections[responseSectionIndex].fields.find((f) => f.question_id === field.id)
          }

          return {
            ...field,
            sectionId: formSection.id,
            component: getComponent(field),
            response: response ? response : { value: "" },
          }
        })

        return { ...formSection, fields }
      })
    },
  },
}
