<template>
  <actions class="flex justify-center" :drop-position="dropPosition" v-if="actionOptions">
    <ul>
      <li v-for="(action, ind) in actionOptions" :key="ind">
        <a @click.prevent="invokeMethod(action.method)" class="cursor-pointer" v-text="action.text"></a>
      </li>
    </ul>
  </actions>
</template>
<script>
import Actions from "@/components/Actions"
export default {
  components: { Actions },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    dropPosition: {
      type: String,
      default: "center",
    },
  },
  computed: {
    actionOptions() {
      if (["ready for conformance", "submitted", "ready for assessment"].includes(this.item.status)) {
        return this.item.tender.status === "open"
          ? [
              { text: "Unsubmit/edit submission", method: "unsubmit" },
              { text: "Review full response", method: "review" },
            ]
          : [
              { text: "Withdraw submission", method: "withdraw" },
              { text: "Review full response", method: "review" },
            ]
      } else if (this.item.status === "draft") {
        return this.item.tender.status === "open"
          ? [
              { text: "Continue response", method: "continue" },
              { text: "Review full response", method: "review" },
              { text: "Delete response", method: "delete" },
            ]
          : [
              { text: "See full response", method: "review" },
              { text: "Delete response", method: "delete" },
            ]
      } else if (this.item.status === "not submitted") {
        return [
          { text: "See full response", method: "review" },
          { text: "Delete response", method: "delete" },
        ]
      }
      return false
    },
  },
  methods: {
    invokeMethod(method, row) {
      this[method](row)
    },
    delete() {
      this.$router.push({ name: "confirm-tender", params: { action: "delete", id: this.item.id } })
    },
    continue() {
      this.$router.push({ name: "response", params: { id: this.item.id } })
    },
    review() {
      this.$router.push({ name: "section-review", params: { id: this.item.id } })
    },
    withdraw() {
      this.$router.push({ name: "withdraw-tender" })
    },
    unsubmit() {
      this.$router.push({ name: "confirm-tender", params: { action: "unsubmit", id: this.item.id } })
    },
  },
}
</script>
