var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.conditionsMet)?_c('li',{staticClass:"relative w-full"},[(_vm.sectionInd !== 0)?_c('div',{staticClass:"absolute w-6 h-full min-h-80 left-9 transform -translate-x-1/2 bottom-0",class:[_vm.inCurrentTrail ? 'bg-primary-light' : 'bg-gray-300', _vm.sectionSelected ? 'bg-primary-light' : '']}):_vm._e(),(_vm.isEnabled)?_c('router-link',{staticClass:"flex items-center pl-4 mb-10 no-underline rounded-lg",class:{ 'bg-primary-highlight': _vm.sectionInd === _vm.currentSectionIndex },attrs:{"to":{ name: _vm.inReview, params: { id: _vm.$route.params.id, section: _vm.section.id } }}},[_c('div',{staticClass:"relative z-10 flex items-center"},[(_vm.isEnabled)?_c('div',{staticClass:"relative flex items-center justify-center w-10 h-10 border-2 rounded-full",class:{
          'bg-dark-blue border-primary-light border-4': _vm.complete,
          'bg-primary-light border-4 border-primary-light': (_vm.isEnabled && _vm.inCurrentTrail) || _vm.sectionSelected || _vm.read,
          'bg-white border-2 border-gray-500': !_vm.complete && !((_vm.isEnabled && _vm.inCurrentTrail) || _vm.sectionSelected || _vm.read),
        }},[(_vm.sectionInd !== 0)?_c('svg',{staticClass:"absolute -top-1/2 -z-1 fill-current",class:[_vm.inCurrentTrail ? 'text-primary-light' : _vm.sectionSelected ? 'text-primary-light' : 'text-gray-300'],attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 52.828 27.779 27.876"}},[_c('path',{attrs:{"d":"M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"}})]):_vm._e(),_c('svg',{staticClass:"absolute -bottom-1/2 -z-1 fill-current",class:[
            _vm.inCurrentTrail && _vm.sectionInd !== _vm.currentSectionIndex ? 'text-primary-light' : _vm.sectionSelected ? 'text-primary-light' : 'text-gray-300' ],attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 27.779 27.876"}},[_c('path',{attrs:{"d":"M1.008 8.942C-1.587 5.149 1.129 0 5.725 0h16.329c4.596 0 7.312 5.149 4.717 8.942-.254.371-.55.71-.882 1.011l-3.418 3.092c-4.191 3.791-6.582 9.179-6.582 14.831h-4c0-5.652-2.391-11.04-6.581-14.831L1.89 9.953c-.332-.301-.629-.64-.882-1.011Z"}})]),(_vm.complete)?_c('img',{staticClass:"w-1/2",attrs:{"src":require("@/assets/images/tick.svg"),"alt":"Tick"}}):(!_vm.complete)?_c('div',{staticClass:"text-white"}):_vm._e()]):_c('div',{staticClass:"flex items-center justify-center w-10 h-10 bg-white border-2 border-gray-300 rounded-full"},[_c('svg',{staticClass:"absolute -bottom-1/2 -z-1 fill-current text-gray-300",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 27.779 27.876"}},[_c('path',{attrs:{"d":"M1.008 8.942C-1.587 5.149 1.129 0 5.725 0h16.329c4.596 0 7.312 5.149 4.717 8.942-.254.371-.55.71-.882 1.011l-3.418 3.092c-4.191 3.791-6.582 9.179-6.582 14.831h-4c0-5.652-2.391-11.04-6.581-14.831L1.89 9.953c-.332-.301-.629-.64-.882-1.011Z"}})]),_c('svg',{staticClass:"absolute -top-1/2 -z-1 fill-current text-gray-300",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 52.828 27.779 27.876"}},[_c('path',{attrs:{"d":"M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"}})])])]),_c('div',{staticClass:"block w-full"},[(_vm.section)?_c('span',{staticClass:"relative block w-full px-2 py-5 ml-2 text-black no-underline rounded-r-md before:absolute before:w-14 before:h-full before:-left-14 before:top-0 before:rounded-l-md before:bg-opacity-20 bg-opacity-20 before:-z-1",class:[
          _vm.sectionSelected
            ? 'bg-transparent'
            : _vm.isEnabled && _vm.currentSectionIndex === _vm.section.id
            ? 'bg-primary-extralight before:bg-primary-extralight'
            : '' ],domProps:{"textContent":_vm._s(_vm.section.title)}}):_vm._e()])]):_c('div',{staticClass:"flex items-center pl-4 mb-10"},[_c('div',[_c('div',{staticClass:"relative z-50 flex items-center justify-center w-10 h-10 bg-white border-2 border-gray-300 rounded-full"})]),(_vm.section)?_c('span',{staticClass:"relative block w-full px-2 py-5 ml-2 text-gray-400 no-underline rounded-md"},[_vm._v(_vm._s(_vm.section.title))]):_vm._e()]),_c('div',{staticClass:"absolute w-6 h-full min-h-90 left-9 transform -translate-x-1/2 top-1/2",class:[_vm.inCurrentTrail && _vm.sectionInd !== _vm.currentSectionIndex ? 'bg-primary-light' : 'bg-gray-300', _vm.sectionSelected ? 'bg-primary-light' : '']})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }