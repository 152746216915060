import { jsonParse } from "@/components/form/helpers/fieldParser"
import api from "@/store/api"
import { isString } from "lodash"

// state
const state = () => ({
  item: {},
  dirty: false,
  sections: [],
  pagination: {
    per_page: 10,
  },
  attachmentList: [],
  submitAttempted: false,
  completeSections: [],
})

// getters
const getters = {
  get: (state) => {
    return state.item
  },
  sections: (state) => {
    return state.sections
  },
  section: (state) => (id) => {
    return state.sections.find((s) => s.id === id)
  },
  fieldValue: (state) => (questionId, savedOnly = false) => {
    if (!savedOnly) {
      for (const s of state.sections) {
        for (const f of s.fields) {
          if (f.question_id === questionId) return f.value
        }
      }
    }

    for (const s of state.item.sections) {
      for (const f of s.fields) {
        if (f.question_id === questionId) return f.value
      }
    }

    return null
  },
  isDirty: (state) => {
    return state.dirty
  },
  pagination: (state) => {
    return state.pagination
  },
  submitAttempted: (state) => {
    return state.submitAttempted
  },
  complete: (state) => {
    return state.completeSections
  },
  readSections: (state) => {
    return jsonParse(state?.item?.sections_read, {})
  },
}

// actions
const actions = {
  one({ commit }, id, params = {}) {
    return api.responses.single(id, params).then((response) => {
      commit("setResponse", response)
      commit("setSubmitAttempt", false)
    })
  },
  section({ commit }, id, section_id, params = {}) {
    return api.responses.section(id, section_id, params).then((response) => {
      commit("setResponse", response)
    })
  },
  submit({ dispatch }, id) {
    return api.responses.submit(id).then(() => {
      dispatch("one", id)
    })
  },
  storeField({ commit }, data) {
    commit("setFields", data)
    commit("setDirty")
  },
  async saveSection({ commit, getters }, { section_id }) {
    let result = await api.responses.update(getters.get.id, {
      tender_id: getters.get.tender_id,
      sections: getters.sections,
    })
    await commit("updateSection", result.data)
    commit("setClean")
  },
  async readSection({ commit, getters }, { section_id, section_name }) {
    if (section_id in getters.readSections) {
      return
    }

    let read = { ...getters.readSections, [section_id]: section_name }
    await api.responses.sectionsRead(getters.get.id, {
      sections_read: JSON.stringify(read),
    })
    await commit("setReadSections", read)
  },
  submitAttempt({ commit }) {
    commit("setSubmitAttempt", true)
  },
  clearSubmitAttempt({ commit }) {
    commit("setSubmitAttempt", false)
  },
  completeSection({ commit }, sections) {
    commit("setCompleteSection", sections)
  },
  incompleteSection({ commit }, sections) {
    commit("removeInCompleteSection", sections)
  },
}

// mutations
const mutations = {
  setResponse(state, { data }) {
    if (!data) {
      return
    }

    state.item = data
    // reset sections
    state.sections = []
  },
  updateSection(state, data) {
    if (!data) {
      return
    }

    data.forEach((updatedSection) => {
      let found = state.item.sections.findIndex((section) => section.id === updatedSection.id)

      if (found < 0) {
        state.item.sections.push({
          id: updatedSection.id,
          fields: [],
        })

        found = state.item.sections.findIndex((section) => section.id === updatedSection.id)
      }

      if (found > -1) {
        updatedSection.fields.forEach((updatedField) => {
          let foundField = state.item.sections[found].fields.findIndex((f) => f.question_id === updatedField.question_id)
          if (foundField > -1) {
            state.item.sections[found].fields[foundField].value = updatedField.value
          } else {
            state.item.sections[found].fields.push({
              question_id: updatedField.question_id,
              id: updatedField.id,
              value: updatedField.value,
            })
          }
        })
      }
      // reset temporary sections storage
      state.sections = []
    })
  },
  setFields(state, { section_id, value, field }) {
    let found = state.sections.findIndex((section) => section.id === section_id)

    if (found === -1) {
      state.sections.push({
        id: section_id,
        fields: [],
      })
      found = state.sections.length - 1
    }

    let foundField = state.sections[found].fields.findIndex((f) => f.question_id === field.question_id)

    if (foundField === -1) {
      state.sections[found].fields.push({})
      foundField = state.sections[found].fields.length - 1
    }

    state.sections[found].fields[foundField] = {
      id: field.id,
      question_id: field.question_id,
      name: field.name,
      value: isString(value) ? value : JSON.stringify(value),
      files: field?.files,
      status: field?.status ?? true,
    }

    //state.item.sections = [data]
  },
  setDirty(state) {
    state.dirty = true
  },
  setClean(state) {
    state.dirty = false
    state.sections = []
  },
  setPagination(state, pagination = {}) {
    state.pagination = {
      ...pagination,
    }
  },
  setSubmitAttempt(state, data) {
    state.submitAttempted = data
  },
  setCompleteSection(state, section) {
    const sectionCheck = state.completeSections.find((sectionId) => sectionId === section)
    if (!sectionCheck) state.completeSections = [...state.completeSections, section]
  },
  setReadSections(state, data) {
    state.item.sections_read = JSON.stringify(data)
  },
  removeInCompleteSection(state, section) {
    state.completeSections = state.completeSections.filter((item) => item !== section)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
