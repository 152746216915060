<template>
  <div class="form-input text-input" v-if="conditionsMet">
    <validation-provider
      mode="eager"
      :rules="validation.join('|')"
      v-slot="{ errors, changed }"
      :name="`${id}-${namePrefix}${label}`"
      :immediate="immediate"
    >
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${id}_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>

        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <input
          type="text"
          :name="name"
          :id="`input_${id}_${name}`"
          v-model="current"
          :class="{ error: errors.length && !changed }"
          v-debounce:300="update"
        />
        <field-error v-if="errors.length > 0" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :type="type"
        :print="print"
        :id="id"
        :errors="errors"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import FieldError from "@/components/form/FieldError"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"
export default {
  name: "NumericInput",
  components: { ReadOnlyString, FieldError },
  mixins: [InputMixin, ConditionalMixin],
}
</script>
