import { jsonParse } from "@/components/form/helpers/fieldParser"
import { isEmpty } from "lodash"
import { email, max, regex, required } from "vee-validate/dist/rules"

const daterange = {
  validate(value) {
    // #todo implement
    return true
  },
}

const timerange = {
  validate(value) {
    // #todo implement
    return true
  },
}

const time = {
  validate(value) {
    // #todo implement
    return true
  },
}

const date = {
  validate(value) {
    // #todo implement
    return true
  },
}

const currency = {
  validate(value) {
    return /^[0-9-]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(value)
  },
}

const numeric = {
  validate(value) {
    return /^[0-9-]\d*(((,\d{3}){1})?(\.\d{0,5})?)$/.test(value)
  },
}

const abn = {
  validate(raw) {
    let item = jsonParse(raw, false)

    if (!item?.value?.ABN) {
      return false
    }

    let abn = item.value.ABN;
    if (Array.isArray(abn)) {
        let sorted = item.value.ABN.sort((a, b) => {
            return new Date(b.replacedFrom) - new Date(a.replacedFrom);
        });
        abn = sorted[0];        
    }

    return !isEmpty(abn.identifierValue)
  },
}

const contact = {
  validate(raw) {
    let item = jsonParse(raw, false)

    if (!item) {
      return false
    }

    if (!email.validate(item?.value?.email)) {
      return false
    }

    if (!required.validate(item?.value?.firstName).valid) {
      return false
    }

    if (!required.validate(item?.value?.lastName).valid) {
      return false
    }

    if (!required.validate(item?.value?.position).valid) {
      return false
    }

    if (!max.validate(item?.value?.primaryContactNumber, { length: 10 }) || !/^\d+$/.test(item?.value?.primaryContactNumber)) {
      return false
    }

    return true
  },
}

const address = {
  validate(raw) {
    let item = jsonParse(raw, false)

    if (!item) {
      return false
    }

    if (!required.validate(item?.value?.addressLine1).valid) {
      return false
    }

    if (!required.validate(item?.value?.suburb).valid) {
      return false
    }

    if (!required.validate(item?.value?.state).valid) {
      return false
    }

    if (!required.validate(item?.value?.postCode).valid || item?.value?.postCode?.length !== 4) {
      return false
    }

    if ("format" in item?.value && item?.value?.format === "siteaddress") {
      if ("typesOfService" in item?.value) {
        if (!required.validate(item?.value?.typesOfService).valid) {
          return false
        }
      }

      if ("hoursOfService" in item?.value) {
        if (!required.validate(item?.value?.hoursOfService).valid) {
          return false
        }
      }

      if ("frequencyOfService" in item?.value) {
        if (!required.validate(item?.value?.frequencyOfService).valid) {
          return false
        }
      }
    }

    return true
  },
}

export { daterange, timerange, time, date, currency, numeric, abn, contact, address }
