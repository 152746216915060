<template>
  <div class="page flex justify-center items-center min-h-screen">
    <card>
      <div class="p-40 relative flex">
        <loader :overlay="false"></loader>
      </div>
    </card>
  </div>
</template>
<script>
import Loader from "@/components/Loader"
import Card from "@/components/Card"
import { mapGetters } from "vuex"
import api from "@/store/api"

export default {
  components: {
    Card,
    Loader,
  },
  computed: {
    ...mapGetters({
      userLocation: "auth/userLocation",
      token: "auth/token",
      account: "auth/account",
    }),
  },
  methods: {
    load() {
      this.$store
        .dispatch("auth/load")
        .then(() => {
          api.auth
            .validate({ idToken: this.token, account: this.account })
            .then(() => {
              if (this.userLocation !== "/" && this.userLocation.includes("error") && this.userLocation.includes("404")) {
                this.$router.push({ path: this.userLocation }).catch(() => {})
              } else {
                this.$router.push({ name: "home" }).catch(() => {})
              }
            })
            .catch(() => {
              this.$router.push({ name: "home" }).catch(() => {})
            })
        })
        .catch(() => {
          this.$router.push({ name: "home" }).catch(() => {})
        })
    },
  },
  mounted() {
    this.load()
  },
}
</script>
<style lang="scss" scoped>
.page {
  @apply bg-no-repeat bg-top bg-primary;
  background-image: url("~@/assets/images/cables-bg.svg");
}
</style>
