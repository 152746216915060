<template>
  <alert class="mb-5 text-center shadow-lg">
    <p>You are now in read-only mode.</p>
    <p>You can print your full response, or download a pdf by setting the print destination to PDF.</p>
    <div class="flex flex-col items-center justify-between mt-4 2xl:flex-row">
      <button type="button" class="flex items-center mb-4 ml-4 text-primary-light 2xl:mb-0" @click.prevent="print()">
        <span class="underline">Print/download PDF of full response</span>
        <img src="@/assets/images/icons/printer.svg" alt="Printer icon" class="w-5 ml-3" />
        <img src="@/assets/images/icons/export.svg" alt="Export icon" class="w-5 ml-3" />
      </button>
      <router-link
        v-if="response && editable"
        class="button button--filled"
        :to="{ name: 'response-section', params: { id: response.id, section: sectionId } }"
        >Return to response</router-link
      >
      <router-link v-if="response && !editable" class="button button--filled" :to="{ name: 'responses' }">Return to My tender responses</router-link>
    </div>
  </alert>
</template>
<script>
import Alert from "@/components/Alert"
export default {
  components: { Alert },
  props: {
    response: {
      type: [Object, Boolean],
      default: false,
    },
    sectionId: {
      type: [String, Number, Boolean],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    print() {
      this.$events.$emit("print:open")
    },
  },
}
</script>
