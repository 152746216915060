<template>
  <div class="mx-8">
    <h2 class="text-center">Confirm Response Submission</h2>
    <p class="mb-8 text-center">
      You are about to submit your response to this tender.
    </p>
    <p class="mb-8 text-center">
      You can still edit your submission before the closing date by selecting the ‘Unsubmit/edit submission’ action on the ‘My tenders’ page.
    </p>
    <alert classes="alert--secondary" v-if="hasAddenda">
      <h3>Addenda agreement and confirmation</h3>
      <div class="mb-4">
        This tender has {{ response.tender.addenda_count }} {{ response.tender.addenda_count === 1 ? "Addendum" : "Addenda" }}. You are responsible
        for ensuring you have read all
        <router-link target="_blank" :to="{ name: 'tender-addenda', params: { id: tenderId } }">Addenda</router-link>
        issued and understand how they may influence your response.
      </div>
      <label class="flex font-bold cursor-pointer">
        <input type="checkbox" class="mr-4 mt-[5px] w-[22px] h-[22px]" v-model="checkbox" />
        <p class="w-full">
          I acknowledge that I have read and understood all the Addenda and the accompanying documents for this tender and have understood the
          contents of them
        </p>
      </label>
    </alert>
    <div class="flex justify-between" v-if="response.invited">
      <button class="inline-flex button button--outline mt-9" type="submit" v-text="'Cancel'" @click.prevent="goBack()"></button>
      <button
        class="inline-flex button button--filled mt-9"
        type="submit"
        v-text="!submitting ? 'Submit' : 'Submitting'"
        @click.prevent="fullSubmit()"
        :disabled="!checkbox && hasAddenda"
      ></button>
    </div>

    <modal v-if="modalOpen" @close="cancel()">
      <template #content>
        <p>
          There {{ formSectionErrors.size === 1 ? "is" : "are" }}
          <span class="font-bold text-red-700">{{ formSectionErrors.size }} section{{ formSectionErrors.size === 1 ? "" : "s" }}</span> with errors in
          this response.
        </p>
        <p>You must resolve all errors before your response can be submitted successfully.</p>
      </template>
      <template #footer>
        <button class="mr-3 button button--outline" @click.prevent="fixErrors()">Fix errors</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import FormSections from "@/routes/response/mixins/form-sections"
import Alert from "@/components/Alert"
import Modal from "@/components/Modal.vue"
import { validate } from "vee-validate"
import { fieldComponents } from "@/components/form/Fields"
import { jsonParse } from "@/components/form/helpers/fieldParser"
import builtSections from "@/routes/response/mixins/builtSections"
import { processConditions } from "@/components/form/helpers/conditionProcessor"

export default {
  inheritAttrs: false,
  components: {
    Modal,
    Alert,
  },
  mixins: [FormSections, builtSections],
  props: {
    response: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkbox: false,
      submitting: false,
      modalOpen: false,
      formErrors: [],
      formSectionErrors: new Set(),
    }
  },
  computed: {
    hasAddenda() {
      return this.response?.tender?.addenda_count > 0
    },
    tenderId() {
      return this.$store.getters["response/get"].tender_id
    },
  },
  methods: {
    ...mapActions({
      saveSection: "response/saveSection",
      submitSection: "response/submit",
      submitAttempt: "response/submitAttempt",
    }),
    cancel() {
      this.modalOpen = false
      this.submitting = false
    },
    fixErrors() {
      this.cancel()
      const firstErrorSection = this.formSections.find((section) => section.id === this.formErrors[0].sectionId)
      this.$router.push({ name: "response-section", params: { id: this.$route.params.id, section: firstErrorSection.id } })
    },
    goBack() {
      this.$router.push({
        name: "response-section",
        params: { id: this.$route.params.id, section: this.formSections[this.formSections.length - 1].id },
      })
    },
    addFormErrors(section, field, errors) {
      this.formErrors.push({
        sectionId: section.id,
        fieldId: field.id,
        fieldName: field.name,
        error: errors,
      })

      this.formSectionErrors.add(section.id)
    },
    async fullSubmit() {
      this.formErrors = []
      this.formSectionErrors.clear()
      for await (const section of this.builtSections) {
        if (processConditions(section?.conditional_statements)) {
          for await (const field of section.fields) {
            if (field.type in fieldComponents) {
              let result = await validate(field?.response?.value || "", field.validation.join("|"))
              if (!result.valid && processConditions(field?.conditional_statements)) {
                this.addFormErrors(section, field, result.errors)
              }
            } else if (field.type === "table") {
              if (field.response) {
                const tableJson = jsonParse(field.response.value)
                if (tableJson?.value) {
                  for (const row of tableJson.value) {
                    for (const column of field.fields) {
                      const cell = row.find((cell) => {
                        return cell?.question_id?.includes(column.id)
                      })
                      let result = await validate(cell?.value || "", column.validation.join("|"))
                      if (!result.valid) {
                        this.addFormErrors(section, field, result.errors)
                      }
                    }
                  }
                } else if (field.validation.includes("required") || field.fields.filter((column) => column.validation.length > 0).length > 0) {
                  this.addFormErrors(section, field, ["This field is required"])
                }
              } else if (field.validation.includes("required") || field.fields.filter((column) => column.validation.length > 0).length > 0) {
                this.addFormErrors(section, field, ["This field is required"])
              }
            }
          }
        }
      }

      if (this.formErrors.length === 0) {
        this.submitSection(this.$route.params.id).then(() => {
          this.submitting = false
          this.$router.push({ name: "submitted", params: { id: this.$route.params.id } })
        })
        return
      }

      this.submitAttempt()
      this.$emit("error", this.formErrors)
      this.modalOpen = true
    },
  },
}
</script>
