<template>
  <div class="relative mb-92">
    <loader v-if="loading" :overlay="false"></loader>
    <div class="xl:flex" v-if="!loading">
      <div class="relative xl:w-350 xl:pr-10" v-if="!submitted">
        <button @click="open = !open" class="block w-full p-4 mb-4 text-left bg-white rounded shadow-lg dropdown xl:hidden" :class="{ open }">
          Jump to
        </button>
        <time-remaining :item="tender" v-if="tender"></time-remaining>
        <mini-menu v-if="false"></mini-menu>
        <progress-menu
          v-if="response && formSection"
          :readOnly="readOnly"
          :sections="formSections"
          :section="formSection"
          :available-sections="availableSections"
          :response="response"
          :class="{ 'hidden xl:block': !open }"
        ></progress-menu>
      </div>
      <div class="w-full tender-content" :class="{ 'xl:w-3/4': !submitted }">
        <response-read-only-alert
          v-if="readOnly && !submitted"
          :response="response"
          :section-id="sectionId"
          :editable="editable"
          @print="print()"
        ></response-read-only-alert>
        <response-denyed-alert v-if="response && !response.invited" />
        <validation-observer slim ref="form">
          <form @submit.prevent="submit" method="post" class="mb-24">
            <card theme="light" v-if="response && formSection">
              <addenda-alert v-if="!lastSectionCheck && !submitted" :tender="tender"></addenda-alert>
              <in-page-nav
                v-if="!lastSectionCheck && !submitted"
                :form-sections="availableSections"
                :read-only="readOnly"
                class="mb-10"
              ></in-page-nav>
              <router-view
                :response="response"
                :section="section"
                :form-section="formSection"
                :key="formSection.id"
                :readOnly="readOnly"
                :immediate="immediate"
                :built-sections="builtSections"
                @error="onError"
              ></router-view>
              <in-page-nav
                v-if="!lastSectionCheck && !submitted"
                :form-sections="availableSections"
                :read-only="readOnly"
                class="mt-10"
              ></in-page-nav>
            </card>
            <response-footer v-if="!loading && !lastSectionCheck && !submitted">
              <template #review>
                <router-link
                  v-if="!readOnly"
                  class="button button--filled"
                  :to="{ name: 'section-review', params: { id: response.id, section: sectionId } }"
                >
                  Review, print and download response
                </router-link>
                <div v-if="readOnly" class="flex flex-col items-center justify-center w-full sm:flex-row sm:justify-end">
                  <button type="button" class="flex items-center mb-4 mr-14 text-primary-light sm:mb-0" @click.prevent="print()">
                    <span class="underline">Print/download PDF of full response</span>
                    <img src="@/assets/images/icons/printer.svg" alt="Printer icon" class="w-5 ml-3" />
                    <img src="@/assets/images/icons/export.svg" alt="Export icon" class="w-5 ml-3" />
                  </button>

                  <router-link type="button" :to="{ name: 'uploads-review' }" class="flex items-center mb-4 mr-14 text-primary-light sm:mb-0">
                    <span class="underline">View Attachments</span>
                  </router-link>

                  <router-link
                    class="button button--filled"
                    v-if="editable"
                    :to="{ name: 'response-section', params: { id: response.id, section: sectionId } }"
                  >
                    Return to response
                  </router-link>
                  <router-link v-else class="button button--filled" :to="{ name: 'responses' }">Return to My tender responses</router-link>
                </div>
              </template>
              <template #submit v-if="!readOnly">
                <button
                  class="inline-flex button button--filled"
                  :disabled="saving || !dirty"
                  type="submit"
                  v-text="!saving ? 'Save' : 'Saving'"
                ></button>
              </template>
            </response-footer>
          </form>
        </validation-observer>
      </div>
    </div>
    <modal v-if="modalOpen" @close="cancel()">
      <template v-if="submitting" #content>
        <p>
          There are <span class="font-bold text-red-700">{{ formErrors.length }} question responses</span> errors in this section.
        </p>
        <ul>
          <li v-for="(error, ind) in formErrors" :key="'error_' + ind">
            {{ error.error[0].replace("{field}", error.fieldName) }}
          </li>
        </ul>
      </template>
      <template v-else #content>
        <p>
          There are <span class="font-bold text-red-700">{{ formErrors.length }} question responses</span> with errors in this response.
        </p>
        <p>Would you like to -</p>
        <ol class="my-0">
          <li>Save partial draft and continue without addressing page errors</li>
          <li>Cancel and address errors</li>
        </ol>
      </template>
      <template #footer>
        <button class="mr-3 button button--outline" @click.prevent="forceSubmit()">Save partial draft and continue</button>
        <button class="button button--outline" @click.prevent="cancel()">Cancel</button>
      </template>
    </modal>
  </div>
</template>

<script>
import { processConditions } from "@/components/form/helpers/conditionProcessor"
import ResponseDenyedAlert from "@/components/responses/ResponseDeniedAlert"
import DynamicTitle from "@/mixins/dynamic-title"
import Card from "@/components/Card"
import { mapActions, mapGetters } from "vuex"
import Loader from "@/components/Loader"
import TimeRemaining from "@/components/TimeRemaining"
import ProgressMenu from "@/components/ProgressMenu"
import InPageNav from "@/components/InPageNav.vue"
import ResponseFooter from "@/components/ResponseFooter"
import FormSections from "./mixins/form-sections"
import navCatch from "./mixins/navCatch"
import readOnly from "./mixins/readOnly"
import responseSubmit from "./mixins/responseSubmit"
import responseHelpers from "./mixins/responseHelpers"
import MiniMenu from "@/components/responses/MiniMenu.vue"
import Modal from "@/components/Modal.vue"
import AddendaAlert from "@/components/tenders/AddendaAlert"
import ResponseReadOnlyAlert from "@/components/responses/ResponseReadOnlyAlert"
import builtSections from "@/routes/response/mixins/builtSections"

const numberOfFieldsComplete = (section) => {
  let fields = section.fields
  let complete = 0
  for (let i = 0; i < fields.length; i++) {
    if (fields[i].complete && processConditions(fields[i]?.conditional_statements)) {
      complete++
    }
  }
  return complete
}

export default {
  components: {
    ResponseDenyedAlert,
    ResponseReadOnlyAlert,
    AddendaAlert,
    TimeRemaining,
    Loader,
    Card,
    ProgressMenu,
    InPageNav,
    ResponseFooter,
    MiniMenu,
    Modal,
  },

  mixins: [DynamicTitle, FormSections, navCatch, readOnly, builtSections, responseHelpers, responseSubmit],

  data: () => ({
    open: false,
    loading: false,
    saving: false,
    submitting: false,
    printPreview: false,
    modalOpen: false,
    formErrors: [],
    checked: false,
    attachmentIds: [],
  }),
  computed: {
    ...mapGetters({
      dirty: "response/isDirty",
      response: "response/get",
      immediate: "response/submitAttempted",
      complete: "response/complete",
      dirtySections: "response/sections",
      readSections: "response/readSections",
    }),
    editable() {
      return this.response.status === "draft" && this.response.tender.status !== "closed"
    },
    section() {
      if (this.$route.params?.section && this.response.sections && this.response.sections.length > 0) {
        return this.response.sections.find((section) => section.id === this.$route.params.section)
      }

      if (this.formSection?.id && this.response.sections && this.response.sections.length > 0) {
        return this.response.sections.find((section) => section.id === this.formSection.id)
      }

      return this.response?.sections?.length ? this.response.sections[0] : false
    },
    availableSections() {
      return this.calculatedSections.filter((section) => processConditions(section?.conditional_statements, true))
    },
    calculatedSections() {
      return this.formSections.map((section) => {
        const completeFields = numberOfFieldsComplete(section)
        const sectionError = this.complete.filter((error) => error.sectionId === section.id)
        const fillableFields = section.fields.filter((field) => field.type !== "content" && processConditions(field?.conditional_statements))
        const requiredFields = section.fields.filter(
          (field) => field.validation.includes("required") && processConditions(field?.conditional_statements),
        )
        const tableRequiredFields = section.fields
          .filter((field) => field.type === "table")
          .filter((field) => field.fields.filter((field) => field.validation.includes("required")))

        let complete = false

        if (fillableFields.length === 0 && section.id in this.readSections) {
          complete = true
        } else if (requiredFields.length === 0 && tableRequiredFields.length === 0 && section.id in this.readSections) {
          complete = true
        } else if (section.id in this.readSections && this.$store.getters["response/complete"].includes(section.id)) {
          complete = true
        }

        return {
          title: section.title,
          id: section.id,
          conditional_statements: section?.conditional_statements ?? false,
          complete,
          read: section.id in this.readSections,
          completeFields: completeFields,
          fields: fillableFields.length,
          errors: sectionError,
        }
      })
    },
    sectionId() {
      if (this.section) {
        return this.section.id
      }

      return this.$route.params?.section ?? null
    },
    title() {
      if (this.loading) {
        return false
      }

      return (this.readOnly ? "Reviewing response to " : "") + this.response?.tender?.title ?? false
    },
    tender() {
      return this.response?.tender ?? false
    },
    currentErrors() {
      return this.$refs.form.errors
    },
    readOnly() {
      return this.$route.name === "section-review" || !this.editable
    },
    submitted() {
      return this.$route.name === "submitted"
    },
    lastSectionCheck() {
      return this.$route.name === "submit"
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.load()
    })
  },
  methods: {
    ...mapActions({
      fetchResponse: "response/one",
      fetchForm: "form/load",
    }),
    async load() {
      await this.$store.dispatch("auth/load")
      await this.$store.dispatch("auth/poll")
      this.loading = true
      this.fetchResponse(this.$route.params.id).then(() => {
        this.titleSync()
        this.fetchForm(this.response.tender_id).then(() => {
          this.checkCompletedSections()
          this.loading = false
        })
      })
    },
    async print() {
      this.$events.$emit("print:open")
    },
  },
}
</script>
