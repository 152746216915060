<template>
  <div>
    <card theme="light">
      <div class="max-w-xl m-auto py-12 text-center">
        <h2 class="mb-0">System error</h2>
        <span class="mb-8 block text-sm text-gray-500">{{ date }}</span>
        <img src="@/assets/images/system-error.svg" class="m-auto max-w-md mb-8 w-full" alt="" />
        <h3>We are having technical issues</h3>
        <div>Please try again later or contact us if you need help.</div>
        <div class="pt-8">
          <strong class="block">By phone</strong>
          <p><a href="tel:1300 733 514" class="tel">1300 733 514</a></p>
          <strong class="block pt-4">By email</strong>
          <p><a href="mailto:espurchasing@dewr.gov.au">espurchasing@dewr.gov.au</a></p>
          <div>
            To assist us in helping you, please attach a screenshot of this page, including the timestamp.
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import Card from "@/components/Card"
export default {
  components: {
    Card,
  },
  computed: {
    date() {
      return new Date().toString()
    },
  },
}
</script>
