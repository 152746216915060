import api from "@/store/api"
import { useDummy } from "@/plugins/util"

let items = []

if (useDummy()) {
  items = require("../../mocks/data/knowledge").default.filter((t) => t.type === "block")
}

// state
const state = () => ({
  items,
})

// getters
const getters = {
  findByMachineName: (state) => (search) => {
    return state.items.find((blockContentItem) => blockContentItem.machine_name === search) ?? false
  },

  findByMachineNames: (state) => (search) => {
    return state.items.filter((blockContentItem) => search.includes(blockContentItem.machine_name))
  },

  find: (state) => (id) => {
    let search = parseInt(id)
    return state.items.find((blockContentItem) => blockContentItem.id === search)
  },

  existsByMachineName: (state, getters) => (search) => {
    return getters.findByMachineName(search) ?? false
  },
}

// actions
const actions = {
  add({ commit }, blockContent) {
    commit("addBlockContent", { blockContent })
  },

  load({ commit, getters }, machine_name) {
    machine_name = machine_name.filter((mn) => !getters.existsByMachineName(mn))

    if (machine_name.length < 1) {
      // skip if this exists already... no need for magic updates to blocks
      return
    }

    return api.blockContent.collection({ names: machine_name }).then((response) => {
      commit("setBlockContent", {
        data: response.data,
      })
    })
  },

  one({ commit }, machine_name, params = {}) {
    return api.blockContent.single(machine_name, params).then((response) => {
      const data = [response.data]
      commit("setBlockContent", data)
    })
  },
}

// mutations
const mutations = {
  setBlockContent(state, { data }) {
    state.items = data.reduce(
      (acc, el) => {
        if (state.items.findIndex((current) => current.id === el.id) === -1) {
          acc.push(el)
        }
        return acc
      },
      [...state.items],
    )
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
