import Vue from "vue"
import Vuex, { mapGetters } from "vuex"
import VueRouter from "vue-router"
import VueDOMPurifyHTML from "vue-dompurify-html"
import { ValidationProvider, ValidationObserver, extend } from "vee-validate"
import * as rules from "vee-validate/dist/rules"
import * as customRules from "./plugins/validationRules"
import { messages } from "./plugins/validationMessages.json"
import moment from "moment"
import vClickOutside from "v-click-outside"
import App from "./App.vue"
import Paginate from "vuejs-paginate"
import VueDebounce from "vue-debounce"
import { humanFileSize, useMock } from "./plugins/util"
import { default as configPlugin } from "./config"
import { router } from "./routes"
import store from "./store"
import * as VueGoogleMaps from 'vue2-google-maps'

import "./assets/sass/main.scss"

const itemsjs = require("itemsjs")

Vue.prototype.$moment = moment
Vue.prototype.$itemsjs = itemsjs
Vue.prototype.$events = new Vue()

Vue.use(VueDebounce, {
  listenTo: ["input", "keyup"],
})
Vue.use(VueDOMPurifyHTML, {
  default: {
    ADD_ATTR: ["target"],
  },
})
Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(vClickOutside)
Vue.use(configPlugin)

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API_KEY,
        libraries: 'places',
    },
})

const allRules = { ...rules, ...customRules }
Object.keys(allRules).forEach((rule) => {
  extend(rule, {
    ...allRules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

Vue.component("validation-provider", ValidationProvider)
Vue.component("validation-observer", ValidationObserver)

Vue.component("paginate", Paginate)
Vue.filter("humanFileSize", function(value) {
  if (!value) return ""
  value = parseInt(value)
  return humanFileSize(value)
})

Vue.mixin({
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
    }),
  },
})

//Local mock api server
if (process.env.NODE_ENV === "development" && useMock()) {
  console.log("USING MOCK DATA")
  const { worker } = require("./mocks/browser")
  worker.start().then(() => {
    console.log("MOCK DATA LOADED")
  })
}

export const main = new Vue({
  router,
  store,
  render: (h) => h(App),
  data: () => ({
    interval: {},
  }),
  watch: {
    $route() {
      this.poll()
    },
  },
  async created() {
    await this.$store.dispatch("auth/load")

    setTimeout(async () => {
      await this.$store.dispatch("auth/load")
    }, 1000)

    this.interval = setInterval(() => this.poll(), 60000)
  },

  destroyed() {
    clearInterval(this.interval)
  },
  methods: {
    async poll() {
      //console.warn("root.poll")
      await this.$store.dispatch("auth/poll")
    },
  },
}).$mount("#app")
