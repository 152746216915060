<template>
  <div>
    <div class="p-2 flex flex-col items-center">
      <div class="text-center">
        <p class="font-bold text-lg">Your response has been submitted</p>
        <p>You will receive an email confirming your submission has been received.</p>
      </div>
      <div>
        <div class="uploadSuccessIcon"></div>
      </div>
      <div>
        <router-link class="inline-flex button button--filled m-4" :to="{ name: 'responses' }">Return to My Tender Responses</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import render from "@/plugins/render"
export default {
  props: {
    response: {
      type: Object,
      default: () => ({}),
    },
    formSection: {
      type: Object,
      default: () => ({}),
    },
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    safeDescription() {
      if (!this.formSection?.description) {
        return ""
      }
      // this render function uses dompurify so we don't need to run it through the v-dompurify-html attribute
      let text = render.editor(this.formSection.description)
      return text ? text.innerHTML : ""
    },
  },
}
</script>
