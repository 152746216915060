export default {
  mounted() {
    this.titleSync()
  },
  methods: {
    titleSync() {
      this.$events.$emit("title:sync", this?.title)
    },
  },
  watch: {
    title() {
      this.titleSync()
    },
  },
}
