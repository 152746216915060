module.exports = '<h2>Procurement and Licence Management System</h2>\n' +
  '  <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
  ' Aliquam ut porttitor <a href="https://dese.gov.au">leo a diam sollicitudin tempor id eu</a>. Duis ut diam quam nulla porttitor massa. Faucibus vitae aliquet nec ' +
  ' ullamcorper sit amet risus. Ut sem viverra aliquet eget sit amet tellus. Neque sodales ut etiam sit amet nisl purus in. ' +
  ' Odio pellentesque diam volutpat commodo sed. Dictumst vestibulum rhoncus est pellentesque. ' +
  ' Vel eros donec ac odio. Sagittis vitae et leo duis. Ultricies mi quis hendrerit dolor. ' +
  'Sit amet est placerat in egestas erat imperdiet sed. Tortor aliquam nulla facilisi cras fermentum. ' +
  ' Fringilla est ullamcorper eget nulla. Id cursus metus aliquam eleifend mi in nulla. </p>' +
  ' <iframe width="560" height="315" src="https://www.youtube.com/embed/I15lRZIl9Ok" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' +
  ' <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
  'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
  'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
  'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>' +
  '<p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
  'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum:</p>' +
  '<a href="http://google.com" target="_blank" ><img src="https://placeimg.com/640/480/arch"></a>' +
  '<ul>' +
  '<li>Excepteur sint occaecat cupidatat non proident</li>\n' +
  '<li>Sunt in culpa qui officia deserunt mollit anim id est laborum</li>\n' +
  '<li>Nulla malesuada pellentesque elit eget gravida cum sociis natoque</li>\n' +
  '<li>In hendrerit gravida rutrum quisque non tellus orci</li>\n' +
  '<li>Metus aliquam eleifend mi in nulla posuere sollicitudin aliquam</li>\n' +
  '</ul>' +
  '<iframe width="560" height="315" src="https://www.youtube.com/embed/AIu_1A40M6A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'


