<template>
  <div>
    <loader v-if="loading"></loader>
    <div class="xl:flex">
      <div class="relative xl:w-[350px] xl:pr-16">
        <button @click="open = !open" class="block w-full p-4 mb-4 text-left bg-white rounded shadow-lg dropdown xl:hidden" :class="{ open }">
          Jump to
        </button>
        <time-remaining :item="tender" v-if="tender"></time-remaining>
        <progress-menu
          v-if="formSection && formSections"
          :sections="formSections"
          :section="formSection"
          :class="{ 'hidden xl:block': !open }"
          :overview="overview"
          :is-enabled="false"
        ></progress-menu>
      </div>
      <div class="xl:w-3/4 tender-content">
        <card class="relative" v-if="tender">
          <div class="mb-8 text-center">
            <h2 v-if="overview && overview.title" v-text="overview.title"></h2>
            <h2 v-else>Confirm Response</h2>
          </div>
          <div v-if="overview && !hasResponse" v-dompurify-html="overview.description"></div>

          <div v-if="hasResponse">
            <div class="pb-8">You have already started a response would you like to continue</div>
            <div class="flex justify-between w-full">
              <router-link :to="{ name: 'tender', params: { id: tender.id } }" class="mr-4 button button--outline">Cancel</router-link>
              <router-link :to="{ name: 'response', params: { id: response.id } }" class="inline-flex button button--filled"
                >Continue response</router-link
              >
            </div>
          </div>
          <div v-else>
            <div class="flex justify-between w-full">
              <router-link :to="{ name: 'tender', params: { id: tender.id } }" class="mr-4 button button--outline">Cancel</router-link>
              <button @click="createResponse" class="inline-flex button button--filled">Begin response</button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Card.vue"
import Loader from "@/components/Loader.vue"
import ProgressMenu from "@/components/ProgressMenu.vue"
import { mapActions, mapGetters } from "vuex"
import FormSections from "./mixins/form-sections"
import DynamicTitle from "@/mixins/dynamic-title"
import TimeRemaining from "@/components/TimeRemaining"
import { isArray } from "lodash"

export default {
  components: {
    TimeRemaining,
    Loader,
    Card,
    ProgressMenu,
  },
  mixins: [FormSections, DynamicTitle],
  data() {
    return {
      loading: false,
      open: false,
    }
  },
  async created() {
    this.loading = true
    await this.loadTender(this.$route.params.tenderId)
    await this.loadForm(this.$route.params.tenderId)
    if (this.responses < 1) {
      await this.fetchResponses()
    }
    this.loading = false
  },
  computed: {
    ...mapGetters({
      responses: "responses/all",
    }),
    hasResponse() {
      return !!this.response
    },
    response() {
      return this.$store.getters["responses/findByTender"](this.$route.params.tenderId)
    },
    tender() {
      return this.$store.getters["tenders/find"](this.$route.params.tenderId)
    },
    title() {
      return this.tender?.title ? `Responding to: ${this.tender.title}` : ""
    },
    overview() {
      if (!this.form?.sections || this.form?.sections?.length < 1) {
        return false
      }
      return this.form?.sections.find((s) => s.title.match(/overview/gi))
    },
    formSections() {
      if (!isArray(this?.form?.sections)) {
        return []
      }

      return this?.form?.sections.filter((s) => !s.title.match(/overview/gi)) ?? []
    },
    firstSection() {
      return this?.form?.sections.length > 1 ? this?.form?.sections[1] : this?.form?.sections[0]
    },
  },
  methods: {
    ...mapActions({
      loadTender: "tenders/one",
      loadForm: "form/load",
      fetchResponses: "responses/load",
      readSection: "response/readSection",
    }),
    async createResponse() {
      this.loading = true
      let response = await this.$store.dispatch("responses/create", { tender_id: this.$route.params.tenderId })
      await this.$store.dispatch("response/one", response.id)
      this.loading = false

      if (this.overview) {
        this.readSection({ section_id: this.overview?.id, section_name: this.overview.name }).then()
      }

      await this.$router.push({ name: "response-section", params: { id: response.id, section: this.firstSection?.id } })
    },
  },
}
</script>
