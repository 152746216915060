var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.availableSections)?_c('ul',{staticClass:"pl-0 ml-0 list-none bg-white shadow-lg xl:shadow-none xl:bg-transparent"},[(_vm.begin)?_c('progress-section',{attrs:{"sectionInd":0,"isEnabled":false,"currentSection":{},"section":_vm.overview,"current-section-index":_vm.currentSectionIndex,"section-selected":_vm.sectionsSelected,"complete":true,"read":false}}):_vm._e(),_vm._l((_vm.availableSections),function(section,ind){return _c('progress-section',{key:section.id,attrs:{"sectionInd":ind,"isEnabled":!_vm.begin,"section":section,"menuLength":section.length,"currentSectionIndex":_vm.currentSectionIndex,"current-section":_vm.currentSection,"section-selected":_vm.sectionSelected,"complete":section.complete,"read":section.read,"readOnly":_vm.readOnly}})}),(_vm.response && _vm.response.id && !_vm.readOnly)?_c('li',{staticClass:"relative w-full"},[_c('router-link',{staticClass:"flex items-center pl-4 mb-10 no-underline",attrs:{"to":{
          name: 'submit',
          params: { id: _vm.response.id },
        }}},[_c('div',{staticClass:"relative z-10 flex items-center"},[_c('svg',{staticClass:"absolute -top-1/3 -z-1 fill-current",class:[_vm.sectionSelected ? 'text-primary-light' : 'text-gray-300'],attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 52.828 27.779 27.876"}},[_c('path',{attrs:{"d":"M26.771001 71.761998c2.595 3.793-.121 8.942-4.717 8.942h-16.329c-4.596 0-7.312-5.149-4.717-8.942.254-.371.55-.71.882-1.011l3.418-3.092c4.191-3.791 6.582-9.179 6.582-14.831h4c0 5.652 2.391 11.04 6.581 14.831l3.418 3.092c.332.301.629.64.882 1.011Z"}})]),_c('div',{staticClass:"w-10 h-10 rounded-full",class:{
              'bg-primary-light border-4 border-primary-light': _vm.$route.name === 'submit',
              'bg-white border-2 border-gray-500 ': _vm.$route.name !== 'submit',
            }})]),_c('span',{staticClass:"relative block w-full px-2 py-5 ml-2 text-black no-underline rounded-r-md before:absolute before:w-14 before:h-full before:-left-14 before:top-0 before:rounded-l-md before:bg-opacity-20 bg-opacity-20",class:_vm.$route.name === 'submit' ? 'bg-primary-highlight before:bg-primary-highlight' : ''},[_vm._v("Submit")])])],1):_c('li',{staticClass:"relative w-full"},[_c('div',{staticClass:"flex items-center pl-4"},[_c('div',{staticClass:"relative z-10 flex items-center"},[_c('div',{staticClass:"relative flex items-center justify-center w-10 h-10 bg-white border-2 rounded-full",class:{
              'border-gray-300': !_vm.submitted,
              'bg-[#003860] border-primary-light border-4': _vm.submitted,
            }},[(_vm.submitted)?_c('img',{staticClass:"w-1/2",attrs:{"src":require("@/assets/images/tick.svg"),"alt":"Tick"}}):_vm._e()])]),_vm._m(0)])])],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-block"},[_c('span',{staticClass:"block px-2 py-1 ml-2 text-black text-gray-400 no-underline rounded-md"},[_vm._v("Submit")])])}]

export { render, staticRenderFns }