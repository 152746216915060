<template>
  <div class="min-h-screen lg:flex" :class="[{ 'bg-gray-300': layout === 'full' }, `layout-${layout}`, `page-${pageClass}`]">
    <div class="sidebar" v-if="layout === 'normal'" :class="{ mobile: menuOpen }">
      <affix
        :scroll-affix="isDesktop"
        relative-element-selector="#main-content"
        :enabled="isDesktop"
        v-if="layout === 'normal'"
        :offset="{ top: 0, bottom: 0 }"
      >
        <sidebar-layout />
      </affix>
    </div>
    <div class="w-full main-content" id="main-content" :class="{ mobile: menuOpen }">
      <maintenance-alert v-if="layout === 'normal'"></maintenance-alert>
      <loader v-if="loaderActive" />
      <header-layout :title="title" v-if="layout === 'normal'">
        <template v-slot:menu>
          <hamburger v-if="layout === 'normal'" :open="menuOpen" @toggle="toggleMenu" />
        </template>
      </header-layout>
      <div class="blocker" v-if="menuOpen" @click="menuOpen = false"></div>
      <div :class="layout === 'full' ? 'min-h-screen' : 'px-4 lg:px-12 lg:py-8'">
        <transition name="fade" mode="out-in">
          <router-view v-slot="{ Component, route }" :key="$route.path" ref="route">
            <keep-alive>
              <component :is="Component" :key="route.path" />
            </keep-alive>
          </router-view>
        </transition>
      </div>
    </div>
    <print></print>
    <errors />
  </div>
</template>

<script>
import { Affix } from "vue-affix"
import SidebarLayout from "@/components/layout/SidebarLayout"
import HeaderLayout from "@/components/layout/HeaderLayout"
import Hamburger from "@/components/Hamburger"
import breakpoints from "@/plugins/breakpoints.js"
import Loader from "@/components/Loader"
import MaintenanceAlert from "@/components/MaintenanceAlert"
import Print from "@/components/responses/Print"
import Errors from "@/components/Errors"

export default {
  name: "App",
  components: {
    SidebarLayout,
    Hamburger,
    HeaderLayout,
    Affix,
    Loader,
    MaintenanceAlert,
    Print,
    Errors,
  },
  data() {
    return {
      menuOpen: false,
      pageTitle: "",
      breakpoints,
      loaderActive: false,
    }
  },
  computed: {
    isDesktop() {
      return ["lg", "xl", "2xl"].includes(breakpoints.is)
    },
    layout() {
      return this.$route?.meta?.layout ?? "normal"
    },
    pageClass() {
      return this.$route?.name ?? "default"
    },
    title() {
      if (this.pageTitle && this.pageTitle !== "") {
        return this.pageTitle
      }

      return this.$route?.meta?.title ?? ""
    },
  },
  methods: {
    toggleMenu(open) {
      this.menuOpen = open
    },
  },
  watch: {
    $route() {
      this.pageTitle = ""
    },
    title() {
      let parts = [this.$appConfig.title]

      if (this.pageTitle) {
        parts.unshift(this.pageTitle)
      } else if (this.title !== "") {
        parts.unshift(this.title)
      }

      window.document.title = parts.join(" - ")
    },
  },
  created() {
    this.$events.$on("menu:close", (status = false) => {
      this.toggleMenu(status)
    })

    this.$events.$on("title:sync", (title = "") => {
      this.pageTitle = title
    })
  },
  destroyed() {
    this.$events.$off("menu:close")
    this.$events.$off("title:sync")
  },
}
</script>
