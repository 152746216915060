export default {
  computed: {
    builtSections() {
      return this.formSections.map((formSection) => {
        if (!formSection.fields) {
          return formSection
        }
        let sectionIndex = this.response.sections.findIndex((section) => section.id === formSection.id)

        if (sectionIndex === -1) {
          return formSection
        }

        let fields = formSection.fields.map((field) => {
          let response = this.response.sections[sectionIndex].fields.find((responseField) => responseField.question_id === field.id)
          return {
            ...field,
            response: response ? response : { value: "" },
          }
        })
        return { ...formSection, fields }
      })
    },
  },
}
