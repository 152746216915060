<template>
  <div class="wrapper">
    <loader v-if="loading" :overlay="false"></loader>
    <div class="xl:flex" v-if="!loading">
      <div class="relative xl:w-1/4 xl:pr-16">
        <button @click="open = !open" class="block w-full p-4 mb-4 text-left bg-white rounded shadow-lg dropdown xl:hidden" :class="{ open }">
          Jump to
        </button>
        <ul class="-mt-4 bg-white shadow-lg sub-menu xl:mt-4 xl:shadow-none xl:bg-transparent" v-if="tender" :class="{ 'hidden xl:block': !open }">
          <li class="p-2">
            <router-link :to="{ name: 'tender', params: { path: $route.params.id } }">{{ textType }} detail</router-link>
          </li>
          <template v-if="authenticated && hasAddenda">
            <li v-for="(addendum, ind) in addenda" :key="ind" class="p-2">
              <router-link :to="{ name: 'tender-addendum', params: { addendumId: addendum.id } }"
                >Addendum {{ ind + 1 }}: {{ addendum.title }}
                <span v-if="false && !addendum.read" class="px-2 ml-1 text-xs text-white bg-red-600 rounded-full">Unread</span>
              </router-link>
            </li>
          </template>
          <li class="p-2" v-if="tender.faqs && tender.faqs.length > 0">
            <router-link :to="{ name: 'tender-faq', params: { path: $route.params.id } }">FAQ</router-link>
          </li>
        </ul>
      </div>
      <div class="xl:w-3/4 tender-content">
        <card theme="light" v-if="tender">
          <span v-if="addendaTotal > 0">
            <alert v-if="!authenticated" class="mb-4" type="addendum">
              This tender has {{ addendaTotal }} {{ addendaTotal == 1 ? "Addendum" : "Addenda" }}. Register or login to the Employment Services
              Tenders portal to view
            </alert>
            <addenda-alert v-if="authenticated" :tender="tender"></addenda-alert>
          </span>

          <div class="mb-8 md:flex md:justify-center" v-if="tender.status !== 'closed' && ($feature('response') || download || tender.url)">
            <button-link
              :outline="true"
              :external="true"
              v-if="$feature('download') && download"
              :to="download.url"
              class="w-full mb-4 md:w-auto md:mx-2 md:mb-0"
              v-text="`Download RFT`"
            ></button-link>

            <button-router-link
              v-if="!hasResponse && $feature('response') && authenticated"
              :to="{ name: 'response-create', params: { tenderId: tender.id } }"
            >
              Respond to tender
            </button-router-link>
            <span v-if="authenticated">
              <button-router-link
                v-if="hasResponse && authenticated"
                :to="{ name: 'response', params: { id: response.id } }"
                v-text="response.status === 'submitted' ? 'Review full response' : 'Continue response'"
              >
              </button-router-link>
            </span>

            <button
              v-else-if="$feature('response') && !authenticated"
              @click="login()"
              class="inline-flex button button--filled"
              v-text="`Login to respond to tender`"
            ></button>
            <button-link
              :external="true"
              v-else-if="tender.url"
              :to="tender.url"
              class="w-full md:w-auto md:mx-2"
              v-text="download ? `Respond to tender` : `Respond and download documents`"
            >
            </button-link>
          </div>
          <router-view :tender="tender"> </router-view>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import Card from "@/components/Card"
import Alert from "@/components/Alert"
import DynamicTitle from "@/mixins/dynamic-title"
import ButtonLink from "@/components/ButtonLink"
import ButtonRouterLink from "@/components/ButtonRouterLink"
import Loader from "@/components/Loader"
import AddendaAlert from "@/components/tenders/AddendaAlert"

export default {
  components: {
    AddendaAlert,
    ButtonRouterLink,
    Card,
    ButtonLink,
    Alert,
    Loader,
  },
  mixins: [DynamicTitle],
  data: () => ({
    open: false,
    loading: false,
  }),
  computed: {
    response() {
      return this.$store.getters["responses/findByTender"](this.$route.params.id)
    },
    hasResponse() {
      return !!this.response
    },
    tender() {
      return this.$store.getters["tenders/find"](this.$route.params.id)
    },
    addenda() {
      return this.tender?.addenda
    },
    hasAddenda() {
      return this.addenda && this.addenda.length > 0
    },
    addendaTotal() {
      return this.tender?.addenda_count ?? 0
    },
    title() {
      return this.tender?.title
    },
    download() {
      if (!this.tender?.files) {
        return false
      }

      let found = this.tender.files.find((f) => f.type === "ATM")

      return found ?? false
    },
    textType() {
      if (!this.tender) {
        return ""
      }

      return this.tender?.type === "notice" ? "Notice" : "Tender"
    },
  },
  async created() {
    this.loading = true
    if (this.authenticated) {
      await this.fetchResponses()
      /* if (this.$store.getters["responses/all"].length < 1) {
      } */
    }

    if (!this.tender?.detailed) {
      await this.load(this.$route.params.id)
    }
    this.titleSync()
    this.loading = false
  },
  methods: {
    ...mapActions({
      load: "tenders/one",
      login: "auth/login",
      fetchResponses: "responses/load",
    }),
  },
}
</script>
