import VueRouter from "vue-router"
import store from "../store"
import Home from "./Home.vue"
import Tenders from "./tenders/Tenders.vue"
import Tender from "./tenders/Tender.vue"
import Response from "./response/Response.vue"
import CreateResponse from "./response/CreateResponse.vue"
import Help from "./help/Help.vue"
import Contact from "./Contact.vue"
import Page from "./Page.vue"
import Auth from "./Auth.vue"
import PageNotFound from "./PageNotFound.vue"
import SystemError from "./SystemError.vue"
import Responses from "./response/Responses.vue"
import Confirm from "./response/Confirm.vue"
import { guid } from "@/plugins/util"

// sub routes
import TenderChildren from "./tenders/children"
import HelpChildren from "./help/children"
import ResponseChildren from "./response/children"

import pipeline from "./middleware/pipeline"
import { auth, web, response } from "./middleware"

let router = new VueRouter({
  mode: "history",
  linkActiveClass: "active-trail",
  linkExactActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    if (to.hash && !to.hash.match("#error|#state|#access_token")) {
      return {
        selector: to.hash,
        behavior: "smooth",
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    { path: "/", component: Home, name: "home", meta: { middleware: [web], title: "" } },
    { path: "/404", component: PageNotFound, name: "page-not-found", meta: { middleware: [web], title: "Error" } },
    { path: "/error", component: SystemError, name: "system-error", meta: { middleware: [web], title: "Error" } },
    { path: "/tenders", component: Tenders, name: "tenders", meta: { middleware: [web], title: "Tenders" } },
    {
      path: `/tenders/:id(${guid})`,
      component: Tender,
      meta: { middleware: [web], title: "Detailed Tender" },
      children: TenderChildren,
    },
    { path: "/tenders/:status", component: Tenders, name: "tenders-status", meta: { middleware: [web] } },
    { path: "/responses", component: Responses, name: "responses", meta: { middleware: [auth], title: "My Tenders" } },
    { path: "/responses/create/:tenderId", component: CreateResponse, name: "response-create", meta: { middleware: [auth] } },
    {
      path: `/responses/:id(${guid})`,
      component: Response,
      meta: { middleware: [auth, response] },
      children: ResponseChildren,
    },
    { path: `/responses/:id(${guid})/confirm/:action`, component: Confirm, name: "confirm-tender", meta: { middleware: [auth] } },
    //{ path: "/responses/confirm-tender/:action/:id", component: DeleteTender, name: "confirm-tender", meta: { middleware: [auth] } },
    {
      path: "/logout",
      name: "logout",
      redirect: async () => {
        await store.dispatch("auth/logout")
        return { name: "home" }
      },
    },
    { path: "/auth", name: "auth", component: Auth, meta: { middleware: [web], layout: "full" } },
    { path: "/contact-us", component: Contact, name: "contact-us", meta: { middleware: [web], title: "Contact us" } },
    { path: "/help", component: Help, meta: { middleware: [web], title: "Help" }, children: HelpChildren },

    // This should always be at the end
    { path: "/:path*", component: Page, name: "page", meta: { middleware: [web] } },
  ],
})

/**
 * Handle middleware
 */

router.beforeEach(async (to, from, next) => {
  await router.app.$root.$store.dispatch("auth/poll")
  await router.app.$root.$store.dispatch("auth/poll")

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware

  const context = {
    to,
    from,
    next,
    store,
  }

  return middleware[0]({
    ...context,
    next: pipeline(context, middleware, 1),
  })
})

router.afterEach((to) => {
  router.app.$root.$events.$emit("menu:close", false)

  if (typeof gtag == "function" && window.GA_TRACKING_ID) {
    gtag("config", window.GA_TRACKING_ID, {
      page_path: to.fullPath,
      app_name: "Portal",
      send_page_view: true,
    })
  }
})

export { router }
