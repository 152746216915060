<template>
  <card title="" theme="light" :loading="loading">
    <div v-if="content">
      <div class="flex items-start">
        <img class="w-24 pr-4" src="@/assets/images/knowledge-base-computer.svg" alt="Knowledge base icon" />
        <h4 class="mb-7" v-if="content">{{ content.title }}</h4>
      </div>
      <div v-dompurify-html="content.description" v-if="content"></div>
      <button-router-link :to="{ name: 'help' }" :outline="false">
        Discover and explore the help guides
      </button-router-link>
    </div>
  </card>
</template>
<script>
import ButtonRouterLink from "@/components/ButtonRouterLink"
import Card from "@/components/Card.vue"
import Computer from "@/assets/images/computer.svg"

export default {
  components: {
    ButtonRouterLink,
    Card,
  },
  data: () => ({
    Computer,
  }),
  computed: {
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("help_guides") ?? false
    },
    loading() {
      return !this.$store.getters["blockContent/findByMachineName"]("help_guides")
    },
  },
}
</script>
