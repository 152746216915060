import moment from "moment"
import { isArray } from "lodash"

import tenderForm from "./tender-form"

const formatFields = (fields, section_id = "") => {
  if (!isArray(fields)) {
    return []
  }

  return fields.map((field) => {
    return {
      id: "",
      name: field.label,
      question_id: field.id,
      response_id: "",
      section_id: section_id,
      fields: field?.fields?.length > 0 ? formatFields(field.fields, section_id) : [],
    }
  })
}

const formattedSections = tenderForm.sections.map((section) => {
  return {
    id: section.id,
    value: section.title,
    fields: formatFields(section.fields, section.id),
  }
})

export default {
  started_at: moment().subtract(5, "days"),
  status: "draft", // submitted | inactive | draft
  completion: 2,
  notifications: 3,
  questions: {
    total: 30,
    completed: 1,
  },
  sections: formattedSections,
}
