<template>
  <nav class="h-20 bg-white roundedrop-shadow-md">
    <div class="flex items-center h-full p-0 m-0 list-none">
      <button class="flex flex-col items-center justify-center w-1/4 h-full border-r border-gray-300">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="w-5">
          <path
            d="M.5 21c0 .2761.223858.5.5.5h20c.2761 0 .5-.2239.5-.5V4.57143c0-.14839-.0659-.28911-.1799-.38411L17.0344.615889C16.9445.541008 16.8313.5 16.7143.5H1C.723858.5.5.723858.5 1v20Z"
            fill="#676767"
            stroke="#676767"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18 21.5h.5V15c0-.8284-.6716-1.5-1.5-1.5H5c-.82843 0-1.5.6716-1.5 1.5v6.5H18ZM5 1h12v5c0 .55228-.4477 1-1 1H6c-.55228 0-1-.44772-1-1V1Z"
            fill="#fff"
            stroke="#676767"
          />
        </svg>
        <span class="mt-2">Save</span>
      </button>
      <button class="flex flex-col items-center justify-center w-1/4 h-full border-r border-gray-300">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 21" class="w-5">
          <rect x="6.5" y=".5" width="11" height="9" rx=".5" fill="#fff" stroke="#676767" />
          <rect y="5" width="23" height="11" rx="2" fill="#676767" />
          <rect x="6.5" y="11.5" width="11" height="9" rx=".5" fill="#fff" stroke="#676767" />
          <path d="M8 13h8m-8 3h8m-8 3h8" stroke="#676767" stroke-linecap="round" />
          <path fill="#fff" d="M4 8h1v1H4z" />
        </svg>
        <span class="mt-2">Print</span>
      </button>
      <button class="flex flex-col items-center justify-center w-1/4 h-full border-r border-gray-300">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 21" class="w-5">
          <path
            d="M10 15c0 .5523.4477 1 1 1s1-.4477 1-1h-2ZM11.7071.292893c-.3905-.3905241-1.0237-.3905241-1.4142 0L3.92893 6.65685c-.39052.39053-.39052 1.02369 0 1.41422.39053.39052 1.02369.39052 1.41422 0L11 2.41421l5.6569 5.65686c.3905.39052 1.0236.39052 1.4142 0 .3905-.39053.3905-1.02369 0-1.41422L11.7071.292893ZM12 15V1h-2v14h2Z"
            fill="#676767"
          />
          <path d="M1 12v8h20v-7.619" stroke="#676767" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="mt-2">Export</span>
      </button>
      <button class="flex flex-col items-center justify-center w-1/4 h-full">
        <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 23" class="w-5">
          <rect width="18" height="23" rx="1" fill="#676767" />
          <path fill="#fff" d="M3 3h2v2H3zm4 0h8v2H7zm0 5h8v2H7zm0 10h8v2H7zm0-5h8v2H7zM3 8h2v2H3zm0 5h2v2H3zm0 5h2v2H3z" />
        </svg>
        <span class="mt-2">Sections</span>
      </button>
    </div>
  </nav>
</template>

<script>
export default {}
</script>

<style></style>
