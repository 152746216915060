import store from "@/store"
import { isEmpty, isString } from "lodash"

export const jsonParse = (text, fallback = "") => {
  if (isEmpty(text)) {
    return fallback
  }
  if (!isString(text)) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    // console.log("json-error", e)
  }

  return fallback
}

export const complexComponents = ["abn", "address", "attachment", "multiselect", "checkbox", "contact"]

/**
 * Returns json or plain text
 * @param field
 * @param responseField
 * @returns {string|*}
 */
export const fieldValue = (field, responseField) => {
  if (!responseField) {
    return ""
  }

  if (complexComponents.includes(field.type)) {
    return jsonParse(responseField)?.value ?? ""
  }

  return responseField?.value ? responseField?.value : responseField
}
