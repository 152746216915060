
module.exports = [
  {
    name: "Employment Facilitator and Support Services for the LJP Deed 2020 2022 for March RFT 7 ERs",
    size: 241000,
    filename: "Employment Facilitator and Support Services for the LJP Deed 2020 2022 for March RFT 7 ERs.docx",
    url: "https://dese.gov.au/path/to/download/file.pdf",
    type: "addendum",
    ext: "pdf",
  },
  {
    name: "RFT Employment Facilitator and Support Services for LJP 2020 2022 seven ERs",
    size: 4710000,
    filename: "RFT Employment Facilitator and Support Services for LJP 2020 2022 seven ERs.docx",
    url: "https://dese.gov.au/path/to/download/file1.pdf",
    type: "addendum",
    ext: "pdf",
  },
  {
    name: "Addendum word",
    size: 241000,
    filename: "addendum-word.docx",
    url: "https://dese.gov.au/path/to/download/file2.docx",
    type: "addendum",
    ext: "docx",
  },
  {
    name: "Addendum pdf",
    size: 4710000,
    filename: "addendum-pdf.docx",
    url: "https://dese.gov.au/path/to/download/file3.pdf",
    type: "addendum",
    ext: "pdf",
  },
  {
    name: "FAQ word",
    size: 241000,
    filename: "faq-word.docx",
    url: "https://dese.gov.au/path/to/download/file4.docx",
    type: "addendum",
    ext: "docx",
  },
  {
    name: "FAQ pdf",
    size: 4710000,
    filename: "faq-pdf.docx",
    url: "https://dese.gov.au/path/to/download/file5.pdf",
    type: "ATM",
    ext: "pdf",
  },
]
