<template>
  <alert class="mb-5 shadow-lg mt-4" type="secondary">
    <p>You were <strong>not</strong> invited to submit a response to this tender. You are <strong>not</strong> eligible to submit a response.</p>
    <p>If you have any questions or concerns please contact the Employment Services Purchasing Hotline by:</p>

    <ul>
      <li>phone - 1300 733 514, (Monday to Friday, 9.00 am to 5.00 pm Canberra time, excluding ACT and national public holidays)</li>
      <li>email - Employment Services Purchasing Hotline (<a href="mailto:espurchasing@dewr.gov.au" target="_blank">espurchasing@dewr.gov.au</a>)</li>
    </ul>
  </alert>
</template>
<script>
import Alert from "@/components/Alert"
export default {
  components: { Alert },
}
</script>
