import endpoint from "./endpoint"

const collection = (params = {}) => {
  //#todo this better
  params.per_page = 1000
  return endpoint.get("/tenders", params)
}

const single = (id, params = {}) => {
  return endpoint.get(`/tenders/${id}`, params)
}

const form = (id, params = {}) => {
  return endpoint.get(`/tenders/${id}/form`, params)
}

const addenda = (id) => {
  return endpoint.get(`/addenda/${id}`)
}

export default {
  collection,
  single,
  form,
  addenda,
}
