<template>
  <div class="w-full p-4 mb-2 bg-white border-none rounded-lg accordion-item" :class="{ open: show }">
    <div class="cursor-pointer accordion" @click="toggle">
      <div class="font-bold title dropdown">
        {{ title }}
      </div>
    </div>
    <div :class="{ visible: this.show }" class="content">
      <div class="pt-4" v-dompurify-html="content"></div>
      <file-list :files="files" :show-title="false" class="mt-4"></file-list>
    </div>
  </div>
</template>

<script>
import FileList from "@/components/FileList"
export default {
  components: { FileList },
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    files: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    toggle() {
      this.show = !this.show
    },
  },
  created() {
    this.$events.$on("accordion:toggle", (state) => {
      this.show = state
    })
  },
  destroyed() {
    this.$events.$off("accordion:toggle")
  },
}
</script>
