import inputProps from "./../helpers/inputProps"
import FieldError from "@/components/form/FieldError"
import FieldMeta from "@/components/form/FieldMeta"
export default {
  inheritAttrs: false,
  components: {
    FieldError,
    FieldMeta,
  },
  props: {
    ...inputProps,
  },
  data() {
    return {
      current: "",
      active: true,
      auto: true,
      files: [],
      conditionsMet: true,
    }
  },
  created() {
    this.load()
  },
  watch: {
    current() {
      if (this.auto) {
        this.input()
      }
    },
    value() {
      if (!this.updatesForm) {
        this.load()
      }
    },
  },
  computed: {
    maxCharacters() {
      let mc = this.validation
      mc = mc
        .filter((c) => c.includes("max:"))
        .toString()
        .slice(4)
      return parseFloat(mc)
    },
    charactersLeft() {
      if (!this.current || !this.current.length) {
        return false
      }

      return this.maxCharacters - this.current.length
    },
    isOutOfRange() {
      return this.charactersLeft >= 0
    },
  },
  methods: {
    reuse(value) {
      this.current = value
      this.input()
      this.update()
    },
    load() {
      this.current = this.value
    },
    input() {
      this.$emit("input", this.current)
    },
    update() {
      if (this.updatesForm) {
        this.$emit("updated", this.current, {
          id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.name,
          files: this.files,
          status: this.conditionsMet,
        })
      } else {
        this.$emit("changed", {
          value: this.current,
          // id: this?.response?.id ?? "",
          question_id: this.id,
          type: this.type,
          name: this.label,
          files: this.files,
          status: this.conditionsMet,
        })
      }
    },
  },
}
