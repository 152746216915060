<template>
  <div class="form-input select-input">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="label">
      <label :for="`input_${name}`" v-text="label"></label>
      <span class="text-required" v-if="validation.includes('required')"> *</span>

      <multiselect
        :id="`input_${name}`"
        :name="name"
        v-model="current"
        @input="input"
        :options="options"
        :placeholder="placeholder"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :hide-selected="true"
        :show-labels="false"
        :class="['text-gray-500 bg-gray-100 border border-gray-500 rounded-md', { 'border-error border-2': errors.length }]"
        :value="value"
      >
      </multiselect>
      <field-error v-if="errors.length" :errors="errors"></field-error>
    </validation-provider>
  </div>
</template>
<script>
import { isPlainObject, map } from "lodash"
import Multiselect from "vue-multiselect"
import FieldError from "@/components/form/FieldError"
export default {
  components: {
    Multiselect,
    FieldError,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    items: {
      type: [Array, Object],
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    validation: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      current: [],
    }
  },
  computed: {
    options() {
      if (isPlainObject(this.items)) {
        return map(this.items, (value, key) => ({
          key,
          value,
        }))
      }

      return this.items
    },
  },
  methods: {
    load() {
      this.current = this.value
    },
    input() {
      this.$emit("input", this.current)
    },
  },
}
</script>
