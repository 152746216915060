import moment from "moment"

const description = require("./html/tender-detail")

const fileList = require("./files")

const additionalData = {
  rft_id: "DoE123456789",
  agency: "DESE - Department of Education, Skills and Employment",
  updates: [
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      created_at: "2021-10-30T17:32:28Z",
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. ",
      created_at: "2021-09-30T17:32:28Z",
    },
    {
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      created_at: "2021-08-30T17:32:28Z",
    },
  ],
  notice: "This tender has 3 addenda",
  description,
  files: fileList,
  url: "https://www.dese.gov.au",
  download: {
    name: "RFT Employment Facilitator and Support Services for LJP 2020 2022 seven ERs",
    size: 4710000,
    filename: "RFT Employment Facilitator and Support Services for LJP 2020 2022 seven ERs.docx",
    url: "https://dese.gov.au/path/to/download/file3.pdf",
  },
  faqs:
    "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi aperiam consequuntur cumque earum eum exercitationem, fugiat laudantium minima neque nisi nostrum perferendis possimus quis quod recusandae rem, rerum suscipit voluptatibus.</p>",
  appendix:
    "<a href='/tenders/dfb816ad-a866-482b-b107-bd026578d7dd/http://dese.gov.au/path/to/download/file.pdf' class='button button--download button--outline' target='_blank'> Employment Facilitator and Support Services for the LJP Deed 2020 2022 for March RFT 7 ERs - 241.0 KB </a>",
  glossary:
    "<table><thead><th>Term</th><th>Description</th></thead><tr><td>DESE</td><td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td></tr><tr><td>Applicant</td><td>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</td></tr></table>",
  addendums: [
    {
      id: "f533ab5f-c277-4631-b0dd-bbb79b4466d4",
      title: "Publication of the Draft Employment Facilitator and Support Services for the Local Jobs Program Deed 2020—2022",
      read: false,
      created_at: "2021-10-30T17:32:28Z",
      content:
        "<p>The Draft Employment Facilitator and Support Services for the Local Jobs Program  Deed 2020—2022 has been published in AusTender and 360Pro.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
      attachedFiles: [
        {
          name: "Addendum No.1",
          size: 4710000,
          filename: "Addendum No.1.docx",
          url: "https://dese.gov.au/path/to/download/1.doc",
        },
        {
          name: "Addendum No.1",
          size: 4710000,
          filename: "Addendum No.1.pdf",
          url: "https://dese.gov.au/path/to/download/1.pdf",
        },
      ],
    },
    {
      id: "613cd98a-7721-4472-bab1-86a26ef41039",
      title: "Publication of the Draft Employment Facilitator and Support Services for the Local Jobs Program Deed 2020—2022",
      read: true,
      created_at: "2021-10-30T17:32:28Z",
      content:
        "<p>The Draft Employment Facilitator and Support Services for the Local Jobs Program  Deed 2020—2022 has been published in AusTender and 360Pro.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
      attachedFiles: [
        {
          name: "Addendum No.2",
          size: 4710000,
          filename: "Addendum No.2.docx",
          url: "https://dese.gov.au/path/to/download/2.doc",
        },
        {
          name: "Addendum No.2",
          size: 4710000,
          filename: "Addendum No.2.pdf",
          url: "https://dese.gov.au/path/to/download/2.pdf",
        },
      ],
    },
    {
      id: "91a312c5-5939-4405-bed9-1154e9785f18",
      title: "Publication of the Draft Employment Facilitator and Support Services for the Local Jobs Program Deed 2020—2022",
      read: false,
      created_at: "2021-10-30T17:32:28Z",
      content:
        "<p>The Draft Employment Facilitator and Support Services for the Local Jobs Program  Deed 2020—2022 has been published in AusTender and 360Pro.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>",
      attachedFiles: [
        {
          name: "Addendum No.3",
          size: 4710000,
          filename: "Addendum No.3.docx",
          url: "https://dese.gov.au/path/to/download/3.doc",
        },
        {
          name: "Addendum No.3",
          size: 4710000,
          filename: "Addendum No.3.pdf",
          url: "https://dese.gov.au/path/to/download/3.pdf",
        },
      ],
    },
  ],
}

export default [
  {
    id: "bee58702-bf7e-4ef5-914b-5fdacca77b18",
    title: "Request for Tender for Employment Facilitator and Support Services for the Local Jobs Program 2020-2022: type-notice",
    summary: "",
    close_at: moment().add(5, "days"),
    status: "open",
    type: "notice",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Melbourne"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "dfb816ad-a866-482b-b107-bd026578d7dd",
    title: "Request for Tender for Harvest Trail Services 2020–2023 and Harvest Trail Information Service 2020–2023",
    summary: "",
    close_at: moment().add(2, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e74290c8-5ae4-481d-83f7-fc8bad92636c",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().add(10, "days"),
    status: "open",
    type: "notice",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "f0e4a05f-c04b-432f-9543-70fc69e5a69c",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(60, "days"),
    status: "open",
    type: "limited",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Perth"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "773dc777-de4d-4c56-bba4-f1467b211e14",
    title: "Request for Tender for Employment Facilitator and Support Services for the Local Jobs Program 2020-2022",
    summary: "",
    close_at: moment().add(45, "days"),
    status: "closed",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e8000000-f538-4e73-ab97-a48c51e9530b",
    title: "Request for Tender for Harvest Trail Services 2020–2023 and Harvest Trail Information Service 2020–2023",
    summary: "",
    close_at: moment().add(-16, "days"),
    status: "closed",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "9bcddc9c-22cd-4d98-931f-5aebf0a02861",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().subtract(180, "days"),
    status: "closed",
    type: "notice",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "71fdc24a-fcdb-46e8-8da1-e57c2efa2b7b",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(180, "days"),
    status: "closed",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "0ea96386-f8d3-4f0f-8ca6-b5f4a69850fd",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().add(-30, "days"),
    status: "closed",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "f8e8fc9a-18a3-4e5a-a920-ab124e6d7b9b",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(-60, "days"),
    status: "closed",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e26203e6-79f6-4675-830f-c2f23140efdd",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(5, "days"),
    status: "open",
    type: "notice",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "c7c799e9-4b7e-4969-af1b-6265df0b0bc5",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(20, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "8869cc96-f0aa-43b4-9934-fbc3e52a2267",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(10, "days"),
    status: "open",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "871225b2-913c-41b9-90c8-e12bfd6000a3",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(60, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "bee58702-bf7e-4ef5-914b-5fdacca77b20",
    title: "Request for Tender for Employment Facilitator and Support Services for the Local Jobs Program 2020-2022",
    summary: "",
    close_at: moment().add(5, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Melbourne", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "dfb816ad-a866-482b-b107-bd026578d7dc",
    title: "Request for Tender for Harvest Trail Services 2020–2023 and Harvest Trail Information Service 2020–2023",
    summary: "",
    close_at: moment().add(2, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e74290c8-5ae4-481d-83f7-fc8bad92639c",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().add(10, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "f0e4a05f-c04b-432f-9543-70fc69e5a66c",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(60, "days"),
    status: "open",
    type: "limited",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Perth"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "773dc777-de4d-4c56-bba4-f1467b233e14",
    title: "Request for Tender for Employment Facilitator and Support Services for the Local Jobs Program 2020-2022",
    summary: "",
    close_at: moment().add(45, "days"),
    status: "closed",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e8000088-f538-4e73-ab97-a48c51e9530b",
    title: "Request for Tender for Harvest Trail Services 2020–2023 and Harvest Trail Information Service 2020–2023",
    summary: "",
    description: "Dolore do eiusmod tempor incididunt ut labore et  magna aliqua. Lorem ipsum dolor sit amet",
    close_at: moment().add(-16, "days"),
    status: "closed",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "9bcddc9c-22cd-4d98-931f-5aebf0a02868",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().add(180, "days"),
    status: "closed",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "71fdc24a-fcdb-46e8-8da1-e57c8efa2b7b",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(180, "days"),
    status: "closed",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "0ea96386-f8d3-4f0f-8ca6-b5f4a67850fd",
    title: "Procurement for Youth Jobs PaTH Industry Pilots 2020–2021",
    summary: "",
    close_at: moment().add(-30, "days"),
    status: "closed",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "f8e8fc9a-18a3-4e5a-a920-ab124e6d8b9b",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(-60, "days"),
    status: "closed",
    awarded: true,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "e26203e6-79f6-4375-830f-c2f23140efdd",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(5, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "c7c799e9-4b7e-4969-af1b-6265df0b0bb5",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(20, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "8869cc96-f0aa-43b4-9934-fbc3e52a2269",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",

    close_at: moment().add(10, "days"),
    status: "open",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Education and Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
  {
    id: "871225b2-913c-41b9-90c8-e12bfd6000a1",
    title: "Request for Proposal for the Career Transition Assistance (CTA) Trial 2018–2020",
    summary: "",
    close_at: moment().add(60, "days"),
    status: "open",
    type: "limited",
    awarded: false,
    release_at: "2021-06-03T05:58:39.744Z",
    created_at: "2021-01-30T17:32:28Z",
    locations: ["Sydney", "Canberra"],
    category: "Training Services",
    updated_at: "2021-02-30T17:32:28Z",
    ...additionalData,
  },
]
