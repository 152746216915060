<template>
  <tr>
    <td class="max-w-xs rounded-l-xl">
      <div class="text-xs text-center">
        <div class="mb-1">
          <strong>Close date: </strong>
          <span v-text="$moment(item.tender.close_at).format('Do MMMM YYYY')"></span>
        </div>
        <time-remaining :item="item.tender"></time-remaining>
      </div>
    </td>
    <td class="px-8">
      <router-link class="title" :to="{ name: 'tender', params: { id: item.tender.id } }">{{ item.tender.title }}</router-link>
      <router-link v-if="item.tender.addenda_count" class="no-underline" :to="{ name: 'tender', params: { id: item.tender.id } }"
        >&nbsp; <span class="addenda-pill">Addenda</span>
      </router-link>
    </td>
    <td class="text-center">
      <div class="inline-block capitalize" v-text="completionStatus"></div>
    </td>
    <td class="text-center">
      <div class="flex flex-col items-center">
        <router-link class="text-center rounded-full" :to="{ name: 'response', params: { id: item.id } }"
          ><div v-if="editable" class="px-4 py-1 text-white rounded-full bg-primary-light w-min">
            Continue
          </div></router-link
        >
        <response-actions :item="item"></response-actions>
      </div>
    </td>
  </tr>
</template>
<script>
import ResponseListItem from "./mixins/response-list-item"
import TimeRemaining from "@/components/TimeRemaining"
import ResponseActions from "@/components/responses/ResponseActions"
export default {
  components: { ResponseActions, TimeRemaining },
  mixins: [ResponseListItem],
}
</script>
