<template>
  <div class="fixed inset-0 z-[100] flex items-center justify-center bg-black bg-opacity-10" :class="{ 'm-4': fullscreen }">
    <article class="flex flex-col w-full px-10 bg-white rounded-lg shadow py-7" :class="{ 'max-w-xl': !fullscreen, 'h-full': fullscreen }">
      <header class="flex justify-end">
        <button @click.prevent="close()" class="mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-3">
            <path
              fill="currentColor"
              d="M193.94 256 296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"
            />
          </svg>
        </button>
      </header>
      <slot name="content"></slot>
      <footer class="flex justify-end pt-7">
        <slot name="footer"></slot>
      </footer>
    </article>
  </div>
</template>

<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
  created() {
    document.addEventListener("keydown", ({ key }) => {
      if (key === "Escape") {
        this.close()
      }
    })
  },
}
</script>
