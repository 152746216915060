<template>
  <div class="wrapper">
    <div class="xl:flex">
      <div class="xl:w-1/4 xl:pr-16 relative">
        <button @click="open = !open" class="p-4 bg-white shadow-lg block w-full rounded mb-4 text-left dropdown xl:hidden" :class="{ open }">
          Jump to
        </button>
        <ul class="sub-menu bg-white -mt-4 shadow-lg xl:mt-4 xl:shadow-none xl:bg-transparent" :class="{ 'hidden xl:block': !open }">
          <li class="p-2" :key="ind" v-for="(item, ind) in links">
            <router-link :to="{ name: item.name, params: { path: $route.params.id } }" v-text="item.label"></router-link>
          </li>
          <li class="p-2" :key="item.id" v-for="item in pages">
            <router-link :to="{ name: 'help-page', params: { path: item.id } }" v-text="item.title"></router-link>
          </li>
        </ul>
      </div>
      <div class="xl:w-3/4">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import HelpLinks from "@/routes/help/children"
export default {
  data: () => ({
    open: false,
  }),
  methods: {
    load() {
      this.$store.dispatch("blockContent/load", ["help_guides_intro", "faq_introduction"])

      this.$store.dispatch("knowledge/load", {
        type: "help",
      })
    },
  },
  created() {
    this.load()
  },
  computed: {
    content() {
      return this.$store.getters["blockContent/findByMachineName"]("help_guides_intro") ?? false
    },
    pages() {
      let pages = this.$store.getters["knowledge/whereType"]("help")

      if (pages.length < 1) {
        return []
      }

      return pages.map(({ id, title }) => ({
        id,
        title,
      }))
    },
    links() {
      let links = HelpLinks.filter((tl) => tl.name !== "help-page").map((tl) => {
        return {
          name: tl.name,
          label: tl.meta.label,
        }
      })

      if (!this.$feature("response")) {
        return links.filter((l) => l.name !== "withdraw-tender")
      }

      return links
    },
  },
}
</script>
