module.exports = '<h2>My business is interested in responding to an ATM. What do I have to do?</h2>\n' +
  '  <p>You will need to prepare a response that complies with the ATM documentation. You will find some information about the typical contents of an ATM in the section below.</p>\n' +
  '  <p>You must prepare and submit your response by the specified date and time, to the specified location and in the specified format (for example electronically or a certain number of printed copies) that will be outlined in the ATM documentation.</p>\n' +
  '  <p>A short checklist to assist you to prepare and submit your response has also been included.</p>\n' +
  '  <h2>What is typically contained in an ATM?</h2>\n' +
  '  <p>Approach to market documents will usually include some, if not all, of the following elements:</p>\n' +
  '  <h2>Is there a particular format for my response?</h2>\n' +
  '  <p>Most Approaches to Market (ATMs) will included a template response for you to complete. You will simply need to answer each of the criteria.</p>\n' +
  '  <p>If a template response document is not included with the ATM package you will need to ensure you have included all the mandatory requirements specified in the ATM. If specific formatting is required, this should be clearly stated in the ATM.</p>\n' +
  '  <p>Be sure that your response addresses all the requirements and evaluation criteria, completes any mandatory forms and contains enough information to assist the government buyer in understanding your offer.</p>\n' +
  '  <p>Be sure that your response:</p>\n' +
  '  <ul>\n' +
  '    <li>directly addresses the requirements as laid out in the statement of requirement</li>\n' +
  '    <li>completes any mandatory forms provided in the ATM documentation, for example a declaration that you are authorised to submit the response, or a listing of proposed subcontractors</li>\n' +
  '    <li>pays attention to addressing the evaluation criteria, particularly any mandatory or essential evaluation criteria</li>\n' +
  '    <li>provides enough information and enough detail to allow the procurement staff to understand what your business is offering to supply to the government</li>\n' +
  '    <li>meets any format and content requirements laid down in the ATM documentation</li>\n' +
  '  </ul>\n' +
  '  <div class="info-box">Fusce nec nibh molestie, rhoncus diam in, mollis neque. Ut sollicitudin est et augue ' +
  'molestie aliquam. Suspendisse dignissim est dignissim, dapibus quam eget, hendrerit metus. Nulla lacinia diam et leo aliquam, sit amet luctus nisl convallis. </div>' +
  '  <h2>Is it true the government won’t accept late tenders, even if I have a good reason for being late?</h2>\n' +
  '  <p>Entities cannot accept your offer if it is submitted after the deadline, unless the delay is due solely to mishandling by the organisation. It is therefore&nbsp;<strong>vital</strong>&nbsp;that your offer is submitted by the deadline contained in the ATM documents. If it is late for any other reason, by even a few minutes, it will NOT be accepted. The AusTender system automatically closes at the designated time.</p>\n' +
  '  <h2>How can I give my response the best chance of success?</h2>\n' +
  '  <p>The following quick checklist presents some key points to consider when preparing your tender submission.</p>\n' +
  '  <h3>Is this the right opportunity for your business?</h3>\n' +
  '  <p>Participating in tender processes can involve substantial costs for your business with no guarantee of success. Take the time to determine whether each opportunity is potentially rewarding and cost-effective to pursue, based on its match with your competitive advantage, your demonstrable business history and experience, your business capacity, and the size of the size of the market.</p>\n' +
  '  <h3>Do you understand all the requirements?</h3>\n' +
  '  <p>It may sound simple, but be sure to read the tender documents carefully, highlight key points, and seek clarification from the organisation if you are uncertain about any issue.</p>\n' +
  '  <h3>Have you attended any briefing offered?</h3>\n' +
  '  <p>Be sure to attend an industry briefing if it is offered. Industry briefings provide an efficient opportunity to ask questions and hear what competitors may ask.&nbsp;&nbsp; Attending these sessions will give you a better idea if this business opportunity is right for you.</p>\n' +
  '  <h3>Have you planned your tender preparation and allowed plenty of time to meet the deadline?</h3>\n' +
  '  <p>Late tenders&nbsp;<strong>cannot</strong>&nbsp;be accepted, so make sure you know where and when your tender must be lodged. If you need to form a consortium or engage sub-contractors to complete the work, be sure to allow others enough time to provide input into the tender submission.</p>\n' +
  '  <h3>Is your response straightforward and to the point?</h3>\n' +
  '  <p>There is no standard format for tender responses—they vary depending on the nature and complexity of the procurement. However, you will always need to complete any mandatory forms and follow formatting style or templates and schedules provided. Your tender will be evaluated on content, not extravagant presentation. It is also a good idea to use simple, straightforward language and keep to the point. Put your response into the same order as the information in the request documents so that it is easy to follow for the people evaluating the tender. Use headings and lists to make your content clear. Do&nbsp;<strong>not</strong>&nbsp;include merchandising material unless it is permitted AND addresses the evaluation criteria directly.</p>\n' +
  '  <h3>Have you addressed all mandatory conditions for participation and evaluation criteria?</h3>\n' +
  '  <p>It is extremely important to demonstrate that you comply with all specified conditions for participation, and that you address all the evaluation criteria and any other specified requirements. Make sure you can comply with the terms and conditions of the draft contract. Remember, if you do not address the evaluation criteria, you response will be rejected. Check that you have addressed every evaluation criterion, and that your response fully demonstrates your ability to deliver the work required. Ensure you sign any declarations required.</p>\n' +
  '  <h3>Have you demonstrated value for money?</h3>\n' +
  '  <p>Value for money is the key consideration of Government organisations. Price is obviously important, but even more important are service standards, quality, performance and whole-of-life costs. Always bid competitively the first time; you will probably not be given a chance to improve your offer later in the process. Equally, do&nbsp;<strong>not</strong>&nbsp;underprice to win the work assuming you will be able to extend the value of the contract or increase your price once the contract is signed—you will be required to hold to the price you have quoted. Be sure not to over-promise as this could cause trouble for you later on if you can’t deliver.</p>\n' +
  '  <h3>Have you emphasised what sets you apart from your competitors?</h3>\n' +
  '  <p>Think about how you would evaluate your tender against the evaluation criteria if you were the official responsible for assessing tender responses. You may wish to emphasise areas where you exceed the requirements, and where you feel you can add value for the organisation. Your tender will usually be competing against several alternative proposals, so be sure to clearly identify potential points of difference from your competitors.</p>\n' +
  '  <h3>Have you clearly explained why you should be selected?</h3>\n' +
  '  <p>Don’t assume that the government organisation knows you and your business, even if you have worked for them before. The people for whom you have worked in the past may not be a part of this tender process, so you cannot assume that the government organisation will know anything about your previous work for them. Be sure to fully explain what you do and how your product or service will meet the requirements. Your offer will be judged on the information&nbsp;<strong>contained in your tender</strong>, so be sure to provide enough detail to demonstrate why your business should be the preferred supplier. Do not pad your tender unnecessarily or fill it with marketing waffle - just stick to the facts and give good examples of what you can do to meet the requirements.</p>\n' +
  '  <table>\n' +
  '    <thead>\n' +
  '        <tr>\n' +
  '            <th>Some field information heading</th>\n' +
  '            <th>Lorem sit</th>\n' +
  '            <th>Some field</th>\n' +
  '        </tr>\n' +
  '    </thead>\n' +
  '    <tbody>\n' +
  '        <tr>\n' +
  '            <td>\n' +
  '                Request for Tender for Employment Facilitator and Support Services for the Local Jobs Program 2020-2022\n' +
  '            </td>\n' +
  '            <td>Sydney</td>\n' +
  '            <td>Education and Training Services</td>\n' +
  '        </tr>\n' +
  '        <tr>\n' +
  '            <td>\n' +
  '                Request for Tender for Harvest Trail Services 2020–2023 and Harvest Trail Information Service 2020–2023\n' +
  '            </td>\n' +
  '            <td>Brisbane</td>\n' +
  '            <td>Jobs program</td>\n' +
  '        </tr>\n' +
  '        <tr>\n' +
  '            <td>\n' +
  '                Procurement for Youth Jobs PaTH Industry Pilots 2020–2021\n' +
  '            </td>\n' +
  '            <td>Melbourne</td>\n' +
  '            <td>Jobs program</td>\n' +
  '        </tr>\n' +
  '    </tbody>\n' +
  '</table>\n' +
  '<div class="collapsible_panel collapsible_panel_noborder">\n' +
  '    <div class="collapsible_panel_head">\n' +
  '        <div class="collapsible_panel_icon">&nbsp;</div>\n' +
  '        <div class="collapsible_panel_title" >\n' +
  '        <p data-placeholder="Title">Item 1</p>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '    <div class="collapsible_panel_body">\n' +
  '        <p data-placeholder="Enter body here">Body Content</p>\n' +
  '    </div>\n' +
  '</div>' +
  '<div class="collapsible_panel collapsible_panel_noborder">\n' +
  '    <div class="collapsible_panel_head">\n' +
  '        <div class="collapsible_panel_icon">&nbsp;</div>\n' +
  '        <div class="collapsible_panel_title" >\n' +
  '        <p data-placeholder="Title">Item 2</p>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '    <div class="collapsible_panel_body">\n' +
  '        <p data-placeholder="Enter body here">Body Content Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor, ' +
  '     eligendi est hic molestias, nihil odio odit quasi similique vel veritatis vero voluptatibus. ' +
  'Accusamus autem eos et iste magnam quaerat totam?</p>\n' +
  '    </div>\n' +
  '</div>' +
  '<div class="collapsible_panel collapsible_panel_noborder">\n' +
  '    <div class="collapsible_panel_head">\n' +
  '        <div class="collapsible_panel_icon">&nbsp;</div>\n' +
  '        <div class="collapsible_panel_title" >\n' +
  '        <p data-placeholder="Title">Item 3</p>\n' +
  '        </div>\n' +
  '    </div>\n' +
  '    <div class="collapsible_panel_body">\n' +
  '        <p data-placeholder="Enter body here">Body Content</p>\n' +
  '    </div>\n' +
  '</div>'
