<template>
  <div class="form-input text-input" v-if="conditionsMet">
    <validation-provider mode="eager" :rules="validation.join('|')" v-slot="{ errors }" :name="`${id}-${namePrefix}${label}`" :immediate="immediate">
      <template v-if="!readOnly">
        <div class="mb-2" v-if="showLabel">
          <label :for="`input_${name}`" v-text="label"></label>
          <span class="text-required" v-if="validation.includes('required')"> *</span>
        </div>

        <field-meta v-if="help && showLabel" :help="help"></field-meta>
        <div v-for="(item, ind) in items" class="m-1 mb-2" :key="ind">
          <label class="flex items-center">
            <input type="radio" v-model="current" :name="`input_${id}_${name}`" :value="item" class="mr-2" :disabled="disabled" @change="update" />
            <span v-text="item"></span>
          </label>
        </div>
        <field-error v-if="errors.length" :errors="errors" :id="id"></field-error>
      </template>
      <read-only-string
        v-else
        class="flex flex-col flex-wrap"
        :label="label"
        :show-label="showLabel"
        :value="value"
        :type="type"
        :print="print"
        :errors="errors"
        :id="id"
      ></read-only-string>
    </validation-provider>
  </div>
</template>
<script>
import InputMixin from "./mixins/input"
import ConditionalMixin from "./mixins/conditional"
import ReadOnlyString from "@/components/form/read-only/ReadOnlyString"

export default {
  name: "RadioInput",
  components: { ReadOnlyString },
  mixins: [InputMixin, ConditionalMixin],
  props: {
    items: Array,
  },
}
</script>
