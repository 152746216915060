<template>
  <validation-observer v-slot="{ handleSubmit }" slim>
    <p v-if="!submitted">The details in the form will be used to pre-fill an email that you can send to our mailbox.</p>
    <form action="" method="post" @submit.prevent="handleSubmit(submit)" class="mb-4" v-if="!submitted">
      <contact-text label="Name" v-model="form.name" :validation="['required']" name="name"></contact-text>

      <contact-text label="Email" v-model="form.email" :validation="['required', 'email']" name="email"></contact-text>

      <contact-drop-down
        label="Select enquiry category"
        v-model="form.category"
        :items="subjects"
        :validation="['required']"
        name="category"
      ></contact-drop-down>

      <contact-multi-select
        v-if="form.category === 'Tender currently advertised'"
        label="Select related tender"
        v-model="form.tenders"
        :items="tenders"
        :validation="['required']"
        placeholder="Select tenders"
        name="tenders"
      ></contact-multi-select>

      <contact-textarea label="Enquiry message" v-model="form.message" :validation="['required']" name="email"></contact-textarea>

      <div class="flex justify-center">
        <button type="submit" class="button inline-flex button--filled">Send email</button>
      </div>
    </form>
    <div v-if="submitted" class="p-4 text-center">
      Please ensure you have sent the enquiry via the email client pop-up.
      <br />
      You will receive a confirmation email. <br />If the email client has not opened, please
      <a target="_blank" :href="mailto()">click here to try again</a> or
      <a @click.prevent="submitted = false" href="#">return to the form</a>
    </div>
  </validation-observer>
</template>
<script>
import ContactText from "./ContactText"
import ContactTextarea from "./ContactTextarea"
import ContactMultiSelect from "./ContactMultiSelect"
import ContactDropDown from "./ContactDropDown"

const subjects = [
  "Tender currently advertised",
  "General information about procurement or procurement process",
  "Suggestion/Feedback or report about bugs, glitches or lack of functionality or problems on the system",
  "Other",
]

export default {
  props: {
    recipient: {
      type: String,
      default: "espurchasing@dewr.gov.au",
    },
  },
  components: {
    ContactText,
    ContactTextarea,
    ContactMultiSelect,
    ContactDropDown,
  },
  data: () => ({
    submitted: false,
    subjects,
    form: {
      name: "",
      email: "",
      category: "",
      message: "",
      tenders: [],
    },
  }),
  methods: {
    submit() {
      this.sendMail()
      this.submitted = true
    },
    sendMail() {
      window.location.href = this.mailto()
    },
    mailto() {
      const tendersSelected = this.form.tenders.length > 0 ? "%0D%0ASelected tenders: " + this.form.tenders.join(", ") : ""
      const subjectCategory = this.form.category.charAt(0).toUpperCase() + this.form.category.slice(1)
      const message =
        "Category: " +
        this.form.category +
        tendersSelected +
        "%0D%0A" +
        "Contact information: " +
        this.form.name +
        ", " +
        this.form.email +
        "%0D%0A" +
        "Message: " +
        this.form.message

      return `mailto:${this.recipient}?subject=${subjectCategory} enquiry from ${this.form.name}&body=${message}`
    },
    load() {
      this.$store.dispatch("tenders/load", {
        status: ["current"],
      })
    },
  },
  computed: {
    tenders() {
      return this.$store.getters["tenders/hasTenders"]("current") ? this.$store.getters["tenders/whereStatus"]("current").map((a) => a.title) : false
    },
  },
  created() {
    this.load()
    this.$events.$on("retrySubmit", () => {
      this.submit()
    })
  },
  destroyed() {
    this.$events.$off("retrySubmit")
  },
}
</script>
<style lang="scss">
.multiselect__option {
  @apply whitespace-normal;
}
</style>
